import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import ReactTooltip from "react-tooltip";
import { withTrans } from "../../../i18n/withTrans";
import Card from "../../../components/commons/cards/card";
import CardHeader from "../../../components/commons/cards/cardHeader";
import CardBody from "../../../components/commons/cards/cardBody";
import CardFlush from "../../../components/commons/cards/cardFlush";
import Checkbox from "../../../components/commons/forms/Checkbox";
import VoucherModal from "./modal/VoucherModal";
import moment from "moment";

import BookingService from "../../../store/actions/booking";

const Checkout = ({ button, formik, reg_price, data, t }) => {
  const dispatch = useDispatch();
  const [voucherModal, setVoucherModal] = useState(false);
  const [transportPrice, setTransportPrice] = useState(0);
  const [grandTotalPrice, setGrandTotalPrice] = useState(0);
  const [adultPrice, setAdultPrice] = useState(0);
  const [childPrice, setChildPrice] = useState(0);
  const [infantPrice, setInfantPrice] = useState(0);
  const [dataCheckout, setDataCheckout] = useState(formik?.values);
  const [showPriceService, setShowPriceService] = useState(false);
  const [showLounge, setShowLounge] = useState(false);

  const handleDeleteVoucher = (formik) => {
    let subTotal = formik?.values?.subTotal;
    formik?.setFieldValue("promo_id", null);
    formik.setFieldValue("grandTotalPrice", subTotal);
    formik.setFieldValue("gross_amount", subTotal);
  };

  const numberWithCommas = (x) => {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  useEffect(() => {
    getPriceFromApi();
  }, [data]);

  const getPriceFromApi = () => {
    new Promise((resolve) => {
      let values = {
        "airplane_name": formik?.values?.airplane_name,
        "airplane_name_transfer_to": formik?.values?.airplane_name_transfer_to,
        "airplane_number": formik?.values?.airplane_number,
        "airplane_number_transfer_to": formik?.values?.airplane_number_transfer_to,
        "airport_id_from": formik?.values?.airport_id_from,
        "airport_id_to": formik?.values?.airport_id_to,
        "airport_id_transfer": formik?.values?.airport_id_transfer,
        "assistant_id": formik?.values?.assistant_id,
        "customer_id": null,
        "date": formik?.values?.date,
        "date_transfer_to": formik?.values?.date_transfer_to,
        "flight_type": formik?.values?.flight_type_code,
        "lounge_adult_number": 
          formik?.values?.lounge_adult_number === "" 
            ? 0 
            : formik?.values?.lounge_adult_number,
        "lounge_child_number": 
          formik?.values?.lounge_child_number === "" 
            ? 0 
            : formik?.values?.lounge_child_number,
        "lounge_infant_number": 
          formik?.values?.lounge_infant_number === "" 
            ? 0 
            : formik?.values?.lounge_infant_number,
        "midtrans_token_id": formik?.values?.midtrans_token_id,
        "passenger_list": formik?.values?.passenger_list,
        "passenger_notes": formik?.values?.passenger_notes,
        "payment_type": formik?.values?.payment_type,
        "period_id": formik?.values?.period_id,
        "price_id":
          formik?.values?.price_id !== undefined && formik?.values?.price_id !== null  ? formik?.values?.price_id : null,
        "group_price_id":
          formik?.values?.group_price_id !== undefined && formik?.values?.group_price_id !== null  ? formik?.values?.group_price_id : null,
        "product_list_id":
          formik?.values?.product_list_id === ""
            ? null
            : formik?.values?.product_list_id,
        "promo_id": formik?.values?.promo_id,
        "time": formik?.values?.time,
        "time_transfer_to": formik?.values?.time_transfer_to,
        "type": formik?.values?.flight_type + 1,
        "region": formik?.values?.region,
        "transport_price_id": formik?.values?.orderExtras?.car?.id !== "" ? formik?.values?.orderExtras?.car?.id : null,
        "transport_number": formik?.values?.total_unit,
        "airport_lounge_id": formik?.values?.lounge !== '' ? formik?.values?.lounge : null,
        "product_name": formik?.values?.product_type,
      }
      dispatch(BookingService.get_price(values, resolve));
    }).then((res) => {
      setGrandTotalPrice(res?.data?.total_price);
      setAdultPrice(res?.data?.adultPriceOriginal);
      setChildPrice(res?.data?.childPriceOriginal);
      setInfantPrice(res?.data?.infantPriceOriginal);
      setTransportPrice(res?.data?.transportPrice);
      formik.setFieldValue("gross_amount", res?.data?.total_price);
      formik.setFieldValue("subTotal", res?.data?.total_price);
      formik.setFieldValue("price_service", res?.data?.price_service);
      formik.setFieldValue("total_lounge_price", res?.data?.totalLoungePrice);
      formik.setFieldValue("is_include_ppn", res?.data?.isIncludePpn);
      formik.setFieldValue(
        "is_include_concession",
        res?.data?.isIncludeConcession
      );
      formik.setFieldValue("ppn_percentage", res?.data?.ppnPercentage);
      formik.setFieldValue(
        "consession_percentage",
        res?.data?.concessionPercentage
      );
      formik.setFieldValue("ppn_group_price", res?.data?.ppnGroupPrice);
      formik.setFieldValue(
        "concession_group_price",
        res?.data?.concessionGroupPrice
      );
      formik.setFieldValue("ppn_adult_price", res?.data?.ppnAdultPrice);
      formik.setFieldValue("ppn_child_price", res?.data?.ppnChildPrice);
      formik.setFieldValue("ppn_infant_price", res?.data?.ppnInfantPrice);
      formik.setFieldValue(
        "concession_adult_price",
        res?.data?.concessionAdultPrice
      );
      formik.setFieldValue(
        "concession_child_price",
        res?.data?.concessionChildPrice
      );
      formik.setFieldValue(
        "concession_infant_price",
        res?.data?.concessionInfantPrice
      );
      formik.setFieldValue(
        "is_include_ppn_lounge",
        res?.data?.isIncludePpnLounge
      );
      formik.setFieldValue(
        "is_include_concession_lounge",
        res?.data?.isIncludeConcessionLounge
      );
      formik.setFieldValue(
        "lounge_ppn_percentage",
        res?.data?.ppnPercentageLounge
      );
      formik.setFieldValue(
        "lounge_consession_percentage",
        res?.data?.concessionPercentageLounge
      );
      formik.setFieldValue(
        "ppn_adult_lounge_price",
        res?.data?.ppnAdultLoungePrice
      );
      formik.setFieldValue(
        "concession_adult_lounge_price",
        res?.data?.concessionAdultLoungePrice
      );
      formik.setFieldValue(
        "ppn_child_lounge_price",
        res?.data?.ppnChildLoungePrice
      );
      formik.setFieldValue(
        "concession_child_lounge_price",
        res?.data?.concessionChildLoungePrice
      );
      formik.setFieldValue(
        "ppn_infant_lounge__price",
        res?.data?.ppnInfantLoungePrice
      );
      formik.setFieldValue(
        "concession_infant_lounge_price",
        res?.data?.concessionInfantLoungePrice
      );
      formik.setFieldValue(
        "ppn_transport",
        res?.data?.ppnTransport
      );
      formik.setFieldValue("tax_adult_price", res?.data?.taxAdultPrice);
      formik.setFieldValue("tax_child_price", res?.data?.taxChildPrice);
      formik.setFieldValue("tax_infant_price", res?.data?.taxInfantPrice);
      formik.setFieldValue("product_id", res?.data?.product_id);
      formik.setFieldValue({ ...dataCheckout });
      formik.values?.order_extra_list.splice(0, 4);
      formik.values = dataCheckout;
    });
  };

  const toggleLounge = () => {
    setShowLounge(!showLounge);
  };

  const togglePriceService = () => {
    setShowPriceService(!showPriceService);
  };

  const infoTax = (
    id,
    include_ppn,
    include_concession,
    ppn_percentage,
    concession_percentage,
    ppn_price,
    concession_price
  ) => {
    return (
      <>
        <span
          className="material-icons"
          style={{ fontSize: 18 }}
          data-tip
          data-for={id}
        >
          info_outlined
        </span>
        <ReactTooltip id={id} place="top" effect="solid">
          <p style={{ whiteSpace: "pre-line" }}>
            {(include_ppn && !include_concession && ppn_percentage !== null) ||
              (include_ppn && include_concession && concession_percentage === null && ppn_percentage !== null) ? (
              <span>
                {`${t(
                  "product.includes_ppn"
                )} ${ppn_percentage}%, Rp ${numberWithCommas(ppn_price)}`}
              </span>
            ) : !include_ppn && include_concession && concession_percentage !== null ? (
              <span>
                {`${t(
                  "product.includes_concession"
                )} ${concession_percentage}%, Rp ${numberWithCommas(
                  concession_price
                )}`}
              </span>
            ) : include_ppn && include_concession && concession_percentage !== null && ppn_percentage !== null ?(
              <span>
                {`${t(
                  "product.includes_ppn"
                )} ${ppn_percentage}%, Rp ${numberWithCommas(ppn_price)} & ${t(
                  "product.includes_concession"
                )} ${concession_percentage}%, Rp ${numberWithCommas(
                  concession_price
                )}`}
              </span>
            ) : ''}
          </p>
        </ReactTooltip>
      </>
    );
  };

  return (
    <>
      <Row>
        <Col lg={9} md={12}>
          <Card className="rounded">
            <CardHeader title="Booking Resume" />
            <CardBody>
              <CardFlush
                list
                label={t("field.flightType")}
                desc={
                  formik.values?.flight_type === 0
                    ? t("field.arrival")
                    : formik.values?.flight_type === 1
                    ? t("field.departure")
                    : "Transfer"
                }
                size={{ label: "5", desc: "5" }}
              />
              <CardFlush
                list
                label={
                  formik.values.flight_type === 0 ||
                  formik.values.flight_type === 2
                    ? t("field.arrvAirplane") + " / No."
                    : t("field.airplane") + " / No."
                }
                desc={
                  formik.values?.airplane_name +
                  " / " +
                  formik?.values?.airplane_code +
                  formik.values?.airplane_number
                }
                size={{ label: "5", desc: "5" }}
              />
              {formik.values?.flight_type === 2 && (
                <CardFlush
                  list
                  label={t("field.deptAirplane") + " / No."}
                  desc={
                    formik.values?.airplane_name_transfer_to +
                    " / " +
                    formik?.values?.airplane_code_transfer +
                    formik.values?.airplane_number_transfer_to
                  }
                  size={{ label: "5", desc: "5" }}
                />
              )}
              <CardFlush
                list
                label={t("field.origin")}
                desc={
                  formik.values?.airport_uniform_from !== "Indonesia"
                    ? formik.values?.airport_name_from +
                      "-" +
                      formik.values?.airport_uniform_from
                    : formik.values?.airport_name_from
                }
                size={{ label: "5", desc: "5" }}
              />
              {formik.values?.flight_type === 2 && (
                <CardFlush
                  list
                  label={t("Transfer")}
                  desc={
                    formik.values?.airport_uniform_transfer !== "Indonesia"
                      ? formik.values?.airport_name_transfer +
                        "-" +
                        formik.values?.airport_uniform_transfer
                      : formik.values?.airport_name_transfer
                  }
                  size={{ label: "5", desc: "5" }}
                />
              )}
              {
                (data = [
                  {
                    label: t("field.destination"),
                    desc:
                      formik.values?.airport_uniform_to !== "Indonesia"
                        ? formik.values?.airport_name_to +
                          "-" +
                          formik.values?.airport_uniform_to
                        : formik.values?.airport_name_to,
                  },
                  {
                    label:
                      formik.values.flight_type === 0 ||
                      formik.values.flight_type === 2
                        ? t("field.arrivalTime")
                        : t("field.departureTime"),
                    desc:
                      moment(formik.values.date).format("DD/MM/YYYY") +
                      ", " +
                      formik.values.time,
                  },
                ].map((item, i) => (
                  <CardFlush
                    list
                    label={item.label}
                    desc={item.desc}
                    size={{ label: "5", desc: "5" }}
                  />
                )))
              }
              {formik.values?.flight_type === 2 && (
                <CardFlush
                  list
                  label={t("field.departureTime")}
                  desc={
                    moment(formik.values?.date_transfer_to).format(
                      "DD/MM/YYYY"
                    ) +
                    ", " +
                    formik.values?.time_transfer_to
                  }
                  size={{ label: "5", desc: "5" }}
                />
              )}
              <div className="d-flex align-items-center mt-4">
                <span
                  className="material-icons text-danger"
                  style={{ fontSize: 16 }}
                >
                  info_outlined
                </span>
                <span
                  className="text-bold text-danger"
                  style={{ fontSize: 14, marginLeft: "-10px" }}
                >
                  Non-Refundable Booking
                </span>
              </div>
            </CardBody>
          </Card>
          <Card className="rounded">
            <CardHeader title={t("field.orderExtras")} />
            <CardBody>
              <div className="d-flex justify-content-left">
                <div
                  className="rounded-fill"
                  style={{
                    backgroundColor:
                      formik.values.orderExtras.lounge.name !== ""
                        ? "#ecf5eb"
                        : "#fff",
                    border:
                      formik.values.orderExtras.lounge.name !== ""
                        ? "1px solid #63AE5C"
                        : "1px solid rgb(165, 165, 192)",
                  }}
                >
                  <span
                    className="material-icons"
                    style={{
                      fontSize: 25,
                      color:
                        formik.values.orderExtras.lounge.name !== ""
                          ? "#63ae5c"
                          : "#A5A5C0",
                    }}
                  >
                    chair
                  </span>
                </div>
                <div className="d-flex flex-column ml-3">
                  <div className="text-bold">Lounge</div>
                  <div className="normal-title">
                    {formik.values.orderExtras.lounge.name !== ""
                      ? formik.values.orderExtras.lounge.name
                      : t("commons.none")}
                  </div>
                </div>
              </div>
              <div className="my-3" />
              <div className="d-flex justify-content-left">
                <div
                  className="rounded-fill"
                  style={{
                    backgroundColor:
                      formik.values.orderExtras.transport.name !== ""
                        ? "#ecf5eb"
                        : "#fff",
                    border:
                      formik.values.orderExtras.transport.name !== ""
                        ? "1px solid #63AE5C"
                        : "1px solid rgb(165, 165, 192)",
                  }}
                >
                  <span
                    className="material-icons"
                    style={{
                      fontSize: 25,
                      color:
                        formik.values.orderExtras.transport.name !== ""
                          ? "#63ae5c"
                          : "#A5A5C0",
                    }}
                  >
                    local_taxi
                  </span>
                </div>
                <div className="d-flex flex-column ml-3">
                  <div className="text-bold">{t("field.transportation")}</div>
                  <div className="normal-title">
                    {formik.values.region !== ""
                      ? formik.values.region +
                        " - " +
                        formik.values.orderExtras.car.name +
                        " (" +
                        formik.values.total_unit +
                        " unit)"
                      : t("commons.none")}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="rounded">
            <CardHeader title={t("field.detailPrice")} />
            {formik?.values?.promo_id !== null ? (
              <div className="rounded mx-4 card-activated">
                <div className="d-flex justify-content-between p-3">
                  <div className="text-extra-bold">Voucher</div>
                  <div className="d-flex justify-content-between clickable">
                    <div className="normal-title mr-5">
                      {`Diskon ${
                        formik?.values?.promo_price !== 0
                          ? " Rp" +
                            numberWithCommas(`${formik?.values?.promo_price} `)
                          : formik?.values.promo_percent + "% "
                      } ${formik?.values?.promo_name}`}
                    </div>
                    <span
                      className="material-icons-outlined"
                      onClick={() => handleDeleteVoucher(formik)}
                    >
                      close
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="border rounded mx-4">
                <div className="d-flex justify-content-between p-3">
                  <div className="text-extra-bold">Voucher</div>
                  <div
                    className="d-flex justify-content-between clickable"
                    onClick={() => setVoucherModal(true)}
                  >
                    <div className="normal-title text-light mr-5">
                      {t("field.selectVoucher")}
                    </div>
                    <span className="material-icons-outlined">
                      navigate_next
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex card-body justify-content-between py-3">
              <div className="normal-title text-extra-bold">
                {t("field.grandTotal")}
              </div>
              <div className="normal-title text-extra-bold">
                Rp{" "}
                {formik.values.promo_id
                  ? formik.values.discount > grandTotalPrice
                    ? numberWithCommas(grandTotalPrice - grandTotalPrice)
                    : numberWithCommas(grandTotalPrice - formik.values.discount)
                  : numberWithCommas(grandTotalPrice)}
              </div>
            </div>

            <div className="card-body bg-light-secondary border-top py-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="normal-title text-extra-bold">
                  {t("field.price")} {t("field.service")}
                </div>
                <div
                  onClick={togglePriceService}
                  className="d-flex align-items-center"
                  style={{ marginRight: "-30px", cursor: "pointer" }}
                >
                  <span className="normal-title text-extra-bold mr-2">
                    Rp {numberWithCommas(formik?.values?.price_service)}
                  </span>
                  <span className="material-icons-round left-icon">
                    expand_more
                  </span>
                </div>
              </div>
              {showPriceService && (
                <>
                  {formik?.values?.passengers_adult !== 0 && (
                    <>
                      <div className="normal-title text-medium text-primary mt-2 mb-1">
                        {t("commons.adult")}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center normal-title">
                            {t("field.price")}
                            {(formik?.values?.is_include_ppn ||
                              formik?.values?.is_include_concession) && (
                              <>
                                {infoTax(
                                  "taxAdult",
                                  formik?.values?.is_include_ppn,
                                  formik?.values?.is_include_concession,
                                  formik?.values?.ppn_percentage,
                                  formik?.values?.consession_percentage,
                                  formik?.values?.ppn_adult_price,
                                  formik?.values?.concession_adult_price
                                )}
                              </>
                            )}
                          </div>
                          {!formik?.values?.is_include_ppn && (
                            <div className="normal-title mt-1">
                              {`PPN ${formik?.values?.ppn_percentage}%`}
                            </div>
                          )}
                          {!formik?.values?.is_include_concession && (
                            <div className="normal-title mt-1">
                              {`Concession ${formik?.values?.consession_percentage}%`}
                            </div>
                          )}
                        </div>
                        <div className="text-right">
                          <div className="normal-title">
                            {formik?.values?.passengers_adult + " "}
                            <span>
                              {" x  Rp " + numberWithCommas(adultPrice)}
                            </span>
                          </div>
                          {!formik?.values?.is_include_ppn && (
                            <div className="normal-title mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  formik?.values?.ppn_adult_price
                                )}
                            </div>
                          )}
                          {!formik?.values?.is_include_concession && (
                            <div className="normal-title mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  formik?.values?.concession_adult_price
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {formik?.values?.passengers_child !== 0 && (
                    <>
                      <div className="normal-title text-medium text-primary mt-2 mb-1">
                        {t("commons.child")}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center normal-title">
                            {t("field.price")}
                            {(formik?.values?.is_include_ppn ||
                              formik?.values?.is_include_concession) && (
                              <>
                                {infoTax(
                                  "taxChild",
                                  formik?.values?.is_include_ppn,
                                  formik?.values?.is_include_concession,
                                  formik?.values?.ppn_percentage,
                                  formik?.values?.consession_percentage,
                                  formik?.values?.ppn_child_price,
                                  formik?.values?.concession_child_price
                                )}
                              </>
                            )}
                          </div>
                          {!formik?.values?.is_include_ppn && (
                            <div className="normal-title mt-1">
                              {`PPN ${formik?.values?.ppn_percentage}%`}
                            </div>
                          )}
                          {!formik?.values?.is_include_concession && (
                            <div className="normal-title mt-1">
                              {`Concession ${formik?.values?.consession_percentage}%`}
                            </div>
                          )}
                        </div>
                        <div className="text-right">
                          <div className="normal-title">
                            {formik?.values?.passengers_child + " "}
                            <span>
                              {" x  Rp " + numberWithCommas(childPrice)}
                            </span>
                          </div>
                          {!formik?.values?.is_include_ppn && (
                            <div className="normal-title mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  formik?.values?.ppn_child_price
                                )}
                            </div>
                          )}
                          {!formik?.values?.is_include_concession && (
                            <div className="normal-title mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  formik?.values?.concession_child_price
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {formik?.values?.passengers_infant !== 0 && (
                    <>
                      <div className="normal-title text-medium text-primary mt-2 mb-1">
                        {t("commons.infant")}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center normal-title">
                            {t("field.price")}
                            {(formik?.values?.is_include_ppn ||
                              formik?.values?.is_include_concession) && (
                              <>
                                {infoTax(
                                  "taxInfant",
                                  formik?.values?.is_include_ppn,
                                  formik?.values?.is_include_concession,
                                  formik?.values?.ppn_percentage,
                                  formik?.values?.consession_percentage,
                                  formik?.values?.ppn_infant_price,
                                  formik?.values?.concession_infant_price
                                )}
                              </>
                            )}
                          </div>
                          {!formik?.values?.is_include_ppn && (
                            <div className="normal-title mt-1">
                              {`PPN ${formik?.values?.ppn_percentage}%`}
                            </div>
                          )}
                          {!formik?.values?.is_include_concession && (
                            <div className="normal-title mt-1">
                              {`Concession ${formik?.values?.consession_percentage}%`}
                            </div>
                          )}
                        </div>
                        <div className="text-right">
                          <div className="normal-title">
                            {formik?.values?.passengers_infant + " "}
                            <span>
                              {" x  Rp " + numberWithCommas(infantPrice)}
                            </span>
                          </div>
                          {!formik?.values?.is_include_ppn && (
                            <div className="normal-title mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  formik?.values?.ppn_infant_price
                                )}
                            </div>
                          )}
                          {!formik?.values?.is_include_concession && (
                            <div className="normal-title mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  formik?.values?.concession_infant_price
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="card-body bg-light-secondary border-top py-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="normal-title text-extra-bold">
                  {t("field.lounge")}
                </div>
                {formik?.values?.lounge ? (
                  <div
                    onClick={toggleLounge}
                    className="d-flex align-items-center"
                    style={{ marginRight: "-30px", cursor: "pointer" }}
                  >
                    <span className="normal-title text-extra-bold mr-2">
                      Rp {numberWithCommas(formik?.values?.total_lounge_price)}
                    </span>
                    <span className="material-icons-round left-icon">
                      expand_more
                    </span>
                  </div>
                ) : (
                  <div
                    className="normal-title text-extra-bold"
                    style={{ cursor: "pointer" }}
                  >
                    Rp 0
                  </div>
                )}
              </div>
              {showLounge && (
                <>
                  {formik?.values?.lounge_adult_number !== "" &&
                    formik?.values?.lounge_adult_number !== 0 && (
                      <>
                        <div className="normal-title text-medium text-primary mt-2 mb-1">
                          {t("commons.adult")}
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <div className="d-flex align-items-center normal-title mb-1">
                              {t("field.price")}
                              {formik?.values?.is_include_ppn_lounge && (
                                <>
                                  {infoTax(
                                    "taxLoungeAdult",
                                    formik?.values?.is_include_ppn_lounge,
                                    formik?.values
                                      ?.is_include_concession_lounge,
                                    formik?.values?.lounge_ppn_percentage,
                                    formik?.values
                                      ?.lounge_consession_percentage,
                                    formik?.values?.ppn_adult_lounge_price,
                                    formik?.values
                                      ?.concession_adult_lounge_price
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="text-right">
                            <div className="normal-title mb-1">
                              {formik?.values?.lounge_adult_number + " "}
                              <span>
                                {" x  Rp " +
                                  numberWithCommas(
                                    formik?.values?.lounge_price_adult
                                  )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  {formik?.values?.lounge_child_number !== "" &&
                    formik?.values?.lounge_child_number !== 0 && (
                      <>
                        <div className="normal-title text-medium text-primary mt-2 mb-1">
                          {t("commons.child")}
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <div className="d-flex align-items-center normal-title mb-1">
                              {t("field.price")}
                              {formik?.values?.is_include_ppn_lounge && (
                                <>
                                  {infoTax(
                                    "taxLoungeChild",
                                    formik?.values?.is_include_ppn_lounge,
                                    formik?.values
                                      ?.is_include_concession_lounge,
                                    formik?.values?.lounge_ppn_percentage,
                                    formik?.values
                                      ?.lounge_consession_percentage,
                                    formik?.values?.ppn_child_lounge_price,
                                    formik?.values
                                      ?.concession_child_lounge_price
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="text-right">
                            <div className="normal-title mb-1">
                              {formik?.values?.lounge_child_number + " "}
                              <span>
                                {" x  Rp " +
                                  numberWithCommas(
                                    formik?.values?.lounge_price_child
                                  )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  {formik?.values?.lounge_infant_number !== "" &&
                    formik?.values?.lounge_infant_number !== 0 && (
                      <>
                        <div className="normal-title text-medium text-primary mt-2 mb-1">
                          {t("commons.infantLabel")}
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <div className="normal-title mb-1">
                              {t("field.price")}
                              {formik?.values?.is_include_ppn_lounge && (
                                <>
                                  {infoTax(
                                    "taxLoungeInfant",
                                    formik?.values?.is_include_ppn_lounge,
                                    formik?.values
                                      ?.is_include_concession_lounge,
                                    formik?.values?.lounge_ppn_percentage,
                                    formik?.values
                                      ?.lounge_consession_percentage,
                                    formik?.values?.ppn_infant_lounge_price,
                                    formik?.values
                                      ?.concession_infant_lounge_price
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="text-right">
                            <div className="normal-title mb-1">
                              {formik?.values?.lounge_infant_number + " "}
                              <span>
                                {" x  Rp " +
                                  numberWithCommas(
                                    formik?.values?.lounge_price_infant
                                  )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                </>
              )}
            </div>
            <div
              className={`d-flex card-body bg-light-secondary ${
                formik.values.promo_id === null ? "rounded-last-card" : ""
              } justify-content-between border-top py-3`}
            >
              <div className="d-flex align-items-center normal-title text-extra-bold">
                {t("field.transportation")}
                {formik.values.total_unit > 0 &&
                  formik?.values?.is_include_ppn_lounge && (
                    <>
                      <span
                        className="material-icons"
                        style={{ fontSize: 18 }}
                        data-tip
                        data-for="taxTransports"
                      >
                        info_outlined
                      </span>
                      <ReactTooltip
                        id="taxTransports"
                        place="top"
                        effect="solid"
                      >
                        <p style={{ whiteSpace: "pre-line" }}>
                          {`${t("product.includes_ppn")} ${
                            formik?.values?.lounge_ppn_percentage
                          }%, Rp ${numberWithCommas(
                            formik?.values?.ppn_transport
                          )}`}
                        </p>
                      </ReactTooltip>
                    </>
                  )}
              </div>
              <div className="normal-title ">
                {formik.values.total_unit > 0
                  ? formik.values.total_unit +
                    " unit  x Rp" +
                    numberWithCommas(transportPrice)
                  : "Rp 0"}
              </div>
            </div>
            {formik?.values?.promo_id !== null && (
              <div className="d-flex card-body card-bg-light rounded-last-card justify-content-between border-top py-3">
                <div className="normal-title text-extra-bold">
                  {t("commons.discount")}
                </div>
                <div className="normal-title ">
                  - Rp{numberWithCommas(formik?.values?.discount ?? 0)}
                </div>
              </div>
            )}
          </Card>
          <Card className="rounded">
            <CardHeader title={t("footer.terms")} />
            <CardBody>
              <Checkbox
                name="agreed_terms"
                checked={formik?.values?.agreed_terms}
                onChange={(e) => {
                  if (formik?.values?.agreed_terms) {
                    formik?.setFieldValue("agreed_terms", null);
                  } else {
                    formik?.setFieldValue("agreed_terms", ["1"]);
                  }
                }}
                value={1}
                label={
                  <p>
                    {t("booking.agreedTerms")}
                    <span className="text-bold text-primary">
                      <a target="_blank" href="/terms-condition">
                        {" "}
                        {t("footer.terms")}
                      </a>
                    </span>
                    <span className="text-bold text-primary">
                      <a target="_blank" href="/privacy-policy">
                        {", "}
                        {t("footer.privacyPolicy")}
                      </a>
                    </span>
                  </p>
                }
              />
              <Checkbox
                style={{ marginTop: "30px" }}
                name="agreed_purchase"
                checked={formik?.values?.agreed_purchase}
                onChange={(e) => {
                  if (formik?.values?.agreed_purchase) {
                    formik?.setFieldValue("agreed_purchase", null);
                  } else {
                    formik?.setFieldValue("agreed_purchase", ["1"]);
                  }
                }}
                value={1}
                label={
                  <p>
                    {t("booking.agreedService")}
                    <span className="text-bold text-primary">
                      <a
                        target="_blank"
                        href={
                          formik?.values?.available_service === "Transfer"
                            ? "/product/detail/transfer"
                            : "/product/detail/meet-and-greet"
                        }
                      >
                        {" "}
                        {formik?.values?.available_service}
                      </a>
                    </span>
                  </p>
                }
              />
            </CardBody>
          </Card>
          {button()}
        </Col>
      </Row>

      <VoucherModal
        show={voucherModal}
        toggle={() => setVoucherModal(!voucherModal)}
        formik={formik}
      />
    </>
  );
};
const mapStateToProps = ({ reg_price }) => {
  return { reg_price };
};

const mapDispatchToProps = () => {
  return {};
};
export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(Checkout)
);
