import { React, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Divider } from "@material-ui/core";
import debounce from "lodash.debounce";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { withTrans } from "../../i18n/withTrans";
import { history } from "../../utils/History";
import { toastError } from "../../components/commons/toast";
import Button from "../../components/commons/buttons/Button";
import Card from "../../components/commons/cards/card";
import CardBody from "../../components/commons/cards/cardBody";
import SelectFilter from "../../components/commons/forms/SelectFilter";
import DynamicButton from "../../components/commons/forms/DynamicButton";
import Select2 from "../../components/commons/forms/Select2";
import Input from "../../components/commons/forms/Input";
import Superiority from "../../components/Home/Superiority";
import Services from "../../components/Home/Services";
import Partners from "../../components/Home/Partners";

//SERVICE
import AirportService from "../../store/actions/master_data/airport";

const Index = ({ t }) => {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const [flightType, setFlightType] = useState(0);
  const [airportIdTo, setAirportIdTo] = useState(null);
  const [airportNameTo, setAirportNameTo] = useState(null);
  const [airportUniformTo, setAirportUniformTo] = useState(null);
  const [airportIdFrom, setAirportIdFrom] = useState(null);
  const [airportNameFrom, setAirportNameFrom] = useState(null);
  const [airportUniformFrom, setAirportUniformFrom] = useState(null);
  const [airportIdTransfer, setAirportIdTransfer] = useState(null);
  const [airportNameTransfer, setAirportNameTransfer] = useState(null);
  const [airportUniformTransfer, setAirportUniformTransfer] = useState(null);
  const [dataAirport, setDataAirport] = useState([]);
  const [dataAirportDomestic, setDataAirportDomestic] = useState([]);
  const [dataAirportTransfer, setDataAirportTransfer] = useState([]);
  const [searchTextAirport, setSearchTextAirport] = useState([]);
  const [searchAirportDomestic, setSearchAirportDomestic] = useState([]);
  const [searchAirportTransfer, setSearchAirportTransfer] = useState([]);
  const [typeSearchAirportDomestic, setTypeSearchAirportDomestic] =  useState("");
  const [typeSearchAirportTransfer, setTypeSearchAirportTransfer] =  useState("");
  const [typeSearchAirport, setTypeSearchAirport] =  useState("");
  const [date, setDate] = useState("");
  const [dateTransferTo, setDateTransferTo] = useState("");
  const [flightTypes, setFlightTypes] =  useState([
    { value: 0, label: t("field.arrival") },
    { value: 1, label: t("field.departure") },
    { value: 2, label: t("field.transfer") },
  ]);

  const getDate = moment(new Date().setDate(new Date().getDate() + 1)).format(
    "YYYY-MM-DD"
  );
  const isSmallMobile = width <= 575;
  const isMobile = width <= 720;
  const isTabled = width <= 1024 && width > 721;
  const isLargeDesktop = width >= 2000;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    if (localStorage.getItem("new_reservation")) {
      localStorage.removeItem('new_reservation');
    }
  }, []);

  useEffect(() => {
    if (flightTypes?.length) {
      setFlightTypes(
        flightTypes?.map((item) => {
          return {
            value: item?.value,
            label: (
              <div className="d-flex align-items-center">
                <span
                  className={`material-icons-round ${
                    item?.value !== 2 ? "left-icon" : "left-icon-large"
                  }`}
                  style={{
                    color: "#63AE5C",
                    fontSize: item?.value === 2 ? "30px" : "",
                  }}
                >
                  {item?.value === 0
                    ? "flight_land"
                    : item?.value === 1
                    ? "flight_takeoff"
                    : "connecting_airports"}
                </span>
                <p className="text-bold" style={{ margin: 0, color: "#000" }}>
                  {item?.label}
                </p>
              </div>
            ),
          };
        })
      );
    }
  }, []);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirport,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirport(
        res?.data.map((airport) => {
          return {
            value: airport?.id,
            label: `${airport?.code}-${airport?.city}-${airport?.name} ${
              airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
            }`,
            uniform: airport?.uniform
          };
        })
      );
    });
  }, [searchTextAirport]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchAirportTransfer,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirportTransfer(
        res?.data.map((airport) => {
          return {
            value: airport?.id,
            label: `${airport?.code}-${airport?.city}-${airport?.name} ${
              airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
            }`,
            uniform: airport?.uniform
          };
        })
      );
    });
  }, [searchAirportTransfer]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchAirportDomestic,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirportDomestic(
        res?.data.map((airport) => {
          if (airport.uniform === "Indonesia") {
            return {
              value: airport?.id,
              label: `${airport?.code}-${airport?.city}-${airport?.name} ${
                airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
              }`,
              uniform: airport?.uniform
            };
          }
        })
      );
    });
  }, [searchAirportDomestic]);

  const debouncedResults = debounce((value, type) => {
    if (value !== "") {
      if (type === "domestic") {
        setSearchAirportDomestic(value);
        setTypeSearchAirportDomestic("domestic");
      } else if (type === "transfer") {
        setSearchAirportTransfer(value);
        setTypeSearchAirportTransfer("transfer");
      } else if (type === "inter") {
        setSearchTextAirport(value);
        setTypeSearchAirport("inter");
      }
    }
  }, 500);

  const handleFlightType = (value) => {
    // setSearchAirportArrival("");
    // setSearchAirportDeparture("");
      setFlightType(value);
      setAirportIdFrom(null);
      setAirportIdTo(null);
    if (value === 2) {
      setAirportIdTransfer(null);
    }
  };

  const exchangeFlights = () => {
    if(flightType === 0){
      setSearchAirportDomestic(
        airportUniformFrom === 'Indonesia' && airportNameFrom !== null
          ? airportNameFrom?.split("-")[1]
          : ""
      );
      setSearchTextAirport(airportNameFrom !== null ? airportNameTo?.split("-")[1] : "");
      setAirportIdFrom(airportIdTo);
      setAirportNameFrom(airportNameTo);
      setAirportUniformFrom(airportUniformTo);
      setAirportIdTo(
        airportUniformFrom === 'Indonesia'
          ? airportIdFrom
          : null
      );
      setAirportNameTo(
        airportUniformFrom === 'Indonesia'
          ? airportNameFrom
          : null
      );
      setAirportUniformTo(
        airportUniformFrom === 'Indonesia'
          ? airportUniformFrom
          : null
      );
    } else if (flightType === 1) {
      setSearchAirportDomestic(
        airportUniformTo === 'Indonesia' && airportNameTo !== null
          ? airportNameTo?.split("-")[1]
          : ""
      );
      setSearchTextAirport(airportNameFrom !== null ? airportNameFrom?.split("-")[1] : "");
      setAirportIdFrom(
        airportUniformTo === 'Indonesia'
          ? airportIdTo
          : null
      );
      setAirportNameFrom(
        airportUniformTo === 'Indonesia'
          ? airportNameTo
          : null
      );
      setAirportUniformFrom(
        airportUniformTo === 'Indonesia'
          ? airportUniformTo
          : null
      );
      setAirportIdTo(airportIdFrom);
      setAirportNameTo(airportNameFrom);
      setAirportUniformTo(airportUniformFrom);
    }
  };

  const handleReservation = () => {
    const param = {
      "flight_type": flightType,
      "airport_id_to": airportIdTo,
      "airport_name_to": airportNameTo,
      "airport_uniform_to": airportUniformTo,
      "airport_id_from": airportIdFrom,
      "airport_name_from": airportNameFrom,
      "airport_uniform_from": airportUniformFrom,
      "airport_id_transfer": airportIdTransfer,
      "airport_name_transfer": airportNameTransfer,
      "airport_uniform_transfer": airportUniformTransfer,
      "date": date,
      "date_transfer_to": dateTransferTo,
      "searchAirportDomestic": searchAirportDomestic,
      "searchAirportTransfer": searchAirportTransfer,
      "searchTextAirport": searchTextAirport,
      "typeSearchAirportDomestic": typeSearchAirportDomestic,
      "typeSearchAirportTransfer": typeSearchAirportTransfer,
      "typeSearchAirport": typeSearchAirport,
    }
    
    localStorage.setItem("new_reservation", JSON.stringify(param));
    history.push("/reservation");
  }

  const settingSlider = {
    className: "center",
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // speed: 5000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: false,
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="jumbotron">
          <Slider {...settingSlider}>
            {[...Array(4)].map((_, index) => (
              <div className={`slider-jumbotron${index + 1}`} key={index}>
                <div className="wrapper-title-jumbotron text-extra-bold">
                  <div className="text-title-jumbotron">{t("home.jumbotron1")}</div>
                  <div className="text-title-jumbotron">{t("home.jumbotron2")}</div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="jumbotron-title">
          <Row
            className={`${
              isTabled
                ? "text-left"
                : isMobile
                ? "text-center"
                : "justify-content-center"
            }`}
            style={{
              padding: '18px 56px'
            }}
          >
            <Col md={12} sm={12}>
              <div className="d-flex flex-column">
                <div className="title text-extra-bold">
                  {t("home.reservToday")}
                </div>
                <div className="normal-title">{t("home.subtitleFooter")}</div>
              </div>
            </Col>
          </Row>
          <Card
            className="rounded" 
            style={{ 
              borderStyle: 'none',
            }}
          >
            <div className="d-flex py-0 px-md-5 px-3">
              {isSmallMobile ? (
                <div className="my-3">
                  <SelectFilter
                    searchable
                    name="flight_type"
                    options={flightTypes}
                    value={flightType}
                    onChange={(name, value) => handleFlightType(value)}
                  />
                </div>
              ) : (
                <>
                <DynamicButton
                  rounded
                  maxWidthBtn="min-content"
                  maxHeightBtn="52px"
                  titleBold="medium"
                  customPaddingBtn="14px 20px"
                  titleLeft={t("field.arrival")}
                  iconLeft="flight_land"
                  active={flightType === 0}
                  toggle={() => handleFlightType(0)}
                />
                <DynamicButton
                  rounded
                  maxWidthBtn="min-content"
                  maxHeightBtn="52px"
                  titleBold="medium"
                  customPaddingBtn="14px 20px"
                  titleLeft={t("field.departure")}
                  iconLeft="flight_takeoff"
                  active={flightType === 1}
                  toggle={() => handleFlightType(1)}
                />
                <DynamicButton
                  rounded
                  maxWidthBtn="min-content"
                  maxHeightBtn="52px"
                  titleBold="medium"
                  customPaddingBtn="14px 20px"
                  titleLeft="Transfer"
                  iconLeft="connecting_airports"
                  iconLeftStyle="30px"
                  active={flightType === 2}
                  toggle={() => handleFlightType(2)}
                />
                </>
              )}
            </div>
            <Divider orientation="horizontal" />
            <CardBody style={{ paddingTop: '10px' }}>
              {flightType !== 2 ? (
                <Row className="px-md-3 px-0 text-left align-items-end">
                  <Col sm={4}>
                    <Select2
                      title={t("field.origin")}
                      name="airport_id_from"
                      styleHeight="50px"
                      stylePadding="0"
                      options={flightType === 1 ? dataAirportDomestic : dataAirport}
                      value={airportIdFrom}
                      onInputChange={(keyword) => {
                        debouncedResults(
                          keyword, 
                          flightType === 1 ? "domestic" : "inter"
                        );
                      }}
                      onChange={(name, value) => {
                        if (airportIdTo === value) {
                          toastError(`${t("field.cantSameAirport")}!`);
                        } else {
                          let airportListOrigin= 
                            flightType === 1
                              ? dataAirportDomestic
                              : dataAirport;
                          const index =
                            airportListOrigin?.map(function (e) {
                              return e.value;
                            }).indexOf(value);
                          setAirportIdFrom(value);
                          setAirportNameFrom(
                            airportListOrigin[index].label
                          );
                          setAirportUniformFrom(airportListOrigin[index].uniform);
                        }
                      }}
                    />
                  </Col>
                  {!isSmallMobile && (
                    <Col sm={1}>
                      <div className="p-0 d-flex justify-content-center" onClick={exchangeFlights}>
                        <span className="material-icons-outlined my-sm-4 my-0 service-navigate">
                          swap_horiz
                        </span>
                      </div>
                    </Col>
                  )}
                  <Col sm={4}>
                    <Select2
                      title={t("field.destination")}
                      name="airport_id_to"
                      styleHeight="50px"
                      stylePadding="0"
                      options={flightType === 1 ? dataAirport : dataAirportDomestic}
                      value={airportIdTo}
                      onInputChange={(keyword) => {
                        debouncedResults(
                          keyword, 
                          flightType === 1 ? "inter" : "domestic"
                        );
                      }}
                      onChange={(name, value) => {
                        if (airportIdFrom === value) {
                          toastError(`${t("field.cantSameAirport")}!`);
                        } else {
                          let airportListDestination = 
                            flightType === 1
                              ? dataAirport
                              : dataAirportDomestic;
                          const index =
                            airportListDestination?.map(function (e) {
                              return e.value;
                            }).indexOf(value);
                          setAirportIdTo(value);
                          setAirportNameTo(
                            airportListDestination[index].label
                          );
                          setAirportUniformTo(airportListDestination[index].uniform);
                        }
                      }}
                    />
                  </Col>
                  <Col sm={3} className="px-md-1 px-sm-0 px-xs-1">
                    <Input
                      title={
                        flightType === 0
                          ? t("field.arrivalDate")
                          : t("field.departureDate")
                      }
                      style={{ height: '50px' }}
                      name="date"
                      type="date"
                      min={getDate}
                      value={date}
                      onChange={(e) => setDate(e?.target?.value)}
                    />
                  </Col>
                </Row>
              ) : (
                <>
                <Row className="mb-2 text-left px-md-3 px-0">
                  <Col sm={4}>
                    <Select2
                      title={t("field.origin")}
                      name="airport_id_from"
                      options={dataAirport}
                      value={airportIdFrom}
                      onInputChange={(keyword) => {
                        debouncedResults(
                          keyword, 
                          "inter"
                        );
                      }}
                      onChange={(name, value) => {
                        if (airportIdTo === value || airportIdFrom === value) {
                          toastError(`${t("field.cantSameAirport")}!`);
                        } else {
                          const index =
                            dataAirport?.map(function (e) {
                              return e.value;
                            }).indexOf(value);
                          setAirportIdFrom(value);
                          setAirportNameFrom(
                            dataAirport[index].label
                          );
                          setAirportUniformFrom(dataAirport[index].uniform);
                        }
                      }}
                    />
                  </Col>
                  <Col sm={4}>
                    <Select2
                      title="Transfer"
                      name="airport_id_transfer"
                      options={dataAirportDomestic}
                      value={airportIdTransfer}
                      onInputChange={(keyword) => {
                        debouncedResults(
                          keyword, 
                          "domestic"
                        );
                      }}
                      onChange={(name, value) => {
                        if (airportIdFrom === value || airportIdTo === value) {
                          toastError(`${t("field.cantSameAirport")}!`);
                        } else {
                          const index =
                            dataAirportDomestic?.map(function (e) {
                              return e.value;
                            }).indexOf(value);
                          setAirportIdTransfer(value);
                          setAirportNameTransfer(
                            dataAirportDomestic[index].label
                          );
                          setAirportUniformTransfer(dataAirportDomestic[index].uniform);
                        }
                      }}
                    />
                  </Col>
                  <Col sm={4}>
                    <Select2
                      title={t("field.destination")}
                      name="airport_id_to"
                      options={dataAirportTransfer}
                      value={airportIdTo}
                      onInputChange={(keyword) => {
                        debouncedResults(
                          keyword, 
                          "transfer"
                        );
                      }}
                      onChange={(name, value) => {
                        if (airportIdFrom === value || airportIdTransfer === value) {
                          toastError(`${t("field.cantSameAirport")}!`);
                        } else {
                          const index =
                            dataAirportTransfer?.map(function (e) {
                              return e.value;
                            }).indexOf(value);
                          setAirportIdTo(value);
                          setAirportNameTo(
                            dataAirportTransfer[index].label
                          );
                          setAirportUniformTo(dataAirportTransfer[index].uniform);
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Divider orientation="horizontal" />
                <Row className="mt-2 text-left px-md-3 px-0">
                  <Col sm={6}>
                    <Input
                      title={t("field.arrivalDate")}
                      name="date"
                      type="date"
                      min={getDate}
                      value={date}
                      onChange={(e) => setDate(e?.target?.value)}
                    />
                  </Col>
                  <Col sm={6}>
                    <Input
                      title={t("field.departureDate")}
                      name="date_transfer_to"
                      type="date"
                      min={getDate}
                      value={dateTransferTo}
                      onChange={(e) => setDateTransferTo(e?.target?.value)}
                    />
                  </Col>
                </Row>
                </>
              )}
              <Row
                className="justify-content-end mt-3 px-3"
              >
                <Col xl={4} md={6} sm={6}
                >
                  <Button
                    title={t("home.bookNow")}
                    variant={`${isTabled ? "mt-2 primary" : "primary"}`}
                    rounded
                    style={{ width: '100%' }}
                    onClick={handleReservation}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
      <Superiority isLargeDesktop={isLargeDesktop} />
      <Services />
      <Partners />
    </div>
  );
};

export default withTrans(Index);
