import React, { useEffect, useState } from "react";
import {
  Col,
  Container, 
  Row,
} from 'reactstrap';
import Card from "../../components/commons/cards/card";
import CardCollapse from "../../components/commons/cards/cardCollapse";
import { withTrans } from "../../i18n/withTrans";
const Index = ({ t }) => {
  const [openTerms, setOpenTerms] = useState("");
  const lang = localStorage.getItem('joumpa_language');

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div>
      <Container fluid>
        <section id="informations">
          <div className="product-bg d-flex">
            <Row className="m-auto justify-content-center">
              <Col md={12}>
                <div className="super-title text-center text-extra-bold">
                  {t("termsCondition.headline")}
                </div>
                <div className="super-title text-center text-extra-bold">
                  {t("termsCondition.subheadline")}
                </div>
              </Col>
            </Row>
          </div>
          <div className="content">
            <Row className="description mb-4">
              <p>
                {t("termsCondition.headlineContent")}
              </p>
              {lang === 'id' ? (
                <p>
                  Aplikasi dan website dimiliki, dioperasikan, dan dikelola oleh PT Gapura Angkasa, perseroan terbatas yang berdiri atas dasar hukum Republik Indonesia. PT Gapura Angkasa memiliki produk Layanan Airport VIP Service yaitu Joumpa Airport VIP Service dan selanjutnya bersama sama disebut sebagai “Kami” atau “Joumpa”. Layanan kami tersedia secara online melalui website{" "}
                  <span className="text-bold text-primary"> 
                    <a target="_blank" href="https://www.joumpa.com/">
                      www.joumpa.com
                    </a>
                  </span>{" "}atau aplikasi
                  <span className="text-bold text-primary"> 
                    {" "}“Joumpa for Customer”{" "}
                  </span>yang tersedia di App Store atau Play Store. 
                </p>
              ) : (
                <p>
                  The application and website are owned, operated and managed by PT Gapura Angkasa, a limited liability company founded on the laws of the Republic of Indonesia. PT Gapura Angkasa has an Airport VIP Service product, namely Joumpa Airport VIP Service and hereinafter collectively referred to as "We" or "Joumpa". Our services are available online through the website{" "}
                  <span className="text-bold text-primary"> 
                    <a target="_blank" href="https://www.joumpa.com/">
                      www.joumpa.com
                    </a>
                  </span>{" "}or the 
                  <span className="text-bold text-primary"> 
                    {" "}“Joumpa for Customer”{" "}
                  </span> application available on the App Store or Play Store.
                </p>
              )}
              <p className="mt-4">
                {t("termsCondition.headlineContact")}
              </p>
              <Col md={3} className="pl-0">
                <p className="text-bold m-0">
                  Email
                </p>
              </Col>
              <Col md={9}>
                joumpa@gapura.id
              </Col>
              <Col md={3} className="pl-0">
                <p className="text-bold m-0">
                  Phone / Whatsapp
                </p>
              </Col>
              <Col md={9}>
                +62-811-8566-009
              </Col>
            </Row>
            <Card
              style={{ 
                marginTop: '3em',
                borderStyle: 'none',
              }}
            >
              <CardCollapse 
                headerTitle={`1. ${t("termsCondition.general")}`}
                list_info={(
                  <>
                    <li>
                      {lang === 'id' ? (
                        <>
                          Dengan mengakses dan menggunakan aplikasi, website atau Layanan Kami Joumpa Airport VIP Service, Anda menyatakan telah membaca, memahami, menyetujui dan menyatakan tunduk pada Syarat dan Ketentuan penggunaan
                          <span className="text-bold text-primary"> 
                            <a target="_blank" href="https://www.joumpa.com/">
                              {" "}www.joumpa.com
                            </a>
                          </span>{" "} ini. Jika Anda tidak dapat menyetujui Syarat dan Ketentuan Penggunaan ini, baik secara keseluruhan ataupun sebagian, maka Anda tidak diperbolehkan untuk mengakses website ini ataupun menggunakan Layanan yang Kami sediakan. 
                        </>
                      ) : (
                        <>
                          By accessing and using our Joumpa Airport VIP Service application, website or service, you acknowledge that you have read, understood, agreed and stated that you are subject to these Terms and Conditions for using{" "}
                          <span className="text-bold text-primary"> 
                            <a target="_blank" href="https://www.joumpa.com/">
                              www.joumpa.com
                            </a>
                          </span>{" "}. If you cannot agree to these Terms and Conditions of Use, either in whole or in part, then you are not allowed to access this website or use the services we provide.
                        </>
                      )}
                    </li>
                    <li>
                      {t("termsCondition.general2")}
                    </li>
                    <li>
                      {t("termsCondition.general3")}
                    </li>
                  </>
                )}
                onClick={() => {
                  if (openTerms === "1") {
                    setOpenTerms(null)
                  } else {
                    setOpenTerms("1")
                  }
                }}
                opened={openTerms === "1"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`2. ${t("termsCondition.usage")}`}
                list_info={(
                  <>
                    <li>
                      {lang === 'id' ? (
                        <>
                          Website{" "}
                          <span className="text-bold text-primary"> 
                            <a target="_blank" href="https://www.joumpa.com/">
                              www.joumpa.com
                            </a>
                          </span>{" "} ini dan produk-produk, teknologi dan proses yang terdapat atau terkandung dalam Website, dimiliki oleh Kami atau pihak ketiga yang memberi hak kepada Kami. Kecuali untuk penggunaan yang secara tegas diijinkan dan diperbolehkan dalam Syarat dan Ketentuan Penggunaan Website ini, Anda tidak memiliki ataupun menerima dan Kami tidak memberikan hak lain apapun ke Anda atas Website ini, berikut dengan segala data, informasi dan konten didalamnya. 
                        </>
                      ) : (
                        <>
                          This{" "}
                          <span className="text-bold text-primary"> 
                            <a target="_blank" href="https://www.joumpa.com/">
                              www.joumpa.com
                            </a>
                          </span>{" "} website and the products, technology and processes contained or contained in the Website, are owned by us or third parties who give us rights. Except for the use that is expressly permitted and allowed in the Terms and Conditions of Use of this Website, you do not own or accept and we do not give you any other rights to this website, along with all the data, information and content therein.
                        </>
                      )}
                    </li>
                    <li>
                      {t("termsCondition.usage2")}
                    </li>
                    <li>
                      {t("termsCondition.usage3")}
                    </li>
                    <li>
                      {t("termsCondition.usage4")}
                    </li>
                  </>
                )}
                onClick={() => {
                  if (openTerms === "2") {
                    setOpenTerms(null)
                  } else {
                    setOpenTerms("2")
                  }
                }}
                opened={openTerms === "2"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`3. ${t("termsCondition.service")}`}
                list_info={(
                  <>
                    <li>
                      {t("termsCondition.service1")}
                    </li>
                    <li>
                      {t("termsCondition.service2")}
                    </li>
                    <li>
                      {t("termsCondition.service3")}
                    </li>
                    <li>
                      {t("termsCondition.service4")}
                    </li>
                    <li>
                      {t("termsCondition.service5")}
                    </li>
                  </>
                )}
                onClick={() => {
                  if (openTerms === "3") {
                    setOpenTerms(null)
                  } else {
                    setOpenTerms("3")
                  }
                }}
                opened={openTerms === "3"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`4. ${t("termsCondition.purchases")}`}
                list_info={(
                  <>
                    <li>
                      {t("termsCondition.purchases1")}
                    </li>
                    <li>
                      {lang === 'id' ? (
                        <>
                          Pemesanan / pembelian produk dapat dilakukan melalui Website{" "}
                          <span className="text-bold text-primary"> 
                            <a target="_blank" href="https://www.joumpa.com/">
                              www.joumpa.com
                            </a>
                          </span>{" "}, aplikasi joumpa for customer atau melalui contact center dengan memasukan data pemesanan secara lengkap dan benar. 
                        </>
                      ) : (
                        <>
                          Product orders/purchases can be made through the Website{" "}
                          <span className="text-bold text-primary"> 
                            <a target="_blank" href="https://www.joumpa.com/">
                              www.joumpa.com
                            </a>
                          </span>{" "}, the joumpa for customer application or through the contact center by entering complete and correct order data.
                        </>
                      )}
                    </li>
                    <li>
                      {t("termsCondition.purcahses3")}
                    </li>
                    <li>
                      {t("termsCondition.purchases4")}
                    </li>
                    <li>
                      {t("termsCondition.purchases5")}
                    </li>
                    <li>
                      {t("termsCondition.purchases6")}
                    </li>
                  </>
                )}
                onClick={() => {
                  if (openTerms === "4") {
                    setOpenTerms(null)
                  } else {
                    setOpenTerms("4")
                  }
                }}
                opened={openTerms === "4"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`5. ${t("termsCondition.promotions")}`}
                list_info={(
                  <>
                    <li>
                      {t("termsCondition.promotions1")}
                    </li>
                    <li>
                      {t("termsCondition.promotions2")}
                    </li>
                    <li>
                      {t("termsCondition.promotions3")}
                    </li>
                  </>
                )}
                onClick={() => {
                  if (openTerms === "5") {
                    setOpenTerms(null)
                  } else {
                    setOpenTerms("5")
                  }
                }}
                opened={openTerms === "5"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`6. ${t("termsCondition.payment")}`}
                list_info={(
                  <>
                    <li>
                      {t("termsCondition.payment1")}
                    </li>
                    <li>
                      {t("termsCondition.payment2")}
                    </li>
                    <li>
                      {t("termsCondition.payment3")}
                    </li>
                    <li>
                      {t("termsCondition.payment4")}
                    </li>
                    <li>
                      {t("termsCondition.payment5")}
                      <ol type="I">
                        <li>
                          {t("termsCondition.payment5I")}
                        </li>
                        <li>
                          {t("termsCondition.payment5II")}
                        </li>
                      </ol>
                    </li>
                    <li>
                      {t("termsCondition.payment6")}
                    </li>
                  </>
                )}
                onClick={() => {
                  if (openTerms === "6") {
                    setOpenTerms(null)
                  } else {
                    setOpenTerms("6")
                  }
                }}
                opened={openTerms === "6"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`7. ${t("termsCondition.cancellation")}`}
                list_info={(
                  <>
                    <li>
                      {t("termsCondition.cancellation1")}
                    </li>
                    <li>
                      {t("termsCondition.cancellation2")}
                      <ol type="I">
                        <li>
                          {t("termsCondition.arrivalDateTime")}
                        </li>
                        <li>
                          {t("termsCondition.departureDateTime")}
                        </li>
                        <li>
                          {t("field.flightNumber")}
                        </li>
                      </ol>
                    </li>
                    <li>
                      {t("termsCondition.cancellation3")}
                    </li>
                    <li>
                      {t("termsCondition.cancellation4")}
                    </li>
                    <li>
                      {t("termsCondition.cancellation5")}
                    </li>
                    <li>
                      {t("termsCondition.cancellation6")}
                    </li>
                    <li>
                      {t("termsCondition.cancellation7")}
                      <Row className="ml-1">
                        <Col md={3} className="pl-0">
                          <p className="text-bold m-0">
                            Email
                          </p>
                        </Col>
                        <Col md={9}>
                          joumpa@gapura.id
                        </Col>
                        <Col md={3} className="pl-0">
                          <p className="text-bold m-0">
                            Phone / Whatsapp
                          </p>
                        </Col>
                        <Col md={9}>
                          +62-811-8566-009
                        </Col>
                      </Row>
                    </li>
                  </>
                )}
                onClick={() => {
                  if (openTerms === "7") {
                    setOpenTerms(null)
                  } else {
                    setOpenTerms("7")
                  }
                }}
                opened={openTerms === "7"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`8. ${t("termsCondition.security")}`}
                list_info={(
                  <>
                    <li>
                      {t("termsCondition.securtiy1")}
                    </li>
                    <li>
                      {t("termsCondition.security2")}
                    </li>
                    <li>
                      {t("termsCondition.security3")}
                    </li>
                  </>
                )}
                onClick={() => {
                  if (openTerms === "8") {
                    setOpenTerms(null)
                  } else {
                    setOpenTerms("8")
                  }
                }}
                opened={openTerms === "8"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`9. ${t("termsCondition.dataUsage")}`}
                list_info={(
                  <>
                    <li>
                      {t("termsCondition.dataUsage1")}
                    </li>
                    <li>
                      {t("termsCondition.dataUsage2")}
                    </li>
                  </>
                )}
                onClick={() => {
                  if (openTerms === "9") {
                    setOpenTerms(null)
                  } else {
                    setOpenTerms("9")
                  }
                }}
                opened={openTerms === "9"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`10. ${t("termsCondition.other")}`}
                list_info={(
                  <>
                    <li>
                      {t("termsCondition.other1")}
                    </li>
                    <li>
                      {t("termsCondition.other2")}
                    </li>
                  </>
                )}
                onClick={() => {
                  if (openTerms === "10") {
                    setOpenTerms(null)
                  } else {
                    setOpenTerms("10")
                  }
                }}
                opened={openTerms === "10"}
              />
              <hr />
            </Card>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default withTrans(Index);
