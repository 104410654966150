import React, { Component } from "react";
import { ErrorMessage } from "formik";
import Moment from "moment";
import NumberFormat from 'react-number-format';
import "./forms.scss";
import { withTrans } from "../../../i18n/withTrans";

class Input extends Component {

  render() {
    return (
      <div className="form-group">
        {this.props?.title && (
          <label className="form-label">{this.props?.title}</label>
        )}
        <div className="input-wrapper" style={{ width: "100%", }}>
          {this.props.leftIcon && (
            <span className="material-icons-round left-icon-input">
              {this.props.leftIcon}
            </span>
          )}

          {
            this.props.currency && (
              <NumberFormat
                className={
                  "form-control form-input" +
                  this.props?.variant +
                  (this.props?.disabled ? "input-disabled " : "") +
                  (this.props?.errors && this.props?.touched
                    ? this.props?.errors[this.props?.name] &&
                      this.props?.touched[this.props?.name]
                      ? " is-invalid"
                      : ""
                    : "")
                }
                value={this.props?.value != null ? this.props?.value : ""}
                thousandSeparator={true}
                prefix={'Rp. '}
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  if (this.props.toggleCurrency) {
                    this.props?.toggleCurrency?.setFieldValue(`${this.props.name}`, value)
                  }
                }}
                style={{ padding: '25px', }}
              />
            )
          }

          {!this.props.currency && (
            <input
              {...this.props}
              className={
                "form-control form-input" +
                this.props?.variant +
                (this.props?.disabled ? "input-disabled " : "") +
                (this.props?.errors && this.props?.touched
                  ? this.props?.errors[this.props?.name] &&
                    this.props?.touched[this.props?.name]
                    ? " is-invalid"
                    : ""
                  : "")
              }
              value={this.props?.value != null ? this.props?.value : ""}
              onChange={this.props?.onChange}
              onKeyUp={this.props?.onKeyUp}
              onKeyDown={this.props?.onKeyDown}
              onWheel={(e) => this.props?.type === "number" ? e.target.blur() : console.log("")}
              disabled={this.props?.disabled}
              maxLength={this.props?.maxLength}
              style={this.props?.style ?? { padding: '25px', borderRadius: 6 }}
              min={this.props?.min}
              max={this.props?.max}
              step={this.props?.step}
              enabledHours={this.props?.enabledHours}
              data-date={
                this.props?.type === "date" && this.props?.value
                  ? Moment(this.props?.value).format("DD/MM/YYYY")
                  : "dd/mm/yyyy"
              }
              pattern={this.props.pattern}
              placeholder={this.props.placeholder ?? this.props.t('commons.input') + (this.props.title ?? 'data')}
            />)}


          {this.props.righticon && (
            <span
              className="material-icons-round right-icon-input"
              style={{ cursor: this.props.toggleicon ? "pointer" : "", top: 15 }}
              onClick={this.props.toggleicon}
            >
              {this.props.righticon}
            </span>
          )}

          {this.props?.rightLabel && (
            <span className="right-icon-input" style={{ cursor: this.props?.toggleicon ? 'pointer' : '', opacity: '0.8' }} onClick={this.props?.toggleicon}>{this.props?.rightLabel}</span>
          )}
          {this?.props?.errors && this?.props?.touched && (
            <ErrorMessage
              name={this.props?.name}
              component="div"
              className="invalid-feedback"
            />
          )}
        </div>
      </div>
    );
  }
}

export default withTrans(Input)
