import { React, useRef, useState, useEffect } from 'react'
import { Col, Container, Row } from "reactstrap";
import { Divider } from "@material-ui/core";
import { Formik, Form } from "formik";
import { connect, useDispatch } from "react-redux";
import moment from "moment";

import Button from "../../components/commons/buttons/Button";
import ButtonModal from "../../components/commons/modal/ButtonModal";
import Card from "../../components/commons/cards/card";
import CardHeader from "../../components/commons/cards/cardHeader";
import CardBody from "../../components/commons/cards/cardBody";
import CardFlush from "../../components/commons/cards/cardFlush";
import Input from "../../components/commons/forms/Input";
import Select2 from "../../components/commons/forms/Select2";
import InputPrepend from "../../components/commons/forms/InputPrepend";
import ConfirmationModal from "../../components/commons/modal/ConfirmModal";
import { withTrans } from "../../i18n/withTrans";
import { toastError } from "../../components/commons/toast";

//Service
import BookingService from "../../store/actions/booking";
import AirlineService from "../../store/actions/master_data/airline";

const Index = ({ t, error }) => {
  // document.documentElement.scrollTop = 0;
  const formikRef = useRef();
  const dispatch = useDispatch();
  const getDate = moment(new Date().setDate(new Date().getDate() + 1)).format("YYYY-MM-DD");
  const [width, setWidth] = useState(window.innerWidth);
  const [email, setEmail] = useState("");
  const [passengerName, setPassengerName] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [openBookingInfo, setOpenBookingInfo] = useState(false);
  const [openRescheduleForm, setOpenRescheduleForm] = useState(false);
  const [dataAirline, setDataAirline] = useState([]);
  const [dataAirlineTransfer, setDataAirlineTransfer] = useState([]);
  const [searchTextAirline, setSearchTextAirline] = useState([]);
  const [searchTextAirlineTransfer, setSearchTextAirlineTransfer] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmEditPassengerModal, setConfirmEditPassengerModal] = useState(false);
  const [editPassengerForm, setEditPassengerForm] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [data, setData] = useState({});

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 660;
  const isTabled = width <= 767 && width > 659;

  const initial = {
    id: data?.id,
    unique_transaction_id: data?.unique_transaction_id,
    date: "",
    time: "",
    date_transfer_to: "",
    time_transfer_to: "",
    airplane_code: "",
    airplane_code_transfer: "",
    airplane_name: "",
    airplane_name_transfer_to: "",
    airplane_number: "",
    airplane_number_transfer_to: "",
    selectedAirline: {},
    selectedAirlineTransfer: {},
  };

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirline,
      };
      dispatch(AirlineService.get(param, resolve));
    }).then((res) => {
      setDataAirline(
        res?.data?.map((item) => {
          return {
            value: item?.name,
            code: item?.code,
            label: `${item?.code} - ${item?.name}`,
          }
        })
      );
    });
  }, [searchTextAirline]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirlineTransfer,
      };
      dispatch(AirlineService.get(param, resolve));
    }).then((res) => {
      setDataAirlineTransfer(
        res?.data?.map((item) => {
          return {
            value: item?.name,
            code: item?.code,
            label: `${item?.code} - ${item?.name}`,
          }
        })
      );
    });
  }, [searchTextAirlineTransfer]);

  const handleFindBooking = () => {
    new Promise((resolve) => {
      let param = {
        id_booking: bookingId,
        email: email,
      };
      dispatch(BookingService.find_booking(param, resolve));
    }).then((res) => {
      if (res?.data) {
        setOpenBookingInfo(true);
        setData(res?.data);
      }
    });
  }

  const handleEditPassenger = () => {
    const callback = () => {
      setPassengerName("");
      setConfirmEditPassengerModal(false)
      setEditPassengerForm(false);
      handleFindBooking();
    };

    if (passengerName === "" || passengerName?.name === "") {
      toastError(`${t("commons.empty_data_message")} ${t("field.passengerName")}!`);
      return;
    }

    setLoadingSubmit(true);
    new Promise((resolve) => {
      const param = {
        ...passengerName,
        id_booking: bookingId,
        email: email
      };
      
      dispatch(BookingService.editPassenger(param, resolve, callback));
    }).then((res) => {
      setLoadingSubmit(false);
    });
  }

  const handleCancelEditPassenger = () => {
    setPassengerName("");
    setEditPassengerForm(false);
  }

  const handleCancelReschedule = () => {
    setOpenRescheduleForm(!openRescheduleForm);
    formikRef?.current?.resetForm();
  }

  useEffect(() => {
    if (error) {
      setLoadingSubmit(false);
    }
  }, [error]);

  const handleReschedule = () => {
    let values = formikRef?.current?.values;
    let isInvalidTime = false;

    if (data?.type_label_eng === "Transfer") {
      let time = moment(
        `${values?.date}, ${values?.time}`, 
        "YYYY-MM-DD HH:mm"
      )?.diff(moment().startOf("day"), "minutes");
  
      let timepick = moment(
        `${values?.date_transfer_to}, ${values?.time_transfer_to}`,
        "YYYY-MM-DD HH:mm"
      )?.diff(moment().startOf("day"), "minutes");
  
      if (time > timepick) {
        isInvalidTime = true;
      }
    }

    if (isInvalidTime) {
      toastError(t("commons.transfer_message"));
      return;
    } else if (
      values.date !== "" &&
      values.date !== null &&
      values?.date < getDate
    ) {
      toastError(
        `${t("commons.orderDateHandle")} ${moment(getDate).format("DD/MM/YYYY")}!`
      );
      return;
    } else if (
      data?.type_label_eng === "Transfer" &&
      values.date_transfer_to !== "" &&
      values.date_transfer_to !== null &&
      values?.date_transfer_to < getDate
    ) {
      toastError(
        `${t("commons.orderDateHandle")} ${moment(getDate).format("DD/MM/YYYY")}!`
      );
      return;
    }  else if (
      (values?.date === "" || values?.date === null) &&
      (values?.time === "" || values?.time === null) &&
      (values?.airplane_code === "" || values?.airplane_code === null) &&
      (values?.airplane_name === "" || values?.airplane_name === null) &&
      (values?.airplane_number === "" || values?.airplane_number === null)
    ) {
      if (
        data?.type_label_eng === "Transfer" &&
        (values?.date_transfer_to === "" || values?.date_transfer_to === null) &&
        (values?.time_transfer_to === "" || values?.time_transfer_to === null) &&
        (values?.airplane_code_transfer === "" || values?.airplane_code_transfer === null) &&
        (values?.airplane_name_transfer_to === "" || values?.airplane_name_transfer_to === null) &&
        (values?.airplane_number_transfer_to === "" || values?.airplane_number_transfer_to === null)
      ) {
        toastError(
          `${t("commons.empty_data_message")} Reschedule!`
        );
        return;
      }
      toastError(
        `${t("commons.empty_data_message")} Reschedule!`
      );
      return;
    } else {
      let param = {
        id_booking: bookingId,
        email: email,
        airplane_code:
          values?.airplane_code === "" 
            ? data?.airplane_code 
            : values?.airplane_code,
        airplane_code_transfer:
          values?.airplane_code_transfer === "" 
            ? data?.airplane_code_transfer
            : values?.airplane_code_transfer,
        airplane_name:
          values?.airplane_name === "" 
            ? data?.airplane_name 
            : values?.airplane_name,
        airplane_name_transfer_to:
          values?.airplane_name_transfer_to === ""
            ? data?.airplane_name_transfer_to
            : values?.airplane_name_transfer_to,
        airplane_number:
          values?.airplane_number === ""
            ? data?.airplane_number
            : values?.airplane_number,
        airplane_number_transfer_to:
          values?.airplane_number_transfer_to === "" 
            ? data?.airplane_number_transfer_to
            : values?.airplane_number_transfer_to,
      };
  
      if(data?.type_label_eng === 'Arrival') {
        param.date_arrival = values?.date === "" ? data?.date : values?.date;
        param.time_arrival = values?.time === "" ? data?.time : values?.time;
      } else if (data?.type_label_eng === 'Departure') {
        param.date_departure = values?.date === "" ? data?.date : values?.date;
        param.time_departure = values?.time === "" ? data?.time : values?.time;
      } else {
        param.date_arrival = values?.date === "" ? data?.date : values?.date;
        param.time_arrival = values?.time === "" ? data?.time : values?.time;
        param.date_departure =
          values?.date_transfer_to === ""
            ? data?.date_transfer_to
            : values?.date_transfer_to;
        param.time_departure =
          values?.time_transfer_to === ""
            ? data?.time_transfer_to
            : values?.time_transfer_to;
      }

      const callback = () => {
        setConfirmModal(false)
        setOpenRescheduleForm(false);
        handleFindBooking();
      };

      setLoadingSubmit(true);
      new Promise((resolve) => {
        dispatch(BookingService.rescheduleBooking(param, resolve, callback));
      }).then((res) => {
        setLoadingSubmit(false);
      });
    }
  }

  return (
    <>
      <Container fluid>
        <section id="manage-booking">
          <div className="product-bg d-flex">
            <Row className="m-auto justify-content-center">
              <Col md={8}>
                <div className="super-title text-center text-extra-bold">
                  {t("manageBooking.headline")}
                </div>
              </Col>
            </Row>
          </div>
          <div className="content">
            <Container>
              <Row className="justify-content-center">
                <Col lg={8} md={10}>
                  <Card
                    className="rounded" 
                    style={{ 
                      borderStyle: 'none',
                      boxShadow: '0px 23px 80px 0px rgba(0, 0, 0, 0.06)',
                    }}
                  >
                    <CardHeader
                      title={(
                        <p className="medium-title text-extra-bold text-primary">
                          {t("manageBooking.cardFindBookingTitle")}
                        </p>
                      )}
                    />
                    <CardBody style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="text-bold mb-1">{t("field.bookingId")}</div>
                        <Col md={9} xs={8}>
                          <Input
                            name="bookingId"
                            type="text"
                            value={bookingId}
                            onChange={(e) => {
                              let value = e.target.value;
                              setBookingId(value);
                            }}
                          />
                        </Col>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="text-bold mb-1">{t("field.email")}</div>
                        <Col md={9} xs={8}>
                          <Input
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e) => {
                              let value = e.target.value;
                              setEmail(value);
                            }}
                          />
                        </Col>
                      </div>
                    </CardBody>
                    <Row
                      className="justify-content-end pt-0 pb-4 px-5"
                    >
                      <Button
                        rounded
                        title={t("manageBooking.findBooking")}
                        variant={`${isTabled ? "mt-2 primary" : "primary"}`}
                        onClick={handleFindBooking}
                      />
                    </Row>
                  </Card>
                </Col>
              </Row>
              {openBookingInfo && (
                <Row className="justify-content-center mt-5">
                  <Col lg={8} md={10}>
                    <p className="d-flex justify-content-center medium-title text-extra-bold">
                      {t("manageBooking.bookingFound")}
                    </p>
                    <Card
                      className="rounded" 
                    >
                      <CardHeader
                        title={t("field.bookingInfo")}
                      />
                      <CardBody style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <CardFlush
                          list
                          withoutDivider={true}
                          label={t("field.bookingId")}
                          desc={bookingId}
                        />
                        <CardFlush
                          list
                          withoutDivider={true}
                          label={t("field.email")}
                          desc={email}
                        />
                        <CardFlush
                          list
                          withoutDivider={true}
                          label={t("field.passenger")}
                          desc={(
                            data?.list_order_passengers?.length && 
                            data?.list_order_passengers?.map((item, index)=> {
                              return editPassengerForm === index ? (
                                <Input
                                  name="passengerName"
                                  type="text"
                                  value={passengerName !== "" ? passengerName?.name : ""}
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    setPassengerName({
                                      id_passenger: item?.id,
                                      name: value
                                    });
                                  }}
                                />
                              ) : (
                                <p
                                  className="d-flex justify-content-between align-items-center"
                                  onClick={()=> setEditPassengerForm(index)}
                                >
                                  {item?.name}
                                  <div className="left-icon float-left rounded-fill"
                                    style={{ padding: "4px 6px 1px 6px"}}>
                                    <span
                                      className="material-icons clickable"
                                      style={{ fontSize: "15px", color: "#63ae5c" }}
                                    >
                                      edit
                                    </span>
                                  </div>
                                </p>
                              )
                            })
                          )}
                          size={{ desc: "8" }}
                        />
                        {editPassengerForm !== false && (
                          <ButtonModal
                            toggle={handleCancelEditPassenger}
                            confirm={() => setConfirmEditPassengerModal(!confirmEditPassengerModal)}
                            confirmTitle={t("commons.save")}
                            typeConfirm={"Button"}
                          />
                        )}
                        <Divider
                          orientation="horizontal"
                          className="mt-5 mb-4"
                          style={{
                            marginLeft: "-32px",
                            marginRight: "-32px",
                          }}
                        />
                        <div
                          className="pt-3 pb-4 text-bold text-primary"
                          style={{ fontSize: 16 }}
                        >
                          {t("field.flightInformation")}
                        </div>
                        <Row>
                          <Col md={6}>
                            <Input
                              name="date"
                              type="date"
                              disabled
                              value={data?.date}
                              title={data?.type_label_eng === "Departure" ? t("field.departureDate") : t("field.arrivalDate")}
                            />
                          </Col>
                          <Col md={6}>
                            <Input
                              name="time"
                              disabled
                              value={data?.time}
                              title={data?.type_label_eng === "Departure" 
                                ? t("field.departureTime") 
                                : t("field.arrivalTime")
                              }
                              type="time"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Input
                              name="airplane_name"
                              type="text"
                              disabled
                              value={data?.airplane_name}
                              title={data?.type_label_eng === "Departure" 
                                ? t("field.deptAirline") 
                                : t("field.arrvAirline")
                              }
                            />
                          </Col>
                          <Col md={6}>
                            <div className="form-group" style={{ marginBottom: "0" }}>
                              <label
                                className="form-label"
                                style={{ marginBottom: "5px" }}
                              >
                                {t("field.flightNumber")}
                              </label>
                            </div>
                            <InputPrepend
                              name="airplane_number"
                              label={t("field.flightNumber")}
                              title={data?.airplane_code}
                              value={data?.airplane_number}
                              type="text"
                              width="25%"
                              mt="2"
                              disabled
                            />
                          </Col>
                        </Row>
                        {data?.type_label_eng === "Transfer" && (
                          <>
                          <hr style={{ marginTop: '0.5rem' }} />
                          <Row>
                            <Col md={6}>
                              <Input
                                name="date_transfer_to"
                                type="date"
                                disabled
                                value={data?.date_transfer_to}
                                title={t("field.departureDate")}
                              />
                            </Col>
                            <Col md={6}>
                              <Input
                                name="time_transfer_to"
                                disabled
                                value={data?.time_transfer_to}
                                title="Estimate Time Departure"
                                type="time"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <Input
                                name="airplane_name_transfer_to"
                                type="text"
                                disabled
                                value={data?.airplane_name_transfer_to}
                                title={t("field.deptAirline")}
                              />
                            </Col>
                            <Col md={6}>
                              <div className="form-group" style={{ marginBottom: "0" }}>
                              <label
                                className="form-label"
                                style={{ marginBottom: "5px" }}
                              >
                                {t("field.flightNumber")}
                              </label>
                            </div>
                              <InputPrepend
                                disabled
                                name="airplane_number"
                                label={t("field.flightNumber")}
                                title={data?.airplane_code_transfer}
                                value={data?.airplane_number_transfer_to}
                                type="text"
                                width="25%"
                                mt="2"
                              />
                            </Col>
                          </Row>
                          </>
                        )}
                        {!openRescheduleForm && (
                          <Row
                            className="justify-content-end py-4 px-5"
                          >
                            <Button
                              rounded
                              title="Reschedule"
                              variant={`${isTabled ? "mt-2 primary" : "primary"}`}
                              onClick={() => setOpenRescheduleForm(!openRescheduleForm)}
                            />
                          </Row>
                        )}
                        {openRescheduleForm && (
                          <Formik
                            enableReinitialize
                            innerRef={formikRef}
                            initialValues={initial}
                          >
                            {(props) => (
                              <Form>
                                <Divider
                                  orientation="horizontal"
                                  className="my-4"
                                  style={{
                                    marginLeft: "-32px",
                                    marginRight: "-32px",
                                  }}
                                />
                                <div
                                  className="pt-3 pb-4 text-bold text-primary"
                                  style={{ fontSize: 16 }}
                                >
                                  {`Reschedule ${t("field.flightInformation")}`}
                                </div>
                                <Row>
                                  <Col md={6}>
                                    <Input
                                      name="date"
                                      type="date"
                                      min={getDate}
                                      errors={props.errors}
                                      touched={props.touched}
                                      {...props.getFieldProps("date")}
                                      title={data?.type_label_eng === "Departure" ? t("field.departureDate") : t("field.arrivalDate")}
                                    />
                                  </Col>
                                  <Col md={6}>
                                    <Input
                                      name="time"
                                      errors={props.errors}
                                      touched={props.touched}
                                      {...props.getFieldProps("time")}
                                      title={data?.type_label_eng === "Departure" 
                                        ? t("field.departureTime")
                                        : t("field.arrivalTime")
                                      }
                                      type="time"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6}>
                                    <Select2
                                      title={data?.type_label_eng === "Departure" 
                                        ? t("field.deptAirline") 
                                        : t("field.arrvAirline")
                                      }
                                      name="airplane_name"
                                      options={dataAirline}
                                      errors={props.errors}
                                      touched={props.touched}
                                      value={props.values?.airplane_name ?? ''}
                                      onInputChange={(keyword) => {
                                        if (keyword !== "") {
                                          setSearchTextAirline(keyword);
                                        }
                                      }}
                                      onChange={(name, value) => {
                                        props.setFieldValue("airplane_name", value);
                                        let airplaneCode = dataAirline?.filter(function (el) {
                                          return el.value == value;
                                        });
                                        props.setFieldValue(
                                          "airplane_code",
                                          airplaneCode[0].code
                                        );
                                        props.setFieldValue("selectedAirline", {
                                          value: value,
                                          code: airplaneCode[0].code,
                                          label: `${airplaneCode[0].code} - ${value}`,
                                        });
                                      }}
                                      onBlur={props.setFieldTouched}
                                    />
                                  </Col>
                                  <Col md={6}>
                                    <div className="form-group" style={{ marginBottom: "0" }}>
                                      <label
                                        className="form-label"
                                        style={{ marginBottom: "5px" }}
                                      >
                                        {t("field.flightNumber")}
                                      </label>
                                    </div>
                                    <InputPrepend
                                      name="airplane_number"
                                      label={t("field.flightNumber")}
                                      title={props.values.airplane_code}
                                      type="text"
                                      width="25%"
                                      mt="2"
                                      touched={props.touched}
                                      {...props.getFieldProps("airplane_number")}
                                    />
                                  </Col>
                                </Row>
                                {data?.type_label_eng === "Transfer" && (
                                  <>
                                    <hr style={{ marginTop: '0.5rem' }} />
                                    <Row>
                                      <Col md={6}>
                                        <Input
                                          title={t("field.departureDate")}
                                          name="date_transfer_to"
                                          type="date"
                                          min={props?.values?.date}
                                          errors={props.errors}
                                          touched={props.touched}
                                          {...props.getFieldProps("date_transfer_to")}
                                          onChange={(e) => {
                                            let value = e.target.value;
                                            props.setFieldValue("date_transfer_to", value);
                                          }}
                                        />
                                      </Col>
                                      <Col md={6}>
                                        <Input
                                          name="time_transfer_to"
                                          title="Estimate Time Departure"
                                          type="time"
                                          errors={props.errors}
                                          touched={props.touched}
                                          {...props.getFieldProps("time_transfer_to")}
                                          onChange={(e) => {
                                            let value = e.target.value;
                                            props.setFieldValue("time_transfer_to", value);
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={6}>
                                        <Select2
                                          title={t("field.deptAirline")}
                                          name="airplane_name_transfer_to"
                                          options={dataAirlineTransfer}
                                          errors={props.errors}
                                          touched={props.touched}
                                          value={props.values?.airplane_name_transfer_to}
                                          onInputChange={(keyword) => {
                                            if (keyword !== "") {
                                              setSearchTextAirlineTransfer(keyword);
                                            }
                                          }}
                                          onChange={(name, value) => {
                                            props.setFieldValue("airplane_name_transfer_to", value);
                                            let airplaneTransferCode = dataAirlineTransfer?.filter(
                                              function (el) {
                                                return el.value == value;
                                              }
                                            );
                                            props.setFieldValue(
                                              "airplane_code_transfer",
                                              airplaneTransferCode[0].code
                                            );
                                            props.setFieldValue("selectedAirlineTransfer", {
                                              value: value,
                                              code: airplaneTransferCode[0].code,
                                              label: `${airplaneTransferCode[0].code} - ${value}`,
                                            });
                                          }}
                                          onBlur={props.setFieldTouched}
                                        />
                                      </Col>
                                      <Col md={6}>
                                        <div className="form-group" style={{ marginBottom: "0" }}>
                                          <label
                                            className="form-label"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {t("field.flightNumber")}
                                          </label>
                                        </div>
                                        <InputPrepend
                                          name="airplane_number_transfer_to"
                                          label={t("field.flightNumber")}
                                          title={props.values.airplane_code_transfer}
                                          type="text"
                                          width="25%"
                                          mt="2"
                                          touched={props.touched}
                                          {...props.getFieldProps("airplane_number_transfer_to")}
                                        />
                                      </Col>
                                    </Row>
                                  </>
                                )}
                                <ButtonModal
                                  toggle={handleCancelReschedule}
                                  confirm={() => setConfirmModal(!confirmModal)}
                                  confirmTitle={t("commons.save")}
                                  typeConfirm={"Button"}
                                  // icon
                                />
                              </Form>
                            )}
                          </Formik>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </Container>
          </div>
        </section>
      </Container>
      <ConfirmationModal
        show={confirmModal}
        toggle={()=> setConfirmModal(!confirmModal)}
        confirm={handleReschedule}
        messageData={`` + data?.unique_transaction_id}
        icon="info"
        message={`Are you sure reschedule booking `}
        disabledConfirm={loadingSubmit}
      />
      <ConfirmationModal
        show={confirmEditPassengerModal}
        toggle={()=> {
          setConfirmEditPassengerModal(!confirmEditPassengerModal);
          handleCancelEditPassenger();
        }}
        confirm={handleEditPassenger}
        icon="info"
        message={`${t("confirm.edit")} ${t("field.passengerName")}`}
        disabledConfirm={loadingSubmit}
      />
    </>
  );
};

const mapStateToProps = ({ booking: { pending, error } }) => {
  return { pending, error };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Index));
