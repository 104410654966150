import React, {useState, useEffect, useRef} from "react";
import { useDispatch } from "react-redux";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Container,
} from 'reactstrap';
import {withTrans} from "../../../../i18n/withTrans";
import {Divider} from "@material-ui/core";
import Button from "../../../../components/commons/buttons/Button";
import SearchButton from "../../../../components/commons/buttons/SearchButton";
import Promo from "../../../../store/actions/promo";
import moment from "moment";

const VoucherModal = ({formik, show, toggle, t}) => {

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState()
    const handleSearch = (value) => {
        setSearchText(value.length > 0 ? value : "");
    };
    const [voucher, setVoucher] = useState([]);

    function differents(item) {
        var start_date = moment()?.format("YYYY-MM-DD");
        var end_date = moment(item?.period_end);
        let period = moment.duration(end_date.diff(start_date)).asDays();
        return period;
    }

    const handleRefresh = () => {
        new Promise((resolve) => {
            let param = {
                length: 999,
                search_text: searchText,
                airport_id:
                    formik?.values?.flight_type === 0
                        ? formik?.values?.airport_id_to
                        : formik?.values?.flight_type === 2
                            ? formik?.values?.airport_id_transfer
                            : formik?.values?.airport_id_from,
                valid: true,
                service_date: formik?.values?.date,
                product_id: formik?.values?.product_id
            };
            dispatch(Promo.get(param, resolve));
        }).then((res) => {
            setVoucher(res?.data);
        });
    };

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    useEffect(() => {
        handleRefresh();
    }, [searchText]);

    const handleAddVoucher = (item) => {
        formik.setFieldValue("promo_id", item?.id)
        formik.setFieldValue("promo_name", item?.name)
        formik.setFieldValue("promo_percent", item?.discount_percent)
        formik.setFieldValue("promo_price", item?.discount_price)

        const subTotal = formik.values.subTotal
        if (item.discount_percent > 0) {
            const disc_grand_percent = item.discount_percent / 100 * subTotal
            formik.setFieldValue("discount", disc_grand_percent);
            const superGrandTotal = subTotal - disc_grand_percent;
            formik.setFieldValue("grandTotalPrice", superGrandTotal);
            formik.setFieldValue("gross_amount", superGrandTotal);
        } else if (item.discount_price > 0) {
            const disc_grand_price = item.discount_price;
            formik.setFieldValue("discount", disc_grand_price);
            const superGrandTotal = subTotal - disc_grand_price;
            formik.setFieldValue("grandTotalPrice", superGrandTotal);
            formik.setFieldValue("gross_amount", superGrandTotal);
        } else {
            formik.setFieldValue("discount", 0);
            formik.setFieldValue("grandTotalPrice", subTotal);
            formik.setFieldValue("gross_amount", subTotal);
        }
        toggle()
    }
    return (
        <>
            <Modal isOpen={show} modalTransition={{timeout: 700}} backdropTransition={{timeout: 1300}}>
                <ModalHeader className="text-extra-bold pt-5"> {'Voucher'} <span className="close clickable" onClick={toggle}>&times;</span></ModalHeader>
                <ModalBody>
                    <Container className='pb-40'>
                        <SearchButton
                            placeholder={t("commons.enterKeyword")}
                            toggle={(value) => handleSearch(value)}
                            showStyle={true}
                            width={{width: "400px"}}
                        />
                        <div
                            style={{
                                maxHeight: "400px",
                                overflowY: "scroll",
                                overflowX: "clip",
                            }}
                            className="mt-4"
                        >
                            {voucher?.length > 0 ? (
                                voucher?.map((item, index) => (
                                    <>
                                        <div className="my-3">
                                            <div className="d-flex justify-content-between">
                                                <div className="title-voucher mr-3">
                                                    <div className="text-extra-bold pb-1">{`Diskon ${item?.discount_price !== 0 ? 'Rp' + numberWithCommas(`${item?.discount_price}`) : item.discount_percent + '%'} ${item.name}`}</div>
                                                    <div className="normal-title">
                                                        {moment(item?.period_end).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")
                                                            ? `${t("field.expired")} ${differents(item)} ${t("field.day")}`
                                                            : `${t("field.expired")} ${t("field.today")}`}
                                                    </div>
                                                </div>
                                                <div className="button-voucher">
                                                    <Button
                                                        title={t("commons.apply")}
                                                        rounded={true}
                                                        variant="primary"
                                                        confirmTitle={t("commons.save")}
                                                        onClick={() => handleAddVoucher(item)}
                                                        icon
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <Divider orientation="horizontal"/>
                                    </>
                                ))
                            ) : (
                                <div className="text-center">
                                    {t("commons.dataNotFound")}
                                </div>
                            )}
                        </div>
                    </Container>
                </ModalBody>
            </Modal>
        </>
    )
}

export default withTrans(VoucherModal)
