import React, { useState, useEffect, useRef, useMemo } from "react";
import { Row, Col } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import { toastError } from "../../../components/commons/toast";
import { withTrans } from "../../../i18n/withTrans";
import Card from "../../../components/commons/cards/card";
import CardHeader from "../../../components/commons/cards/cardHeader";
import CardBody from "../../../components/commons/cards/cardBody";
import Input from "../../../components/commons/forms/Input";
import InputPrepend from "../../../components/commons/forms/InputPrepend";
import Select2 from "../../../components/commons/forms/Select2";
import DynamicButton from "../../../components/commons/forms/DynamicButton";
import moment from "moment";
import debounce from "lodash.debounce";

//SERVICE
import AirportService from "../../../store/actions/master_data/airport";
import AirlineService from "../../../store/actions/master_data/airline";
import ProductService from "../../../store/actions/master_data/product";

const FlightReservation = ({ formik, button, t }) => {
  const dispatch = useDispatch();
  const storedReservation = localStorage.getItem("new_reservation")
    ? JSON.parse(localStorage.getItem("new_reservation"))
    : null;

  const [dataAirport, setDataAirport] = useState([]);
  const [dataAirportDomestic, setDataAirportDomestic] = useState([]);
  const [dataAirportTransfer, setDataAirportTransfer] = useState([]);
  const [dataAirline, setDataAirline] = useState([]);
  const [dataAirlineTransfer, setDataAirlineTransfer] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);

  const [searchTextAirport, setSearchTextAirport] = useState(
    storedReservation && storedReservation?.typeSearchAirport
      ? storedReservation?.searchTextAirport
      : []
  );
  const [searchAirportDomestic, setSearchAirportDomestic] = useState(
    storedReservation && storedReservation?.typeSearchAirportDomestic
      ? storedReservation?.searchAirportDomestic
      : []
  );
  const [searchAirportTransfer, setSearchAirportTransfer] = useState(
    storedReservation && storedReservation?.typeSearchAirportTransfer
      ? storedReservation?.searchAirportTransfer
      : []
  );
  const [searchTextAirline, setSearchTextAirline] = useState([]);
  const [searchTextAirlineTransfer, setSearchTextAirlineTransfer] = useState([]);

  const getDate = moment(new Date().setDate(new Date().getDate() + 1)).format(
    "YYYY-MM-DD"
  );

  useEffect(() => {
    if (!storedReservation) {
      return;
    }

    formik?.setFieldValue("flight_type", storedReservation?.flight_type);
    formik?.setFieldValue("airport_id_to", storedReservation?.airport_id_to);
    formik?.setFieldValue(
      "airport_name_to",
      storedReservation?.airport_name_to
    );
    formik?.setFieldValue(
      "airport_uniform_to",
      storedReservation?.airport_uniform_to
    );
    formik?.setFieldValue(
      "airport_id_from",
      storedReservation?.airport_id_from
    );
    formik?.setFieldValue(
      "airport_name_from",
      storedReservation?.airport_name_from
    );
    formik?.setFieldValue(
      "airport_uniform_from",
      storedReservation?.airport_uniform_from
    );
    formik?.setFieldValue(
      "airport_id_transfer",
      storedReservation?.airport_id_transfer
    );
    formik?.setFieldValue(
      "airport_name_transfer",
      storedReservation?.airport_name_transfer
    );
    formik?.setFieldValue(
      "airport_uniform_transfer",
      storedReservation?.airport_uniform_transfer
    );
    formik?.setFieldValue("date", storedReservation?.date);
    formik?.setFieldValue(
      "date_transfer_to",
      storedReservation?.date_transfer_to
    );
    if (storedReservation?.flight_type === 2) {
      formik.setFieldValue("list_available_services", [
        {
          id: dataProduct[2]?.id,
          name: "Transfer",
          label: "TRANSFER",
          type: 4,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirport,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      if (res?.data) {
        setDataAirport(res?.data);
      }
    });
  }, [searchTextAirport]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchAirportTransfer,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      if (res?.data) {
        setDataAirportTransfer(res?.data);
      }
    });
  }, [searchAirportTransfer]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchAirportDomestic,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      if (res?.data) {
        setDataAirportDomestic(res?.data);
      }
    });
  }, [searchAirportDomestic]);

  const debouncedResults = debounce((value, type) => {
    if (value !== "") {
      if (type === "domestic") {
        setSearchAirportDomestic(value);
      } else if (type === "transfer") {
        setSearchAirportTransfer(value);
      } else if (type === "inter") {
        setSearchTextAirport(value);
      }
    }
  }, 500);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirline,
      };
      dispatch(AirlineService.get(param, resolve));
    }).then((res) => {
      if (res?.data) {
        setDataAirline(res?.data);
      }
    });
  }, [searchTextAirline]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirlineTransfer,
      };
      dispatch(AirlineService.get(param, resolve));
    }).then((res) => {
      if (res?.data) {
        setDataAirlineTransfer(res?.data);
      }
    });
  }, [searchTextAirlineTransfer]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 999,
      };
      dispatch(ProductService.get(param, resolve));
    }).then((res) => {
      if (res?.data) {
        setDataProduct(res?.data);
      }
    });
  }, []);

  const handleFlightType = (value) => {
    setSearchAirportDomestic("");
    setSearchAirportTransfer("");
    setSearchTextAirport("");
    setSearchTextAirline("");
    setSearchTextAirlineTransfer("");
    formik.setFieldValue("product_type", 0);
    formik.setFieldValue("airplane_code", null);
    formik.setFieldValue("airplane_code_transfer", null);
    formik.setFieldValue("airplane_name", null);
    formik.setFieldValue("airplane_number", null);
    formik.setFieldValue("date", null);
    formik.setFieldValue("time", null);
    switch (value) {
      case "arrival":
        formik.setFieldValue("list_available_services", []);
        formik.setFieldValue("flight_type", 0);
        formik.setFieldValue("airport_id_from", null);
        formik.setFieldValue("airport_id_to", null);
        break;
      case "departure":
        formik.setFieldValue("list_available_services", []);
        formik.setFieldValue("flight_type", 1);
        formik.setFieldValue("airport_id_from", null);
        formik.setFieldValue("airport_id_to", null);
        break;
      case "transfer":
        formik.setFieldValue("list_available_services", [
          {
            id: dataProduct[2].id,
            name: "Transfer",
            label: "TRANSFER",
            type: 4,
          },
        ]);
        formik.setFieldValue("product_id", dataProduct[2].id);
        formik.setFieldValue("flight_type", 2);
        formik.setFieldValue("airport_id_transfer", null);
        formik.setFieldValue("airport_id_from", null);
        formik.setFieldValue("airport_id_to", null);
        formik.setFieldValue("airport_uniform_transfer_to", null);
        break;
      default:
        formik.setFieldValue("flight_type", 0);
        break;
    }
  };

  const handleProductSelected = (item) => {
    let selectedType = item?.type;
    let selectedName = item?.name;
    let productType = formik.values.product_type;

    if (selectedType) {
      if (productType === selectedType) {
        selectedType = null;
        selectedName = null;
      }
    }
    formik.setFieldValue("available_service", selectedName);
    formik.setFieldValue("product_type", selectedType);
  };

  useEffect(() => {
    if (formik?.values?.flight_type !== 2) {
      if (formik?.values?.flight_type === 0) {
        formik.setFieldValue("flight_type_code", 5);
      } else {
        formik.setFieldValue("flight_type_code", 6);
      }
    } else {
      if (
        formik?.values?.airport_uniform_from !== "Indonesia" &&
        formik?.values?.airport_uniform_to !== "Indonesia"
      ) {
        formik.setFieldValue("flight_type_code", 1);
      } else if (
        formik?.values?.airport_uniform_from !== "Indonesia" &&
        formik?.values?.airport_uniform_to === "Indonesia"
      ) {
        formik.setFieldValue("flight_type_code", 2);
      } else if (
        formik?.values?.airport_uniform_from === "Indonesia" &&
        formik?.values?.airport_uniform_to !== "Indonesia"
      ) {
        formik.setFieldValue("flight_type_code", 3);
      } else if (
        formik?.values?.airport_uniform_from === "Indonesia" &&
        formik?.values?.airport_uniform_to === "Indonesia"
      ) {
        formik.setFieldValue("flight_type_code", 4);
      }
    }
  }, [
    formik?.values?.flight_type,
    formik?.values?.airport_uniform_from,
    formik?.values?.airport_uniform_to,
  ]);

  useEffect(() => {
    if (
      formik?.values.flight_type >= 0 &&
      formik?.values?.airport_id_from &&
      formik?.values?.airport_id_to
    ) {
      let isInternational =
        formik?.values?.airport_uniform_from !== "Indonesia" ||
        formik?.values?.airport_uniform_to !== "Indonesia";
      let services = [];
      if (formik?.values.flight_type !== 2 && dataProduct) {
        if (isInternational) {
          services = [
            ...services,
            // {
            //   label: "FAST TRACK",
            //   name: "Fast Track",
            //   type: 3,
            // },
            {
              label: "MEET & GREET INTERNATIONAL",
              name: "Meet and Greet International",
              type: 1,
            },
          ];
        } else {
          services = [
            ...services,
            {
              label: "MEET & GREET DOMESTIC",
              name: "Meet and Greet Domestic",
              type: 2,
            },
          ];
        }
        formik.setFieldValue("list_available_services", services);
      } else {
        services = [
          ...services,
          {
            label: "TRANSFER",
            name: "Transfer",
            type: 4,
          },
        ];
        formik.setFieldValue("list_available_services", services);
      }
    }
  }, [
    formik?.values?.airport_id_from,
    formik?.values?.airport_id_to,
    formik?.values.flight_type,
  ]);

  useEffect(() => {
    if (formik?.values?.product_type > 0) {
      const serviceInAvailableServices =
        formik?.values?.list_available_services?.find(
          (item) => item?.type === formik?.values?.product_type
        );

      if (!serviceInAvailableServices) {
        formik.setFieldValue("product_type", 0);
      }
    }
  }, [formik?.values?.list_available_services]);

  let airport_list = [];
  dataAirport?.forEach((airport) => {
    airport_list.push({
      value: airport?.id,
      label: `${airport?.code}-${airport?.city}-${airport?.name} ${
        airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
      }`,
    });
  });
  //if airport_list does not have the same value as selectedAirport, 
  //push selectedAirport in list airport_list
  //so airport search results can still appear in the dropdown
  const indexSameAirport = airport_list.findIndex(
    (x) => x.value == formik?.values?.selectedAirport.value
  );
  if (indexSameAirport === -1) {
    airport_list.push(formik?.values?.selectedAirport);
  }

  let airport_list_transfer = [];
  dataAirportTransfer?.forEach((airport) => {
    airport_list_transfer.push({
      value: airport?.id,
      label: `${airport?.code}-${airport?.city}-${airport?.name} ${
        airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
      }`,
    });
  });
  //if airport_list_transfer does not have the same value as selectedAirportTransfer, 
  //push selectedAirportTransfer in list airport_list_transfer
  //so airport search results can still appear in the dropdown 
  const indexSameAirportTransfer = airport_list_transfer.findIndex(
    (x) => x.value == formik?.values?.selectedAirportTransfer.value
  );
  if (indexSameAirportTransfer === -1) {
    airport_list_transfer.push(formik?.values?.selectedAirportTransfer);
  }

  let airport_list_indo = [];
  dataAirportDomestic?.forEach((airport) => {
    if (airport.uniform === "Indonesia") {
      airport_list_indo.push({
        value: airport?.id,
        label: `${airport?.code}-${airport?.city}-${airport?.name} ${
          airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
        }`,
      });
    }
  });
  //if airport_list_indo does not have the same value as selectedAirportDomestic, 
  //push selectedAirportDomestic in list airport_list_indo
  //so airport search results can still appear in the dropdown 
  const indexSameAirportDomestic = airport_list_indo.findIndex(
    (x) => x.value == formik?.values?.selectedAirportDomestic.value
  );
  if (indexSameAirportDomestic === -1) {
    airport_list_indo.push(formik?.values?.selectedAirportDomestic);
  }

  let airline_list = [];
  dataAirline?.forEach((airline) => {
    airline_list.push({
      value: airline?.name,
      code: airline?.code,
      label: `${airline?.code} - ${airline?.name}`,
    });
  });
  //if airline_list does not have the same value as selectedAirline, 
  //push selectedAirline in list airline_list
  //so airport search results can still appear in the dropdown 
  const indexSameAirline = airline_list.findIndex(
    (x) => x.value == formik?.values?.selectedAirline.value
  );
  if (indexSameAirline === -1) {
    airline_list.push(formik?.values?.selectedAirline);
  }

  let airline_list_transfer = [];
  dataAirlineTransfer?.forEach((airline) => {
    airline_list_transfer.push({
      value: airline?.name,
      code: airline?.code,
      label: `${airline?.code} - ${airline?.name}`,
    });
  });
  //if airline_transfer does not have the same value as selectedAirlineTransfer, 
  //push selectedAirlineTransfer in list airline_transfer
  //so airport search results can still appear in the dropdown 
  const indexSameAirlineTransfer = airline_list_transfer.findIndex(
    (x) => x.value == formik?.values?.selectedAirlineTransfer.value
  );
  if (indexSameAirlineTransfer === -1) {
    airline_list_transfer.push(formik?.values?.selectedAirlineTransfer);
  }

  return (
    <Row>
      <Col lg={9} md={12}>
        <Card rounded>
          <CardHeader title={t("field.flightType")} />
          <CardBody>
            <div className="row justify-content-left">
              <Col xl={4} lg={12} md={12} sm={12}>
                <DynamicButton
                  titleLeft={t("field.arrival")}
                  iconLeft="flight_land"
                  active={formik.values.flight_type === 0}
                  toggle={() => handleFlightType("arrival")}
                />
              </Col>
              <Col xl={4} lg={12} md={12} sm={12}>
                <DynamicButton
                  titleLeft={t("field.departure")}
                  iconLeft="flight_takeoff"
                  active={formik.values.flight_type === 1}
                  toggle={() => handleFlightType("departure")}
                />
              </Col>
              <Col xl={4} lg={12} md={12} sm={12}>
                <DynamicButton
                  titleLeft="Transfer"
                  iconLeft="connecting_airports"
                  iconLeftStyle="36px"
                  customPaddingBtn="14px 24px"
                  active={formik.values.flight_type === 2}
                  toggle={() => handleFlightType("transfer")}
                />
              </Col>
            </div>
          </CardBody>
        </Card>
        {formik.values.flight_type !== 2 && (
          <Card rounded>
            <CardHeader title={t("field.flightInformation")} />
            <CardBody>
              <div className="row justify-content-between">
                <Col md={3} sm={12}>
                  <div className="py-2 text-bold">{t("field.origin")}</div>
                </Col>
                <Col md={8} sm={12}>
                  {formik.values?.flight_type === 1 && (
                    <Select2
                      name="airport_id_from"
                      options={airport_list_indo}
                      errors={formik.errors}
                      touched={formik.touched}
                      value={formik.values?.airport_id_from}
                      onInputChange={(keyword) => {
                        debouncedResults(keyword, "domestic");
                      }}
                      onChange={(name, value) => {
                        if (formik.values?.airport_id_to === value) {
                          toastError(`${t("field.cantSameAirport")}!`);
                        } else {
                          const index = dataAirportDomestic
                            ?.map(function (e) {
                              return e.id;
                            })
                            .indexOf(value);
                          formik.setFieldValue("lounge", "");
                          formik.setFieldValue("transportation", "");
                          formik.setFieldValue("car_type", "");
                          formik.setFieldValue("selectedAirportDomestic", {
                            value: value,
                            label: `${dataAirportDomestic[index].code}-${
                              dataAirportDomestic[index].city
                            }-${dataAirportDomestic[index].name} ${
                              dataAirportDomestic[index].uniform === "Indonesia"
                                ? ""
                                : "-" + dataAirportDomestic[index].uniform
                            }`,
                          });
                          formik.setFieldValue(
                            "airport_uniform_from",
                            dataAirportDomestic[index].uniform
                          );
                          formik.setFieldValue(
                            "airport_name_from",
                            dataAirportDomestic[index].code +
                              "-" +
                              dataAirportDomestic[index].city +
                              "-" +
                              dataAirportDomestic[index].name
                          );
                          formik.setFieldValue("airport_id_from", value);
                        }
                      }}
                      onBlur={formik.setFieldTouched}
                    />
                  )}
                  {formik.values?.flight_type !== 1 && (
                    <Select2
                      name="airport_id_from"
                      options={airport_list}
                      errors={formik.errors}
                      touched={formik.touched}
                      value={formik.values?.airport_id_from}
                      onInputChange={(keyword) => {
                        debouncedResults(keyword, "inter");
                      }}
                      onChange={(name, value) => {
                        if (formik.values?.airport_id_to === value) {
                          toastError(`${t("field.cantSameAirport")}!`);
                        } else {
                          const index = dataAirport
                            ?.map(function (e) {
                              return e.id;
                            })
                            .indexOf(value);
                          formik.setFieldValue("lounge", "");
                          formik.setFieldValue("transportation", "");
                          formik.setFieldValue("car_type", "");
                          formik.setFieldValue(
                            "airport_uniform_from",
                            dataAirport[index].uniform
                          );
                          formik.setFieldValue(
                            "airport_name_from",
                            dataAirport[index].code +
                              "-" +
                              dataAirport[index].city +
                              "-" +
                              dataAirport[index].name
                          );
                          formik.setFieldValue("selectedAirport", {
                            value: value,
                            label: `${dataAirport[index].code}-${
                              dataAirport[index].city
                            }-${dataAirport[index].name} ${
                              dataAirport[index].uniform === "Indonesia"
                                ? ""
                                : "-" + dataAirport[index].uniform
                            }`,
                          });
                          formik.setFieldValue("airport_id_from", value);
                        }
                      }}
                      onBlur={formik.setFieldTouched}
                    />
                  )}
                </Col>
              </div>
              <div className="row justify-content-between">
                <Col md={3} sm={12}>
                  <div className="py-2 text-bold">{t("field.destination")}</div>
                </Col>
                <Col md={8} sm={12}>
                  {formik.values?.flight_type === 0 && (
                    <Select2
                      name="airport_id_to"
                      errors={formik.errors}
                      options={airport_list_indo}
                      touched={formik.touched}
                      value={formik.values?.airport_id_to}
                      onInputChange={(keyword) => {
                        debouncedResults(keyword, "domestic");
                      }}
                      onChange={(name, value) => {
                        if (formik.values?.airport_id_from === value) {
                          toastError(`${t("field.cantSameAirport")}!`);
                        } else {
                          const index = dataAirportDomestic
                            ?.map(function (e) {
                              return e.id;
                            })
                            .indexOf(value);
                          formik.setFieldValue("lounge", "");
                          formik.setFieldValue("transportation", "");
                          formik.setFieldValue("car_type", "");
                          formik.setFieldValue("selectedAirportDomestic", {
                            value: value,
                            label: `${dataAirportDomestic[index].code}-${
                              dataAirportDomestic[index].city
                            }-${dataAirportDomestic[index].name} ${
                              dataAirportDomestic[index].uniform === "Indonesia"
                                ? ""
                                : "-" + dataAirportDomestic[index].uniform
                            }`,
                          });
                          formik.setFieldValue(
                            "airport_uniform_to",
                            dataAirportDomestic[index].uniform
                          );
                          formik.setFieldValue(
                            "airport_name_to",
                            dataAirportDomestic[index].code +
                              "-" +
                              dataAirportDomestic[index].city +
                              "-" +
                              dataAirportDomestic[index].name
                          );
                          formik.setFieldValue("airport_id_to", value);
                        }
                      }}
                      onBlur={formik.setFieldTouched}
                    />
                  )}
                  {formik.values?.flight_type !== 0 && (
                    <Select2
                      name="airport_id_to"
                      errors={formik.errors}
                      options={airport_list}
                      touched={formik.touched}
                      value={formik.values?.airport_id_to}
                      onInputChange={(keyword) => {
                        debouncedResults(keyword, "inter");
                      }}
                      onChange={(name, value) => {
                        if (formik.values?.airport_id_from === value) {
                          toastError(`${t("field.cantSameAirport")}!`);
                        } else {
                          const index = dataAirport
                            ?.map(function (e) {
                              return e.id;
                            })
                            .indexOf(value);
                          formik.setFieldValue("lounge", "");
                          formik.setFieldValue("transportation", "");
                          formik.setFieldValue("car_type", "");
                          formik.setFieldValue(
                            "airport_uniform_to",
                            dataAirport[index].uniform
                          );
                          formik.setFieldValue(
                            "airport_name_to",
                            dataAirport[index].code +
                              "-" +
                              dataAirport[index].city +
                              "-" +
                              dataAirport[index].name
                          );
                          formik.setFieldValue("selectedAirport", {
                            value: value,
                            label: `${dataAirport[index].code}-${
                              dataAirport[index].city
                            }-${dataAirport[index].name} ${
                              dataAirport[index].uniform === "Indonesia"
                                ? ""
                                : "-" + dataAirport[index].uniform
                            }`,
                          });
                          formik.setFieldValue("airport_id_to", value);
                        }
                      }}
                      onBlur={formik.setFieldTouched}
                    />
                  )}
                </Col>
              </div>
            </CardBody>
            <hr />
            <CardBody>
              <div className="row">
                <Col md={6}>
                  <Select2
                    title={t("field.airplane")}
                    name="airplane_name"
                    options={airline_list}
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values?.airplane_name}
                    onInputChange={(keyword) => {
                      if (keyword !== "") {
                        setSearchTextAirline(keyword);
                      }
                    }}
                    onChange={(name, value) => {
                      formik.setFieldValue("airplane_name", value);
                      let airplaneCode = airline_list.filter(function (el) {
                        return el.value == value;
                      });
                      formik.setFieldValue(
                        "airplane_code",
                        airplaneCode[0].code
                      );
                      formik.setFieldValue("selectedAirline", {
                        value: value,
                        code: airplaneCode[0].code,
                        label: `${airplaneCode[0].code} - ${value}`,
                      });
                    }}
                    onBlur={formik.setFieldTouched}
                  />
                </Col>
                <Col md={6}>
                  <div className="form-group" style={{ marginBottom: "0" }}>
                    <label
                      className="form-label"
                      style={{ marginBottom: "5px" }}
                    >
                      {t("field.flightNumber")}
                    </label>
                  </div>
                  <InputPrepend
                    name="airplane_number"
                    label={t("field.flightNumber")}
                    title={formik.values.airplane_code}
                    type="text"
                    width="25%"
                    mt="2"
                    touched={formik.touched}
                    {...formik.getFieldProps("airplane_number")}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    title={
                      formik.values?.flight_type === 0
                        ? t("field.arrivalDate")
                        : t("field.departureDate")
                    }
                    name="date"
                    type="date"
                    min={getDate}
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps("date")}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    title={
                      formik.values?.flight_type === 0
                        ? t("field.arrivalTime")
                        : t("field.departureTime")
                    }
                    name="time"
                    min={getDate}
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values.time}
                    onChange={(e) => {
                      let value = e.target.value;
                      formik?.setFieldValue("time", value);
                      // let date = moment(`${getDate}`).format("YYYY-MM-DD");
                      // let time = moment(
                      //   `${getDate}, ${moment().format("HH:mm")}`
                      // ).diff(moment().startOf("day"), "minutes");
                      // let datepick =
                      //   formik.values.date !== null &&
                      //   formik.values.date !== "" &&
                      //   formik.values.date !== undefined
                      //     ? formik.values.date
                      //     : moment().format("YYYY-MM-DD");
                      // let timepick = moment(`${date}, ${value}`).diff(
                      //   moment().startOf("day"),
                      //   "minutes"
                      // );

                      // if (date === datepick) {
                      //   if (time <= timepick) {
                      //     formik.setFieldValue("time", value);
                      //   } else {
                      //     toastError(t("commons.order_message"));
                      //   }
                      // } else {
                      //   formik.setFieldValue("time", value);
                      // }
                    }}
                    type="time"
                  />
                </Col>
              </div>
            </CardBody>
          </Card>
        )}
        {formik.values.flight_type === 2 && (
          <Card rounded>
            <CardHeader title={t("field.flightInformation")} />
            <CardBody>
              <div className="d-flex justify-content-between">
                <div className="text-bold">{t("field.origin")}</div>
                <Col md={8}>
                  <Select2
                    name="airport_id_from"
                    options={airport_list}
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values?.airport_id_from}
                    onInputChange={(keyword) => {
                      debouncedResults(keyword, "inter");
                    }}
                    onChange={(name, value) => {
                      if (
                        formik.values?.airport_id_to === value ||
                        formik.values?.airport_id_transfer === value
                      ) {
                        toastError(`${t("field.cantSameAirport")}!`);
                      } else {
                        const index = dataAirport
                          ?.map(function (e) {
                            return e.id;
                          })
                          .indexOf(value);
                        formik.setFieldValue(
                          "airport_name_from",
                          dataAirport[index].code +
                            "-" +
                            dataAirport[index].city +
                            "-" +
                            dataAirport[index].name
                        );
                        formik.setFieldValue(
                          "airport_uniform_from",
                          dataAirport[index].uniform
                        );
                        formik.setFieldValue("selectedAirport", {
                          value: value,
                          label: `${dataAirport[index].code}-${
                            dataAirport[index].city
                          }-${dataAirport[index].name} ${
                            dataAirport[index].uniform === "Indonesia"
                              ? ""
                              : "-" + dataAirport[index].uniform
                          }`,
                        });
                        formik.setFieldValue("airport_id_from", value);
                      }
                    }}
                    onBlur={formik.setFieldTouched}
                  />
                </Col>
              </div>
              <div className="d-flex justify-content-between">
                <div className="text-bold">Transfer</div>
                <Col md={8}>
                  <Select2
                    name="airport_id_transfer"
                    errors={formik.errors}
                    options={airport_list_indo}
                    touched={formik.touched}
                    value={formik.values?.airport_id_transfer}
                    onInputChange={(keyword) => {
                      debouncedResults(keyword, "domestic");
                    }}
                    onChange={(name, value) => {
                      if (
                        formik.values.airport_id_to === value ||
                        formik.values.airport_id_from === value
                      ) {
                        toastError(`${t("field.cantSameAirport")}!`);
                      } else {
                        formik.setFieldValue("lounge", "");
                        formik.setFieldValue("transportation", "");
                        formik.setFieldValue("car_type", "");
                        const index = dataAirportDomestic
                          ?.map(function (e) {
                            return e.id;
                          })
                          .indexOf(value);
                        formik.setFieldValue(
                          "airport_name_transfer",
                          dataAirportDomestic[index].code +
                            "-" +
                            dataAirportDomestic[index].city +
                            "-" +
                            dataAirportDomestic[index].name
                        );
                        formik.setFieldValue(
                          "airport_uniform_transfer",
                          dataAirportDomestic[index].uniform
                        );
                        formik.setFieldValue("selectedAirportDomestic", {
                          value: value,
                          label: `${dataAirportDomestic[index].code}-${
                            dataAirportDomestic[index].city
                          }-${dataAirportDomestic[index].name} ${
                            dataAirportDomestic[index].uniform === "Indonesia"
                              ? ""
                              : "-" + dataAirportDomestic[index].uniform
                          }`,
                        });
                        formik.setFieldValue("airport_id_transfer", value);
                      }
                    }}
                    onBlur={formik.setFieldTouched}
                  />
                </Col>
              </div>
            </CardBody>
            <hr />
            <CardBody>
              <div className="row">
                <Col md={6}>
                  <Select2
                    title={t("field.airplane")}
                    name="airplane_name"
                    options={airline_list}
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values?.airplane_name}
                    onInputChange={(keyword) => {
                      if (keyword !== "") {
                        setSearchTextAirline(keyword);
                      }
                    }}
                    onChange={(name, value) => {
                      formik.setFieldValue("airplane_name", value);
                      let airplaneCode = airline_list.filter(function (el) {
                        return el.value == value;
                      });
                      formik.setFieldValue(
                        "airplane_code",
                        airplaneCode[0].code
                      );
                      formik.setFieldValue("selectedAirline", {
                        value: value,
                        code: airplaneCode[0].code,
                        label: `${airplaneCode[0].code} - ${value}`,
                      });
                    }}
                    onBlur={formik.setFieldTouched}
                  />
                </Col>
                <Col md={6}>
                  <div className="form-group" style={{ marginBottom: "0" }}>
                    <label
                      className="form-label"
                      style={{ marginBottom: "5px" }}
                    >
                      {t("field.flightNumber")}
                    </label>
                  </div>
                  <InputPrepend
                    name="airplane_number"
                    label={t("field.flightNumber")}
                    title={formik.values.airplane_code}
                    type="text"
                    width="25%"
                    mt="2"
                    touched={formik.touched}
                    {...formik.getFieldProps("airplane_number")}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    title={t("field.arrivalDate")}
                    name="date"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps("date")}
                    min={getDate}
                    type="date"
                  />
                </Col>
                <Col md={6}>
                  <Input
                    title={t("field.arrivalTime")}
                    name="time"
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values.time}
                    onChange={(e) => {
                      let value = e.target.value;
                      formik.setFieldValue("time", value);
                      // let date = moment(`${getDate}`).format("YYYY-MM-DD");
                      // let time = moment(
                      //   `${getDate}, ${moment().format("HH:mm")}`
                      // ).diff(moment().startOf("day"), "minutes");
                      // let datepick =
                      //   formik.values.date !== null &&
                      //   formik.values.date !== "" &&
                      //   formik.values.date !== undefined
                      //     ? formik.values.date
                      //     : moment().format("YYYY-MM-DD");
                      // let timepick = moment(`${date}, ${value}`).diff(
                      //   moment().startOf("day"),
                      //   "minutes"
                      // );

                      // if (date === datepick) {
                      //   if (time + 720 <= timepick) {
                      //     formik.setFieldValue("time", value);
                      //   } else {
                      //     toastError(t("commons.order_message"));
                      //   }
                      // } else {
                      //   formik.setFieldValue("time", value);
                      // }
                    }}
                    type="time"
                  />
                </Col>
              </div>
            </CardBody>
            <hr />
            <CardHeader title={t("field.arrivalInformation")} />
            <CardBody>
              <div className="d-flex justify-content-between">
                <div className="text-bold">{t("field.destination")}</div>
                <Col md={8}>
                  <Select2
                    name="airport_id_to"
                    options={airport_list_transfer}
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values?.airport_id_to}
                    onInputChange={(keyword) => {
                      debouncedResults(keyword, "transfer");
                    }}
                    onChange={(name, value) => {
                      if (
                        formik.values?.airport_id_from === value ||
                        formik.values?.airport_id_transfer === value
                      ) {
                        toastError(`${t("field.cantSameAirport")} !`);
                      } else {
                        const index = dataAirportTransfer
                          ?.map(function (e) {
                            return e.id;
                          })
                          .indexOf(value);
                        formik.setFieldValue(
                          "airport_name_to",
                          dataAirportTransfer[index].code +
                            "-" +
                            dataAirportTransfer[index].city +
                            "-" +
                            dataAirportTransfer[index].name
                        );
                        formik.setFieldValue(
                          "airport_uniform_to",
                          dataAirportTransfer[index].uniform
                        );
                        formik.setFieldValue("selectedAirportTransfer", {
                          value: value,
                          label: `${dataAirportTransfer[index].code}-${
                            dataAirportTransfer[index].city
                          }-${dataAirportTransfer[index].name} ${
                            dataAirportTransfer[index].uniform === "Indonesia"
                              ? ""
                              : "-" + dataAirportTransfer[index].uniform
                          }`,
                        });
                        formik.setFieldValue("airport_id_to", value);
                      }
                    }}
                    onBlur={formik.setFieldTouched}
                  />
                </Col>
              </div>
            </CardBody>
            <hr />
            <CardBody>
              <div className="row">
                <Col md={6}>
                  <Select2
                    title={t("field.airplane")}
                    name="airplane_name_transfer_to"
                    options={airline_list_transfer}
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values?.airplane_name_transfer_to}
                    onInputChange={(keyword) => {
                      if (keyword !== "") {
                        setSearchTextAirlineTransfer(keyword);
                      }
                    }}
                    onChange={(name, value) => {
                      formik.setFieldValue("airplane_name_transfer_to", value);
                      let airplaneCode = airline_list_transfer.filter(function (
                        el
                      ) {
                        return el.value == value;
                      });
                      formik.setFieldValue(
                        "airplane_code_transfer",
                        airplaneCode[0].code
                      );
                      formik.setFieldValue("selectedAirlineTransfer", {
                        value: value,
                        code: airplaneCode[0].code,
                        label: `${airplaneCode[0].code} - ${value}`,
                      });
                    }}
                    onBlur={formik.setFieldTouched}
                  />
                </Col>
                <Col md={6}>
                  <div className="form-group" style={{ marginBottom: "0" }}>
                    <label
                      className="form-label"
                      style={{ marginBottom: "5px" }}
                    >
                      {t("field.flightNumber")}
                    </label>
                  </div>
                  <InputPrepend
                    name="airplane_number_transfer_to"
                    label={t("field.flightNumber")}
                    title={formik.values.airplane_code_transfer}
                    type="text"
                    width="25%"
                    mt="2"
                    touched={formik.touched}
                    {...formik.getFieldProps("airplane_number_transfer_to")}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    title={t("field.departureDate")}
                    name="date_transfer_to"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps("date_transfer_to")}
                    min={moment(formik.values.date).format("YYYY-MM-DD")}
                    type="date"
                    onChange={(e) => {
                      let value = e.target.value;
                      formik.setFieldValue("date_transfer_to", value);
                    }}
                    disabled={!formik.values.date || !formik.values.time}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    title={t("field.departureTime")}
                    name="time_transfer_to"
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values?.time_transfer_to}
                    onChange={(e) => {
                      let value = e.target.value;
                      formik.setFieldValue("time_transfer_to", value);
                    }}
                    disabled={
                      formik.values.date_transfer_to === null ||
                      formik.values.date_transfer_to === "" ||
                      formik.values.date_transfer_to === undefined
                    }
                    type="time"
                  />
                </Col>
              </div>
            </CardBody>
          </Card>
        )}
        <Card rounded>
          <CardHeader title={t("field.product")} />
          <CardBody>
            {formik?.values?.list_available_services?.map((item, index) => {
              return (
                <React.Fragment key={`service-${index}`}>
                  <div
                    onClick={() => handleProductSelected(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <Card rounded>
                      <CardBody
                        isActive={item?.type === formik?.values.product_type}
                      >
                        <div>
                          <span
                            className={
                              item?.type === formik?.values.product_type
                                ? "card-header"
                                : "card-header-inactive"
                            }
                          >
                            <span
                              style={{
                                borderRadius: "50%",
                                backgroundColor: "#63AE5C",
                                borderColor: "#63AE5C",
                                marginRight: "25px",
                                height: "100%",
                                color: "#FFFF",
                              }}
                              className="material-icons-round"
                            >
                              {item?.type === formik?.values.product_type
                                ? "done"
                                : "info"}
                            </span>
                            <div>
                              {item?.label}
                              <div
                                style={{
                                  fontSize: 12,
                                  display: "block",
                                  lineHeight: "1rem",
                                  paddingTop: 10,
                                }}
                              >
                                {item?.type === 3
                                  ? ""
                                  // ? t("product.detailFTDesc")
                                  : item?.type === 4
                                  ? t("product.detailTransferDesc")
                                  : t("product.detailMnGDesc")}
                              </div>
                            </div>
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </React.Fragment>
              );
            })}
            {(!formik?.values?.list_available_services ||
              formik?.values?.list_available_services?.length < 1) && (
              <Card rounded>
                <CardBody isActive={false}>
                  <span className="card-header-inactive">
                    <span
                      style={{
                        borderRadius: "50%",
                        backgroundColor: "#FFFF",
                        borderColor: "#A5A5C0",
                        marginRight: "25px",
                        height: "100%",
                        color: "#A5A5C0",
                      }}
                      className="material-icons-round"
                    >
                      info
                    </span>
                    {t("field.notAvailable")}
                  </span>
                </CardBody>
              </Card>
            )}
          </CardBody>
        </Card>
        {button()}
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ airport, corporate, airline }) => {
  return { airport, corporate, airline };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(
  connect(mapDispatchToProps, mapStateToProps)(FlightReservation)
);
