import "./card.scss";

const cardHeader = ({
  title,
  position = 'left'
}) => {

  return (
    <div className={`card-header rounded ${position}`}>
      {title}
    </div>
  );

};

export default cardHeader;
