import React, { useEffect, useState } from "react";
import {
  Col,
  Container, 
  Row,
} from 'reactstrap';
import Card from "../../components/commons/cards/card";
import CardCollapse from "../../components/commons/cards/cardCollapse";
import { withTrans } from "../../i18n/withTrans";
const Index = ({ t }) => {
  const [openPrivacy, setOpenPrivacy] = useState("");
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div>
      <Container fluid>
        <section id="informations">
          <div className="product-bg d-flex">
            <Row className="m-auto justify-content-center">
              <Col md={8}>
                <div className="super-title text-center text-extra-bold">
                  {t("privacyPolicy.headline")}
                </div>
              </Col>
            </Row>
          </div>
          <div className="content">
            <Row className="description mb-4">
              <p>
                {t("privacyPolicy.description")}
              </p>
              <p className="mt-4">
                {t("privacyPolicy.description2")}
              </p>
            </Row>
            <Card
              style={{ 
                marginTop: '3em',
                borderStyle: 'none',
              }}
            >
              <CardCollapse 
                headerTitle={`1. ${t("privacyPolicy.personalData")}`}
                info={t("privacyPolicy.personalDataInfo")}
                list_info={(
                  <>
                    <li>
                      {t("privacyPolicy.personalData1")}
                    </li>
                    <li>
                      {t("privacyPolicy.personalData2")}
                    </li>
                    <li>
                      {t("privacyPolicy.personalData3")}
                    </li>
                  </>
                )}
                onClick={() => {
                  if (openPrivacy === "1") {
                    setOpenPrivacy(null)
                  } else {
                    setOpenPrivacy("1")
                  }
                }}
                opened={openPrivacy === "1"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`2. ${t("privacyPolicy.usedOfPersonalData")}`}
                info={t("privacyPolicy.usedOfPersonalDataInfo")}
                onClick={() => {
                  if (openPrivacy === "2") {
                    setOpenPrivacy(null)
                  } else {
                    setOpenPrivacy("2")
                  }
                }}
                opened={openPrivacy === "2"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`3. ${t("privacyPolicy.correctionDeletion")}`}
                info={t("privacyPolicy.correctionDeletionInfo")}
                onClick={() => {
                  if (openPrivacy === "3") {
                    setOpenPrivacy(null)
                  } else {
                    setOpenPrivacy("3")
                  }
                }}
                opened={openPrivacy === "3"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`4. ${t("privacyPolicy.storage")}`}
                info={t("privacyPolicy.storageInfo")}
                onClick={() => {
                  if (openPrivacy === "4") {
                    setOpenPrivacy(null)
                  } else {
                    setOpenPrivacy("4")
                  }
                }}
                opened={openPrivacy === "4"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`5. ${t("privacyPolicy.personalDataSecurity")}`}
                list_info={(
                  <>
                    <li>
                      {t("privacyPolicy.personalDataSecurityInfo1")}
                    </li>
                    <li>
                      {t("privacyPolicy.personalDataSecurityInfo2")}
                    </li>
                  </>
                )}
                onClick={() => {
                  if (openPrivacy === "5") {
                    setOpenPrivacy(null)
                  } else {
                    setOpenPrivacy("5")
                  }
                }}
                opened={openPrivacy === "5"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`6. ${t("privacyPolicy.changesPrivacyPolicy")}`}
                info={t("privacyPolicy.changesPrivacyPolicyInfo")}
                onClick={() => {
                  if (openPrivacy === "6") {
                    setOpenPrivacy(null)
                  } else {
                    setOpenPrivacy("6")
                  }
                }}
                opened={openPrivacy === "6"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`7. ${t("privacyPolicy.agreement")}`}
                info={t("privacyPolicy.agreementInfo")}
                onClick={() => {
                  if (openPrivacy === "7") {
                    setOpenPrivacy(null)
                  } else {
                    setOpenPrivacy("7")
                  }
                }}
                opened={openPrivacy === "7"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`8. ${t("footer.contactUs")}`}
                info={(
                  <diiv>
                    {t("privacyPolicy.contactUsInfo")}
                    <div className="row mt-3">
                      <div className="list-main-navbar col-md-6 col-sm-12 mb-5 line-2">
                        <div className="medium-title text-bold">{t("support.address")}</div>
                        <hr style={{ marginLeft: 0 }} />
                        <div>
                          Gedung Gapura
                          <br></br>
                          Jl. Merpati III, Blok B-12, Kav. 7.
                          <br></br>
                          Kota Baru, Kemayoran, Jakarta Pusat
                        </div>
                      </div>
                      <div className="list-main-navbar col-md-6 line-2">
                        <div className="medium-title text-bold">{t("support.phone")}</div>
                        <hr style={{ marginLeft: 0 }} />
                        <div className="row">
                          <div className="col-md-6">
                            <strong>{t("support.phone")}</strong>
                          </div>
                          <div className="col-md-6">
                            <div>+62-21-654-5410/11</div>
                          </div>
                          <div className="col-md-6">
                            <strong>Fax</strong>
                          </div>
                          <div className="col-md-6">
                            <div>+62-21-654-5408</div>
                          </div>
                          <div className="col-md-6">
                            <strong>Email</strong>
                          </div>
                          <div className="col-md-6">
                            <div>joumpa@gapura.id</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </diiv>
                )}
                onClick={() => {
                  if (openPrivacy === "8") {
                    setOpenPrivacy(null)
                  } else {
                    setOpenPrivacy("8")
                  }
                }}
                opened={openPrivacy === "8"}
              />
              <hr />
            </Card>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default withTrans(Index);
