import React, { useEffect, useState } from "react";
import {
  Col,
  Container, 
  Row,
} from 'reactstrap';
import Card from "../../components/commons/cards/card";
import CardCollapse from "../../components/commons/cards/cardCollapse";
import { withTrans } from "../../i18n/withTrans";
const Index = ({ t }) => {
  const [openPersonalData, setOpenPersonalData] = useState("1");
  const [openUseOfPersonalData, setOpenUseOfPersonalData] = useState("1");
  const [openCorrection, setOpenCorrection] = useState("1");
  const [openStoragePersonalData, setOpenStoragePersonalData] = useState("1");
  const [openSecurity, setOpenSecurity] = useState("1");
  const [openChangesPrivacyPolicy, setOpenChangesPrivacyPolicy] = useState("1");
  const [openAgreement, setOpenAgreement] = useState("1");
  const [openContact, setOpenContact] = useState("1");

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div>
      <Container fluid>
        <section id="informations">
          <div className="product-bg d-flex">
            <Row className="m-auto justify-content-center">
              <Col md={8}>
                <div className="super-title text-center text-extra-bold">
                  {t("privacyPolicy.headline")}
                </div>
              </Col>
            </Row>
          </div>
          <div className="content">
            <Row className="description mb-4">
              <p>
                {t("privacyPolicy.descriptionForAsst")}
              </p>
              <p className="mt-4">
                {t("privacyPolicy.description2")}
              </p>
            </Row>
            <Card
              style={{ 
                marginTop: '3em',
                borderStyle: 'none',
              }}
            >
              <CardCollapse 
                headerTitle={`1. ${t("privacyPolicy.personalData")}`}
                info={t("privacyPolicy.personalDataInfo")}
                list_info={(
                  <>
                    <li>
                      {t("privacyPolicy.personalData1ForAsst")}
                    </li>
                    <li>
                      {t("privacyPolicy.personalData3")}
                    </li>
                  </>
                )}
                onClick={() => {
                  if (openPersonalData === "1") {
                    setOpenPersonalData(null)
                  } else {
                    setOpenPersonalData("1")
                  }
                }}
                opened={openPersonalData === "1"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`2. ${t("privacyPolicy.usedOfPersonalData")}`}
                info={t("privacyPolicy.usedOfPersonalDataInfo")}
                onClick={() => {
                  if (openUseOfPersonalData === "1") {
                    setOpenUseOfPersonalData(null)
                  } else {
                    setOpenUseOfPersonalData("1")
                  }
                }}
                opened={openUseOfPersonalData === "1"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`3. ${t("privacyPolicy.correctionDeletion")}`}
                info={t("privacyPolicy.correctionDeletionInfo")}
                onClick={() => {
                  if (openCorrection === "1") {
                    setOpenCorrection(null)
                  } else {
                    setOpenCorrection("1")
                  }
                }}
                opened={openCorrection === "1"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`4. ${t("privacyPolicy.storage")}`}
                info={t("privacyPolicy.storageInfo")}
                onClick={() => {
                  if (openStoragePersonalData === "1") {
                    setOpenStoragePersonalData(null)
                  } else {
                    setOpenStoragePersonalData("1")
                  }
                }}
                opened={openStoragePersonalData === "1"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`5. ${t("privacyPolicy.personalDataSecurity")}`}
                list_info={(
                  <>
                    <li>
                      {t("privacyPolicy.personalDataSecurityInfo1")}
                    </li>
                    <li>
                      {t("privacyPolicy.personalDataSecurityInfo2")}
                    </li>
                  </>
                )}
                onClick={() => {
                  if (openSecurity === "1") {
                    setOpenSecurity(null)
                  } else {
                    setOpenSecurity("1")
                  }
                }}
                opened={openSecurity === "1"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`6. ${t("privacyPolicy.changesPrivacyPolicy")}`}
                info={t("privacyPolicy.changesPrivacyPolicyInfo")}
                onClick={() => {
                  if (openChangesPrivacyPolicy === "1") {
                    setOpenChangesPrivacyPolicy(null)
                  } else {
                    setOpenChangesPrivacyPolicy("1")
                  }
                }}
                opened={openChangesPrivacyPolicy === "1"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`7. ${t("privacyPolicy.agreement")}`}
                info={t("privacyPolicy.agreementInfo")}
                onClick={() => {
                  if (openAgreement === "1") {
                    setOpenAgreement(null)
                  } else {
                    setOpenAgreement("1")
                  }
                }}
                opened={openAgreement === "1"}
              />
              <hr />
              <CardCollapse 
                headerTitle={`8. ${t("footer.contactUs")}`}
                info={(
                  <diiv>
                    {t("privacyPolicy.contactUsInfo")}
                    <div className="row mt-3">
                      <div className="list-main-navbar col-md-6 col-sm-12 mb-5 line-2">
                        <div className="medium-title text-bold">{t("support.address")}</div>
                        <hr style={{ marginLeft: 0 }} />
                        <div>
                          Gedung Gapura
                          <br></br>
                          Jl. Merpati III, Blok B-12, Kav. 7.
                          <br></br>
                          Kota Baru, Kemayoran, Jakarta Pusat
                        </div>
                      </div>
                      <div className="list-main-navbar col-md-6 line-2">
                        <div className="medium-title text-bold">{t("support.phone")}</div>
                        <hr style={{ marginLeft: 0 }} />
                        <div className="row">
                          <div className="col-md-6">
                            <strong>{t("support.phone")}</strong>
                          </div>
                          <div className="col-md-6">
                            <div>+62-21-654-5410/11</div>
                          </div>
                          <div className="col-md-6">
                            <strong>Fax</strong>
                          </div>
                          <div className="col-md-6">
                            <div>+62-21-654-5408</div>
                          </div>
                          <div className="col-md-6">
                            <strong>Email</strong>
                          </div>
                          <div className="col-md-6">
                            <div>joumpa@gapura.id</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </diiv>
                )}
                onClick={() => {
                  if (openContact === "1") {
                    setOpenContact(null)
                  } else {
                    setOpenContact("1")
                  }
                }}
                opened={openContact === "1"}
              />
              <hr />
            </Card>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default withTrans(Index);
