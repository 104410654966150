import React, { Component } from "react";
import Select from "react-select";

export default class Select2 extends Component {
  handleChange = (item) => {
    // this is going to call setFieldValue and manually update values.topcis
    if (this.props.onChange)
      this.props.onChange(this.props?.name, item?.value ?? "");
  };

  handleChange = item => {
    // this is going to call setFieldValue and manually update values.topcis
    this.props.onChange(this.props?.name, item?.value ?? "");
  }

  render() {
    let isValid = true;
    if (this?.props?.errors && this.props?.name) {
      isValid = !(
        this.props.errors[this.props?.name] && this.props.touched[this.props?.name]
      )
    }

    const customStyles = {
      control: (base, state) => ({
        ...base,
        // state.isFocused can display different borderColor if you need it
        borderColor: state.isFocused ? "#ddd" : !isValid ? "red" : "#ddd",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused ? "#ddd" : !isValid ? "red" : "#ddd",
        },
        cursor: 'pointer',
        padding: this?.props?.stylePadding ?? '5px',
        borderRadius: '6px',
        height: this?.props?.styleHeight ?? 'auto'
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: 'none',
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#000',
        cursor: 'pointer',
        'svg': {
          width: '24px',
          height: '22px',
          '&hover': {
            color: '#0D51FF',
          },
        },
      }),
      option: (options, state) => ({
        ...options,
        cursor: "pointer",
        textAlign: "left",
        paddingLeft: "15px"
      })
    };

    return (
      <div className="form-group">
        {this.props?.title && (
          <label className="form-label">{this.props?.title}</label>
        )}
        <Select
          {...this.props}
          isSearchable={this.props.isSearchable}
          isDisabled={this.props.disabled}
          name={this.props?.name}
          styles={customStyles}
          value={
            this.props.options?.filter((obj) => {
              return obj?.value === this.props.value;
            })[0] ?? { label: "Select...", key: "" }
          }
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          options={this.props.options}
          placeholder={this.props.placeholder}
          isClearable={this.props.clearable ?? false}
          classNamePrefix="select"
          className={"basic-single " + this.props.className}
        ></Select>
        {/* <ErrorMessage name={this.props?.name} component="div" className="invalid-feedback" style={{ display: (isValid ? 'none' : 'block') }} /> */}
        {this.props.errors && (
          <div
            className="invalid-feedback"
            style={{ display: isValid ? "none" : "block" }}
            >
            {this.props.errors[this.props?.name]}
          </div>
        )}
      </div>
    );
  }
}
