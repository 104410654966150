import {
    GET_ASSISTANT_PENDING,
    GET_ASSISTANT_SUCCESS,
    GET_ASSISTANT_ERROR,
    POST_ASSISTANT_PENDING,
    POST_ASSISTANT_SUCCESS,
    POST_ASSISTANT_ERROR,
    PUT_ASSISTANT_PENDING,
    PUT_ASSISTANT_SUCCESS,
    PUT_ASSISTANT_ERROR,
    DELETE_ASSISTANT_PENDING,
    DELETE_ASSISTANT_SUCCESS,
    DELETE_ASSISTANT_ERROR,
} from "../../../actions/master_data/assistant";

const initialState = {
    pending: false,
    error: null,
    data_list: null,
    pending_post: false,
    data_post: null,
    pending_put: false,
    data_put: false,
    pending_delete: false,
    data_delete: null
};

const assistant = (state = initialState, action) => {
    switch (action.type) {
        case GET_ASSISTANT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case GET_ASSISTANT_SUCCESS:
            return {
                ...state,
                pending: false,
                data_list: action.data,
            };
        case GET_ASSISTANT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case POST_ASSISTANT_PENDING:
            return {
                ...state,
                pending_post: true,
            };
        case POST_ASSISTANT_SUCCESS:
            return {
                ...state,
                pending_post: false,
                data_post: action.data,
            };
        case POST_ASSISTANT_ERROR:
            return {
                ...state,
                pending_post: false,
                error: action.error,
            };
        case PUT_ASSISTANT_PENDING:
            return {
                ...state,
                pending_put: true,
            };
        case PUT_ASSISTANT_SUCCESS:
            return {
                ...state,
                pending_put: false,
                data_put: action.data,
            };
        case PUT_ASSISTANT_ERROR:
            return {
                ...state,
                pending_put: false,
                error: action.error,
            };
        case DELETE_ASSISTANT_PENDING:
            return {
                ...state,
                pending_delete: true,
            };
        case DELETE_ASSISTANT_SUCCESS:
            return {
                ...state,
                pending_delete: false,
                data_delete: action.data,
            };
        case DELETE_ASSISTANT_ERROR:
            return {
                ...state,
                pending_delete: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default assistant;
