
import { useState, useRef, useEffect } from "react"

function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] = useState(
        initialIsVisible
    );
    const ref = useRef(null);

    const handleHideDropdown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            setIsComponentVisible(false);
        }
    };

    const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleHideDropdown, true);
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("keydown", handleHideDropdown, true);
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
}

const Index = ({ title, optClass, titleClass, rightClass, editableTitle, children, elipsis, titleChildren, onChangeEdit, buttonBack }) => {

    const [value, setValue] = useState(title ?? '');
    const {
        ref,
        isComponentVisible
    } = useComponentVisible(false);

    const handleChangeTitle = (event) => {
        setValue(event.target.value ?? '')
    }

    useEffect(() => {
        if (isComponentVisible === false) {
            if (onChangeEdit) {
                onChangeEdit(value)
            }
        }
    }, [isComponentVisible]);


    return (
        <div className={`header-nav header-title pr-0 ${optClass}`}>
            <div className={`header-title-container ${titleClass}`}>
                <div className={`menu-title text-extra-bold ${elipsis ? 'menu-title-elipsis' : ''}`} >
                    {isComponentVisible ? (
                        <input className="input-title" ref={ref} value={value} onChange={(event) => handleChangeTitle(event)} />
                    ) : (

                        <span>{buttonBack}{title}</span>
                    )}
                    {!isComponentVisible && editableTitle && (
                        <span className='material-icons-round right-icon' style={{ cursor: 'pointer' }}>edit</span>
                    )}
                </div>
                {titleChildren}
            </div>
            <div className={`menu-right ${rightClass}`}>
                <div className="active-pointer-evt menu-item">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Index;
