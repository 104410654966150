import { React, useState, useEffect } from 'react'
import { Col, Container, Row } from "reactstrap";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { withTrans } from "../../i18n/withTrans";
import { history } from "../../utils/History";
import { handleDetailServices } from "../../utils/Constants";

import fastTrack from "../../assets/images/icon/fast-track-img.svg"
import meetGreet from "../../assets/images/icon/meet-and-greet-img.svg"
import transfer from "../../assets/images/icon/transfer-img.svg"

const QontoConnector = withStyles({
  root: {
    paddingTop: '10px',
    paddingBottom: '10px',
    marginLeft: '3px'
  },
  active: {
    '& $line': {
      borderColor: 'rgba(98, 174, 92, 0.16)',
    },
  },
  completed: {
    '& $line': {
      borderColor: 'rgba(98, 174, 92, 0.16)',
    },
  },
  line: {
    width: '3px',
    borderColor: 'rgba(98, 174, 92, 0.16)',
    borderLeftWidth: 2,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#62AE5C',
  },
  active: {
    color: '#62AE5C',
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: '1px',
    boxShadow: '0px 0px 0px 8px rgba(98, 174, 92, 0.16)',
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#62AE5C',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      <div className={classes.circle} />
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const DetailProduct = ({ t, match }) => {
  document.documentElement.scrollTop = 0;
  const [width, setWidth] = useState(window.innerWidth);
  const [listContent, setListContent] = useState(
    match?.params?.service === 'fast-track'
      ? handleDetailServices("Fast-Track", t)
      : match?.params?.service === 'transfer'
      ? handleDetailServices("Transfer", t)
      : handleDetailServices("Meet-And-Greet", t)
  );

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 660;
  const isTabled = width <= 767 && width > 659;
  return (
    <>
      <Container>
        <section id="product-detail">
          <Row className="product-content align-items-center">
            <div className="side-menu-back-icon mr-4">
              <span
                className="material-icons clickable"
                onClick={() => history.push({ pathname: "/products" })}
              >
                arrow_back
              </span>
            </div>
            <h1 className="text-medium" style={{ color: '#0A0A0A' }}>
              {match?.params?.service === 'fast-track'
                ? 'Fast Track Service'
                : match?.params?.service === 'transfer'
                ? 'Transfer Service'
                : 'Meet and Greet Service'}
            </h1>
          </Row>
          <Row className="product-content align-items-center my-4">
            <div style={{ width: '100%', maxHeight: '644px'}}>
              <img
                className="img-fluid w-100" 
                style={{ maxHeight: '644px' }}
                src={
                  match?.params?.service === 'fast-track'
                    ? fastTrack
                    :  match?.params?.service === 'transfer'
                    ? transfer
                    : meetGreet
                } 
              />
            </div>
          </Row>
          <Row className="product-content align-items-center my-5">
            <p className="desc-text">
              {match?.params?.service === 'fast-track'
                ? t("product.detailFTDesc")
                : match?.params?.service === 'transfer'
                ? t("product.detailTransferDesc")
                : t("product.detailMnGDesc")}
            </p>
          </Row>
          {listContent?.length && listContent?.map((item) => (
            <div className="my-4">
              <p className="title-list-content text-bold">
                {item?.title_type}
              </p>
              <div>
                <Stepper activeStep={0} orientation="vertical" connector={<QontoConnector />}>
                  {item?.list_step?.map((step, index) => (
                    <Step key={index} active={true}>
                      <StepLabel StepIconComponent={QontoStepIcon}>
                        <p className="list-text ml-3">
                          {step}
                        </p>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </div>
          ))}
        </section>
      </Container>
    </>
  );
};

export default withTrans(DetailProduct);
