import Navbar from "./navbar";
import Footer from "./footer";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';


const Index = ({ children }) => {

  return (
    <>
      <Navbar />
      {/* Child menu */}
      <div className={`main-content`}>
        {children}
      </div>
      <a
        href="https://api.whatsapp.com/send?phone=+628118566009&text=Hi%20Admin%20JOUMPA"
        target="_blank"
        className="whatsapp-btn-float"
      >
        <WhatsAppIcon style={{ fontSize: "35px" }} />
        <span className="hover-icon" style={{ marginLeft: "8px", fontSize: "18px" }}>
          Message Us
        </span>
      </a>
      <Footer />
    </>
  );
};

export default Index;
