import {
  GET_CITY_PENDING,
  GET_CITY_SUCCESS,
  GET_CITY_ERROR,
} from "../../../actions/master_data/city";

const initialState = {
  pending: false,
  error: null,
  data: null,
};

const city = (state = initialState, action) => {
  switch (action.type) {
    case GET_CITY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_CITY_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_CITY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default city;
