import { history } from "../../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
import { toastSuccess, toastError } from "../../../../components/commons/toast";

import API from "../../API";
import { handleResponse } from '../../HandleRespone';

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_ROLE_PENDING = "GET_ROLE_PENDING";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_ERROR = "GET_ROLE_ERROR";
export const POST_ROLE_PENDING = "POST_ROLE_PENDING";
export const POST_ROLE_SUCCESS = "POST_ROLE_SUCCESS";
export const POST_ROLE_ERROR = "POST_ROLE_ERROR";
export const PUT_ROLE_PENDING = "PUT_ROLE_PENDING";
export const PUT_ROLE_SUCCESS = "PUT_ROLE_SUCCESS";
export const PUT_ROLE_ERROR = "PUT_ROLE_ERROR";
export const DELETE_ROLE_PENDING = "DELETE_ROLE_PENDING";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_ERROR = "DELETE_ROLE_ERROR";

// URL: URL_{URL}
const ROLE_URL = `v1/master_data/role`;

const get = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(GET_ROLE_PENDING));
    API.get(ROLE_URL, { params: param })
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_ROLE_SUCCESS, res));
            if (resolve) {
                const records_total = res.data.total;
                let data = res.data.data.map((item, i) => ({
                    ...item,
                    no: i + 1 + (param?.page - 1) * param?.length,
                }));
                resolve({
                    data: data,
                    page: param?.page - 1,
                    totalCount: records_total,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_ROLE_ERROR));
            let not_logout = handleResponse(err?.response);
            if (not_logout) {
                toastError(err?.response?.data?.message);
            }
        });
};

const post = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(POST_ROLE_PENDING));
    API.post(ROLE_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(POST_ROLE_SUCCESS, res));
            toastSuccess("Berhasil Tambah Data Bandara");
            if (resolve) {
                resolve({
                    data: res.data.data,
                    message: res.data.message,
                    status: res.data.status,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(POST_ROLE_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const put = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(PUT_ROLE_PENDING));
    API.put(ROLE_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(PUT_ROLE_SUCCESS, res));
            toastSuccess("Berhasil Ubah Data Bandara");
            if (resolve) {
                resolve({
                    data: res.data.data,
                    message: res.data.message,
                    status: res.data.status,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(PUT_ROLE_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const deleted = (param, callback, name) => (dispatch) => {
    dispatch(actionPending(DELETE_ROLE_PENDING));
    API.delete(ROLE_URL + `?id=${param}`)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(DELETE_ROLE_SUCCESS, res));
            toastSuccess(`Berhasil Hapus Data Bandara ${name}`);
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(DELETE_ROLE_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const role = {
    get,
    post,
    put,
    deleted,
};
export default role;
