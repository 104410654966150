import {
  Row,
  Collapse,
} from 'reactstrap';
import CardBody from "./cardBody";

const cardCollapse = ({ 
  headerTitle,
  onClick,
  opened,
  info,
  list_info,
 }) => {
  return (
    <div>
      <Row className="align-items-center justify-content-between flex-nowrap my-3">
        <div className="medium-title text-extra-bold">
          {headerTitle}
        </div>
        <div
          className="clickable"
          onClick={onClick}
        >
          <span className="material-icons-outlined icon ">
            {opened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          </span>
        </div>
      </Row>
      <Collapse isOpen={opened}>
        <CardBody style={{ paddingTop: '10px', paddingLeft: '10px' }}>
          <div className="description">
            {info && (
              <div>
                {info}
              </div>
            )}
            {list_info && (
              <ol type="a">
                {list_info}
              </ol>
            )}
          </div>
        </CardBody>
      </Collapse>
    </div>
  )
}

export default cardCollapse;