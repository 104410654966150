import { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Container, Col } from "reactstrap";
import { withTrans } from "../../../i18n/withTrans";
import { toastError } from "../../../components/commons/toast";
import * as Yup from "yup";
import moment from "moment";

import Loader from "../../../components/commons/Loader";
import ButtonModal from "../../../components/commons/modal/ButtonModal";
import SideMenu from "../../../components/commons/menu/SideMenu";
import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import ConfirmationModal from "../../../components/commons/modal/ConfirmModal";
import Checkout from "./Checkout";
import FlightReservation from "./FlightReservation";
import PassengerReservation from "./PassengerReservation";
import AdtServiceReservation from "./AdtServiceReservation";
import PassengerModal from "./PassengerModal";
import Payment from "./Payment";
import { Formik, Form } from "formik";
import { history } from "../../../utils/History";
import { countries } from "../../../utils/Constants";

//Service
import Booking from "../../../store/actions/booking";
import CountriesService from "../../../store/actions/master_data/countries";

const Index = ({ pending, t, error_message }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();

  const getDate = moment().format("YYYY-MM-DD");
  // const [show, setShow] = useState("payment");
  const [show, setShow] = useState("flight-reservation");
  const [next, setNext] = useState("passenger-reservation");
  const [back, setBack] = useState(null);
  const [sequence, setSequence] = useState(1);
  const [checkFlight, setCheckedFlight] = useState(false);
  const [checkPassenger, setCheckedPassenger] = useState(false);
  const [checkAdtService, setCheckedAdtService] = useState(false);
  const [checkCheckout, setCheckedCheckout] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [indexPassenger, setIndexPassenger] = useState(null);
  const [modalPass, setModalPass] = useState(false);
  const [midToken, setMidToken] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [dataCountries, setDataCountries] = useState(
    countries?.map((item) => ({
      label: item.name,
      value: item.name,
      countryCode: item.code?.toLowerCase(),
    }))
  );

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);
  
  // useEffect(() => {
  //   new Promise((resolve) => {
  //     CountriesService.get(resolve);
  //   }).then((res) => {
  //     if(res?.data) {
  //       const countries = res?.data?.map((item) => ({
  //         label: (
  //           <div className="d-flex align-items-center">
  //             <img
  //               className="mr-3 img-fluid" 
  //               style={{ height: 'auto', width: '20px' }} 
  //               src={
  //                 item?.iso2 === "AF"
  //                   ? 'https://img.mobiscroll.com/demos/flags/' + item.iso2 + '.png'
  //                   : item?.flag
  //               }
  //               alt="Flag"
  //             />
  //             {item.name}
  //           </div>
  //         ),
  //         value: item.name,
  //         countryCode: item.iso2?.toLowerCase(),
  //       }));
  //       setDataCountries(countries);
  //     }
  //   });
  // }, []);

  useEffect(() => {
    if (error_message) {
      setLoadingSubmit(false);
    }
  }, [error_message]);

  const showCancelModal = () => {
    setCancelModal(!cancelModal);
  };
  const handleCancel = () => {
    history.push("/");
  };

  const showModalPass = () => {
    setModalPass(false);
  };

  const handleChangeForm = (tab) => {
    document.documentElement.scrollTop = 0;
    switch (tab) {
      case "flight-reservation":
        setShow("flight-reservation");
        setBack(null);
        setNext("passenger-reservation");
        setSequence(1);
        break;
      case "passenger-reservation":
        setShow("passenger-reservation");
        setBack("flight-reservation");
        setNext("adt-service-reservation");
        setSequence(2);
        setCheckedFlight(true);
        break;
      case "adt-service-reservation":
        setShow("adt-service-reservation");
        setBack("passenger-reservation");
        setNext("checkout");
        setSequence(3);
        setCheckedFlight(true);
        setCheckedPassenger(true);
        break;
      case "checkout":
        setShow("checkout");
        setBack("adt-service-reservation");
        setNext(null);
        setSequence(4);
        setCheckedFlight(true);
        setCheckedPassenger(true);
        setCheckedAdtService(true);
        break;
      // case "payment":
      //     setShow("payment")
      //     setBack("checkout")
      //     setNext(null)
      //     setSequence(4)
      //     setCheckedFlight(true)
      //     setCheckedPassenger(true)
      //     setCheckedAdtService(true)
      //     setCheckedCheckout(true)
      //     break;
    }
  };

  const findAvailabilityPrice = (tab) => {
    let values = formikRef?.current?.values;
    let airport_selected =
      values.flight_type === 0
        ?  values.airport_id_to
        :  values.flight_type === 1
        ?  values.airport_id_from
        :  values.airport_id_transfer;
    const adult_total =
      values?.passenger_list?.filter(
        (item) => item?.title === "Mrs." || item?.title === "Mr." || item?.title === "Ms."
      )?.length;
    const child_total =
      values?.passenger_list?.filter((item) => item?.title === "Child")?.length;
    const infant_total =
      values?.passenger_list?.filter((item) => item?.title === "Infant")?.length;

    new Promise((resolve) => {
      let param = {
        airport_service_id: airport_selected,
        flight_type: values.flight_type_code,
        service_date: values?.date,
        product_name: values?.product_type,
        adult_total: values?.passenger_list?.length ? adult_total : null,
        child_total: values?.passenger_list?.length ? child_total : null,
        infant_total: values?.passenger_list?.length ? infant_total : null,
      };
      if (
        airport_selected &&
        values.flight_type_code &&
        values?.date &&
        values?.product_type
      ) {
        dispatch(Booking.findAvailPrice(param, resolve));
      }
    }).then((res) => {
      if (res) {
        handleChangeForm(tab);
        if (res?.data) {
          const product_list = res?.data?.period_list[0]?.product_list[0];

          formikRef.current.setFieldValue(
            "period_id", 
            res?.data?.period_list[0]?.id
          );
          formikRef.current.setFieldValue(
            "product_list", 
            product_list
          );
          formikRef.current.setFieldValue(
            "product_list_id", 
            product_list?.id
          );
          if (product_list?.group_prices?.length) {
            formikRef.current.setFieldValue(
              "group_price_id", 
              product_list?.group_prices[0]?.id
            );
            formikRef.current.setFieldValue(
              "price_id", 
              null
            );
          } else {
            formikRef.current.setFieldValue(
              "price_id", 
              product_list?.prices?.id
            );
            formikRef.current.setFieldValue(
              "group_price_id", 
              null
            );
          }
        }
      }
    })
  };

  const handleNextClick = (tab) => {
    let values = formikRef?.current?.values;

    formikRef.current.validateForm().then(() => {
      if (tab === "passenger-reservation") {
        if (values.flight_type < 0) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.flightType")}!`
          );
          return;
        }
        if (
          values.product_type === null ||
          values.product_type === 0
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.product")}!`
          );
          return;
        }
        if (
          values.airport_id_from === "" ||
          values.airport_id_from === null
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.origin")}!`
          );
          return;
        }
        if (
          values.airport_id_to === "" ||
          values.airport_id_to === null
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.destination")}!`
          );
          return;
        }
        if (
          values.airplane_name === "" ||
          values.airplane_name === null
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.airplane")}!`
          );
          return;
        }
        if (
          values.airplane_number === "" ||
          values.airplane_number === null
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.flightNumber")}!`
          );
          return;
        }
        if (
          values.date === "" ||
          values.date === null
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("commons.date")}!`
          );
          return;
        } else if (
          values.date !== "" &&
          values.date !== null &&
          values?.date < getDate
        ) {
          toastError(
            `${t("commons.orderDateHandle")} ${moment(getDate).format("DD/MM/YYYY")}!`
          );
          return;
        }
        if (
          values.time === "" ||
          values.time === null
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("commons.time")}!`
          );
          return;
        } else if (
          values.time !== "" &&
          values.time !== null
        ) {
          const dateTomorrow =  moment().add(1, "days").format("YYYY-MM-DD");
          const tomorrowTimeDifference = moment(
            `${dateTomorrow}, ${moment().format("HH:mm")}`
          ).diff(moment().startOf("day"), "minutes");
          const timepickDifference = moment(
            `${dateTomorrow}, ${values.time}`
          ).diff(moment().startOf("day"), "minutes");

          if (dateTomorrow === values.date) {
            if (tomorrowTimeDifference >= timepickDifference) {
              toastError(t("commons.order_message"));
              return;
            }
          }
        }

        if (values.flight_type === 2) {
          let time = moment(
            `${values?.date}, ${values?.time}`, 
            "YYYY-MM-DD HH:mm"
          )?.diff(moment().startOf("day"), "minutes");

          let timepick = moment(
            `${values?.date_transfer_to}, ${values?.time_transfer_to}`,
            "YYYY-MM-DD HH:mm"
          )?.diff(moment().startOf("day"), "minutes");

          if (time > timepick) {
            toastError(t("commons.transfer_message"));
            return;
          }

          if (
            values.airport_id_transfer === "" ||
            values.airport_id_transfer === null
          ) {
            toastError(
              `${t("commons.empty_data_message")} Transfer!`
            );
            return;
          }
          if (values.airplane_name_transfer_to === "") {
            toastError(
              `${t("commons.empty_data_message")} ${t("field.airplane")} !`
            );
            return;
          }
          if (values.airplane_number_transfer_to === "") {
            toastError(
              `${t("commons.empty_data_message")} ${t(
                "field.flightNumber"
              )} !`
            );
            return;
          }
          if (values.date_transfer_to === "") {
            toastError(
              `${t("commons.empty_data_message")} ${t("field.departureDate")} !`
            );
            return;
          } else if (
            values.date_transfer_to !== "" &&
            values.date_transfer_to !== null &&
            values?.date_transfer_to < getDate
          ) {
            toastError(
              `${t("commons.orderDateHandle")} ${moment(getDate).format("DD/MM/YYYY")}!`
            );
            return;
          }
          if (values.time_transfer_to === "") {
            toastError(
              `${t("commons.empty_data_message")} ${t("field.departureTime")} !`
            );
            return;
          }
        }
        if (formikRef.current.isValid) {
          findAvailabilityPrice(tab);
        }
      } else if (tab === "adt-service-reservation") {
        if (values?.passenger_list.length < 1) {
          toastError(
            `${t("commons.empty_data_message")} ${t(
              "field.passenger"
            )}!`
          );
          return;
        } else {
          if (!formikRef.current.isValid) {
            return;
          }
          
          if (values?.bookers_title &&
            values?.bookers_name &&
            values?.bookers_nationality &&
            values?.bookers_email &&
            values?.bookers_phone) {
              if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                  values?.bookers_email
                )
              ) {
                toastError(`${t("commons.wrong_email_format")} ${t("field.booker")}!`);
                return;
              } 
              else {
                findAvailabilityPrice(tab);
              }
          } else {
            toastError(
              `${t("commons.empty_data_message")} ${t(
                "field.bookersInfo"
              )}!`
            );
            return;
          }
        }
      } else if (tab === "checkout") {
        const countAdult = values?.passenger_list?.filter(
          (item) => item?.title === "Mr." || item?.title === "Mrs." || item?.title === "Ms."
        ).length;

        const countChild = values?.passenger_list?.filter(
          (item) => item?.title === "Child"
        ).length;

        const countInfant = values?.passenger_list?.filter(
          (item) => item?.title === "Infant"
        ).length;

        if (values?.lounge) {
          if (values?.lounge_adult_number > countAdult) {
            toastError(`${t("booking.handleAdultLounge")}!`);
            return;
          }
          if (values?.lounge_child_number > countChild) {
            toastError(`${t("booking.handleChildLounge")}!`);
            return;
          }
          if (values?.lounge_infant_number > countInfant) {
            toastError(`${t("booking.handleInfantLounge")}!`);
            return;
          }
        } else {
          formikRef?.current?.setFieldValue("lounge_adult_number", 0);
          formikRef?.current?.setFieldValue("lounge_child_number", 0);
          formikRef?.current?.setFieldValue("lounge_infant_number", 0);
        }

        if (values?.region) {
          if (!(values?.car_type && values?.total_unit)) {
            toastError(
              `${t("commons.empty_data_message")} ${t("field.transportation")}!`
            );
            return;
          }
        } else {
          formikRef?.current?.setFieldValue("total_unit", 0);
        }

        if (formikRef.current.isValid) {
          handleChangeForm(tab);
        }
        formikRef.current.setFieldValue("promo_id", null);
      }
    });
  };

  const handleChangeTab = (tab, number) => {
    if (number > sequence) {
      formikRef.current.validateForm().then(() => {
        if (formikRef.current.isValid) {
          handleChangeForm(tab);
        }
      });
    } else {
      handleChangeForm(tab);
    }
  };

  const handleAddPassenger = (formik, index) => {
    if (index !== null && index !== undefined) {
      setIndexPassenger(index);
    } else {
      let passenger_list = formik?.values?.passenger_list;
      formik?.setFieldValue("passenger_list", [
        ...passenger_list,
        {
          email: "",
          name: "",
          phoneNumber: "",
          title: "",
          passenger_nationality: "",
          passenger_date_of_birth: "",
        },
      ]);
      setIndexPassenger(null);
    }
    setModalPass(true);
  };

  const validation = {
    "flight-reservation": Yup.lazy((value) => {
      if (value.flight_type === -1) {
        return Yup.object().shape({
          airport_id_from: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airport_id_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_name: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_number: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          time: Yup.string().required(t("commons.required")).nullable(),
          date: Yup.string().required(t("commons.required")).nullable(),
        });
      }
      if (value.flight_type === 0) {
        return Yup.object().shape({
          airport_id_from: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airport_id_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_name: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_number: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          time: Yup.string().required(t("commons.required")).nullable(),
          date: Yup.string().required(t("commons.required")).nullable(),
        });
      }
      if (value.flight_type === 1) {
        return Yup.object().shape({
          airport_id_from: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airport_id_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_name: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_number: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          time: Yup.string().required(t("commons.required")).nullable(),
          date: Yup.string().required(t("commons.required")).nullable(),
        });
      }
      if (value.flight_type === 2) {
        return Yup.object().shape({
          airport_id_from: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airport_id_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airport_id_transfer: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_name: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_name_transfer_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_number: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_number_transfer_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          time: Yup.string().required(t("commons.required")).nullable(),
          time_transfer_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          date: Yup.string().required(t("commons.required")).nullable(),
          date_transfer_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
        });
      }
      return Yup.mixed().notRequired();
    }),
    "passenger-reservation": Yup.object().shape({
      passenger_list: Yup.mixed().required(t("commons.required")).nullable(),
      bookers_title: Yup.string().required(t("commons.required")).nullable(),
      bookers_name: Yup.string().required(t("commons.required")).nullable(),
      bookers_nationality: Yup.string().required(t("commons.required")).nullable(),
      bookers_email: Yup.string().required(t("commons.required")).nullable(),
      bookers_phone: Yup.string().required(t("commons.required")).nullable(),
    }),
    "adt-service-reservation": Yup.lazy((value) => {
      if (value.region !== undefined) {
        return Yup.object().shape({
          car_type: Yup.string().required(t("commons.required")).nullable(),
        });
      }
      return Yup.mixed().notRequired();
    }),
  };

  const initialValues = {
    id: "",
    airplane_name: "",
    airplane_name_transfer_to: "",
    airplane_number: "",
    airplane_number_transfer_to: "",
    airport_id_from: "",
    airport_id_to: "",
    airport_id_transfer: null,
    available_service: "",
    customer_id: "",
    car_type: "",
    date: "",
    date_transfer_to: "",
    dataPrice: 0,
    dataPriceMOB: 0,
    dataPriceMOBIndividual: 0,
    dataPriceGroup: [],
    flight_type: -1,
    isOverMOB: false,
    lounge: "",
    lounge_adult_number: 0,
    lounge_child_number: 0,
    lounge_infant_number: 0,
    total_lounge_adult: 0,
    total_lounge_child: 0,
    total_lounge_infant: 0,
    total_lounges: 0,
    orderExtras: {
      lounge: { name: "", price: "", id: "" },
      transport: { name: "", price: "", id: "" },
      car: { name: "", price: "", id: "" },
    },
    order_extra_list: [],
    passenger_list: [],
    passenger_notes: "",
    product_id: null,
    product_type: 0,
    promo_id: null,
    time: "",
    time_transfer_to: "",
    transportation: "",
    region: "",
    type: null,
    groupPrice: 0,
    grandTotalPrice: 0,
    loungePrice: 0,
    transportPrice: 0,
    midtrans_token_id: midToken,
    gross_amount: 0,
    isPriceNotSet: true,
    selectedAirport: {},
    selectedAirportTransfer: {},
    selectedAirportDomestic: {},
    selectedAirline: {},
    selectedAirlineTransfer: {},
    imagePagingInfo: {
      title: t("field.uploadPagingInfo"),
      subtitle: "File",
    },
    agreed_terms: null,
    agreed_purchase: null
  };

  const button = () => {
    return (
      <div style={{ float: "right" }}>
        {next && (
          <ButtonModal
            cancelTitle={back ? t("commons.back") : t("commons.cancel")}
            confirmTitle={t("commons.next")}
            typeConfirm={"Button"}
            toggle={
              back ? () => handleChangeForm(back) : () => showCancelModal()
            }
            confirm={next ? () => handleNextClick(next) : null}
          />
        )}
        {next === null && (
          <ButtonModal
            cancelTitle={back ? t("commons.back") : t("commons.cancel")}
            confirmTitle={"Check Out"}
            typeConfirm={"Submit"}
            toggle={back ? () => handleChangeForm(back) : ""}
            disabledConfirm={
              loadingSubmit 
                ? loadingSubmit
                : formikRef?.current?.values?.agreed_terms != 1 ||
                  formikRef?.current?.values?.agreed_purchase != 1
                  ? true
                  : false
            }
          />
        )}
      </div>
    );
  };

  return (
    <div className="menu-container">
      {pending && <Loader loading={pending} />}
      <div className="grid-header">
        <div className="side-menu-no-border">
          <div className="menu-title text-extra-bold">
            {t("field.newReservation")}
          </div>
          <SideMenu
            title={t("field.flight")}
            toggle={() => handleChangeTab("flight-reservation")}
            checked={sequence > 1}
            onActive={sequence > 1}
            checkbox
          />
          <SideMenu
            title={t("field.passenger")}
            toggle={() => handleChangeTab("passenger-reservation", 2)}
            checked={sequence > 2}
            onActive={sequence > 2}
            checkbox
          />
          <SideMenu
            title={t("field.orderExtras")}
            toggle={() => handleChangeTab("adt-service-reservation", 3)}
            checked={sequence > 3}
            onActive={sequence > 3}
            checkbox
          />
          <SideMenu
            title="Check Out"
            toggle={() => handleChangeTab("checkout", 4)}
            checked={sequence > 4}
            onActive={sequence > 4}
            checkbox
          />
        </div>
        <div className="side-menu-detail">
          <Container className="pb-170">
            <Formik
              innerRef={formikRef}
              enableReinitialize={false}
              initialValues={initialValues}
              // validationSchema={validation[show]}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                if (next === null) {
                  if (values.passenger_list.length < 1) {
                    toastError(
                      `${t("commons.empty_data_message")} ${t(
                        "field.passenger"
                      )}!`
                    );
                    return;
                  }
  
                  if (!(values?.bookers_title &&
                    values?.bookers_name &&
                    values?.bookers_nationality &&
                    values?.bookers_email &&
                    values?.bookers_phone)) {
                      toastError(
                        `${t("commons.empty_data_message")} ${t(
                          "field.bookersInfo"
                        )}!`
                      );
                      return;
                  }

                  let formData = new FormData();
                  values.passenger_list.forEach((key, x) => {
                    formData.append(`passenger_list[${[x]}].email`, key.email ? key.email : "");
                    formData.append(`passenger_list[${[x]}].name`, key.name ? key.name : "");
                    formData.append(`passenger_list[${[x]}].title`, key.title ? key.title : "");
                    formData.append(`passenger_list[${[x]}].passenger_nationality`, key.passenger_nationality ? key.passenger_nationality : "");
                    formData.append(`passenger_list[${[x]}].passenger_date_of_birth`, key.passenger_date_of_birth ? key.passenger_date_of_birth : "");
                    if (
                      key.phoneNumber !== "" &&
                      key.phoneNumber !== undefined &&
                      key.phoneNumber !== null &&
                      !key?.phoneNumber.includes("undefined") &&
                      key?.phoneNumber !== key?.countryCode
                    ) {
                      const phone = key?.phoneNumber?.replace(key?.countryCode, "");
                      formData.append(
                        `passenger_list[${[x]}].phoneNumber`,
                        phone.charAt(0) == 0
                          ? phone?.substring(1)
                          : phone
                      );
                      formData.append(
                        `passenger_list[${[x]}].countryCode`,
                        key.countryCode ? `+${key.countryCode}` : ""
                      );
                      formData.append(
                        `passenger_list[${[x]}].countryId`,
                        key.countryId ? key.countryId : ""
                      );
                    }
                  })
                  formData.append("bookers_title", values?.bookers_title);
                  formData.append("bookers_name", values?.bookers_name);
                  formData.append("bookers_nationality", values?.bookers_nationality);
                  formData.append("bookers_email", values?.bookers_email);
                  if (
                    values?.bookers_phone !== "" &&
                    values?.bookers_phone !== undefined &&
                    values?.bookers_phone !== null &&
                    !values?.bookers_phone.includes("undefined") &&
                    values?.bookers_phone !== values?.bookers_country_code
                  ) {
                    const bookerPhone = 
                      values?.bookers_phone?.replace(values?.bookers_country_code, "");
                    formData.append(
                      'bookers_phone',
                      bookerPhone.charAt(0) == 0
                        ? bookerPhone?.substring(1)
                        : bookerPhone
                    );
                    formData.append(
                      `bookers_country_code`,
                      `+${values?.bookers_country_code}`
                    );
                    formData.append(
                      `bookers_country_id`,
                      values?.bookers_country_id
                    );
                  }
                  if(values?.paging_name !== "" && values?.paging_name !== undefined) {
                    formData.append("paging_name", values?.paging_name);
                  }
                  if(values?.paging_logo !== null && values?.paging_logo !== undefined) {
                    formData.append("paging_logo", values?.paging_logo);
                  }
                  if(values?.show_joumpa_logo == 1) {
                    formData.append("show_joumpa_logo", true);
                  }
                  if(values?.assistant_list !== null && values?.assistant_list !== undefined) {
                    values.assistant_list.forEach((key, x) => {
                      formData.append(`assistant_list[${[x]}].assistant_id`, key.assistant_id ? key.assistant_id : "");
                      formData.append(`assistant_list[${[x]}].is_main`, key.is_main ? key.is_main : "");
                    })
                  }
                  if(values?.customer_id !== null) {
                    formData.append("customer_id", values?.customer_id);
                  }
                  formData.append("type", values?.flight_type + 1);
                  formData.append("airplane_name", values?.airplane_name);
                  formData.append("airplane_name_transfer_to", values?.airplane_name_transfer_to);
                  formData.append("airplane_number", values?.airplane_number);
                  formData.append("airplane_number_transfer_to", values?.airplane_number_transfer_to);
                  formData.append("flight_type", values?.flight_type);
                  formData.append("product_name", values?.product_type);
                  formData.append("product_list_id", values?.product_list_id);
                  formData.append("period_id", values?.period_id);
                  if(values?.price_id !== undefined && values?.price_id !== null) {
                    formData.append("price_id", values?.price_id);
                  }
                  if(values?.group_price_id !== undefined && values?.group_price_id !== null) {
                    formData.append("group_price_id", values?.group_price_id);
                  }
                  if(values?.payment_type !== "" && values?.payment_type !== undefined) {
                    formData.append("payment_type", values?.payment_type);
                  }
                  if(values?.file) {
                    formData.append("file", values?.file);
                  }
                  if(values?.airport_id_from !== "") {
                    formData.append("airport_id_from", values?.airport_id_from);
                  }
                  if(values?.airport_id_to !== "") {
                    formData.append("airport_id_to", values?.airport_id_to);
                  }
                  if(values?.airport_id_transfer !== "" && values?.airport_id_transfer !== null) {
                    formData.append("airport_id_transfer", values?.airport_id_transfer);
                  }
                  formData.append("date", values?.date);
                  if(values?.date_transfer_to !== "") {
                    formData.append("date_transfer_to", values?.date_transfer_to);
                  }
                  formData.append("time", values?.time);
                  if(values?.time_transfer_to !== "") {
                    formData.append("time_transfer_to", values?.time_transfer_to);
                  }
                  if(values?.passenger_notes !== "") {
                    formData.append("passenger_notes", values?.passenger_notes);
                  }

                  if (values?.lounge_adult_number === "") {
                    formData.append("lounge_adult_number", 0);
                  } else {
                    formData.append("lounge_adult_number", values?.lounge_adult_number);
                  }

                  if (values?.lounge_child_number === "") {
                    formData.append("lounge_child_number", 0);
                  } else {
                    formData.append("lounge_child_number", values?.lounge_child_number);
                  }

                  if (values?.lounge_infant_number === "") {
                    formData.append("lounge_infant_number", 0);
                  } else {
                    formData.append("lounge_infant_number", values?.lounge_infant_number);
                  }
                  if (values?.region !== "") {
                    formData.append("region", values?.region);
                  }
                  if (values?.total_unit !== "" && values?.total_unit !== undefined) {
                    formData.append("transport_number", values?.total_unit);
                  }
                  if (values?.orderExtras?.car?.id) {
                    formData.append("transport_price_id", values?.orderExtras?.car?.id);
                  }
                  if (values?.lounge !== "") {
                    formData.append("airport_lounge_id", values?.lounge);
                  }
                  if(values?.promo_id !== null) {
                    formData.append("promo_id", values?.promo_id);
                  }

                  setStatus();
                  new Promise((resolve) => {
                    setLoadingSubmit(true);
                    dispatch(Booking.post(formData, resolve));
                  }).then((response) => {
                    if (response) {
                      window.open(`${response.data.redirect_payment_url}`, `_blank`);
                      history.push("/");
                      setLoadingSubmit(false);
                    };
                  });
                };
              }}
            >
              {(props) => (
                <Form>
                  {show === "flight-reservation" && (
                    <>
                      <HeaderMenu />
                      <FlightReservation formik={props} button={button} />
                    </>
                  )}
                  {show === "passenger-reservation" && (
                    <>
                      <HeaderMenu />
                      <PassengerReservation
                        formik={props}
                        dataCountries={dataCountries}
                        toggle={handleAddPassenger}
                        button={button}
                        add={() => handleAddPassenger(props)}
                      />
                      <PassengerModal
                        show={modalPass}
                        formik={props}
                        dataCountries={dataCountries}
                        idx={indexPassenger}
                        toggle={showModalPass}
                      />
                    </>
                  )}
                  {show === "adt-service-reservation" && (
                    <>
                      <HeaderMenu />
                      <AdtServiceReservation formik={props} button={button} />
                    </>
                  )}
                  {show === "checkout" && (
                    <>
                      <HeaderMenu />
                      <Checkout formik={props} button={button} />
                    </>
                  )}
                  {show === "payment" && (
                    <>
                      <HeaderMenu />
                      <Payment formik={props} button={button} />
                    </>
                  )}
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              show={cancelModal}
              toggle={showCancelModal}
              confirm={handleCancel}
              icon="info"
              title={t("commons.confirmation")}
              isDelete
              confirmTitle={t("commons.yes")}
              cancelTitle={t("commons.cancel")}
            >
              <Col md={8}>
                <div className="normal-title">
                  {t("commons.areYouSureCancel")}{" "}
                  <strong>{t("field.booking")}</strong> ?
                </div>
              </Col>
            </ConfirmationModal>
          </Container>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ booking: { error_message } }) => {
  return { error_message };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(Index)
);
