import Tooltip from "../../tooltip"

const Checkbox = ({
    name,
    checked,
    onChange,
    label,
    value,
    styleInput,
    info,
    disabled,
    style,
    desc
}) => {

    return (
        <>
            <label className="checkbox-container">
                <p className="side-menu-title text-extra-bold" style={{ fontSize: 14, cursor: 'context-menu' }}>{label}</p>
                {desc && (
                    <div className="normal-title">{desc}</div>
                )}
                <input type="checkbox"
                    style={style}
                    name={name}
                    value={value}
                    checked={checked?.includes(String(value))}
                    onChange={onChange}
                    disabled={disabled}
                />
                <span className="checkmark" style={styleInput}></span>
            </label>
            {info && (
                <Tooltip
                    content={info}
                    direction="right"
                >
                    <span className="material-icons-round" style={{ marginLeft: 10 }}>info</span>
                </Tooltip>
            )}
        </>
    )
}

export default Checkbox