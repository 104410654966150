import {
    GET_ROLE_PENDING,
    GET_ROLE_SUCCESS,
    GET_ROLE_ERROR,
    POST_ROLE_PENDING,
    POST_ROLE_SUCCESS,
    POST_ROLE_ERROR,
    PUT_ROLE_PENDING,
    PUT_ROLE_SUCCESS,
    PUT_ROLE_ERROR,
    DELETE_ROLE_PENDING,
    DELETE_ROLE_SUCCESS,
    DELETE_ROLE_ERROR,
} from "../../../actions/master_data/role";

const initialState = {
    pending: false,
    error: null,
    data_role: null,
    pending_post: false,
    data_post: null,
    pending_add: false,
    data_add: null,
    pending_put: false,
    data_put: false,
    pending_delete: false,
    data_delete: null
};

const role = (state = initialState, action) => {
    switch (action.type) {
        case GET_ROLE_PENDING:
            return {
                ...state,
                pending: true,
            };
        case GET_ROLE_SUCCESS:
            return {
                ...state,
                pending: false,
                data_role: action.data,
            };
        case GET_ROLE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case POST_ROLE_PENDING:
            return {
                ...state,
                pending_post: true,
            };
        case POST_ROLE_SUCCESS:
            return {
                ...state,
                pending_post: false,
                data_post: action.data,
            };
        case POST_ROLE_ERROR:
            return {
                ...state,
                pending_post: false,
                error: action.error,
            };
        case PUT_ROLE_PENDING:
            return {
                ...state,
                pending_put: true,
            };
        case PUT_ROLE_SUCCESS:
            return {
                ...state,
                pending_put: false,
                data_put: action.data,
            };
        case PUT_ROLE_ERROR:
            return {
                ...state,
                pending_put: false,
                error: action.error,
            };
        case DELETE_ROLE_PENDING:
            return {
                ...state,
                pending_delete: true,
            };
        case DELETE_ROLE_SUCCESS:
            return {
                ...state,
                pending_delete: false,
                data_delete: action.data,
            };
        case DELETE_ROLE_ERROR:
            return {
                ...state,
                pending_delete: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default role;
