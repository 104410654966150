import React from 'react'
import { Col, Row } from 'reactstrap'
import Card from '../../../components/commons/cards/card'
import CardBody from '../../../components/commons/cards/cardBody'
import payDana from "../../../assets/images/icon/dana.svg"
import payShopay from "../../../assets/images/icon/shopay.svg"
import payJenius from "../../../assets/images/icon/jenius.svg"
import payOvo from "../../../assets/images/icon/ovo.svg"
import payGopay from "../../../assets/images/icon/gopay.svg"

const Payment = () => {
    return (
        <>
            <Card className="rounded" >
                <CardBody style={{ backgroundColor: '#F7F7FA' }}>
                    <Row className='justify-content-between medium-title text-extra-bold'>
                        <Col md={9} style={{ color: '#A5A5C0' }}>
                            {"Total Pembayaran"}
                        </Col>
                        <Col md={3} className='text-right'>
                            {"Rp1.500.000"}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <div className='my-5 text-bold text-center'>{'Pilih Jenis Pembayaran'}</div>
            <Row className='justify-content-left'>
                <Col md={4}>
                    <Card className="rounded text-center" >
                        <CardBody>
                            <img src={payShopay} className='img-fluid' />
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="rounded text-center" >
                        <CardBody>
                            <img src={payJenius} className='img-fluid' />
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="rounded text-center" >
                        <CardBody>
                            <img src={payDana} className='img-fluid' />
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="rounded text-center" >
                        <CardBody>
                            <img src={payOvo} className='img-fluid' />
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="rounded text-center" >
                        <CardBody>
                            <img src={payGopay} className='img-fluid m-1' />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Payment
