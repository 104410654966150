import { history } from "../../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
import { toastSuccess, toastError } from "../../../../components/commons/toast";

import API from "../../API";
import { handleResponse } from '../../HandleRespone';

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_ASSISTANT_PENDING = "GET_ASSISTANT_PENDING";
export const GET_ASSISTANT_SUCCESS = "GET_ASSISTANT_SUCCESS";
export const GET_ASSISTANT_ERROR = "GET_ASSISTANT_ERROR";
export const POST_ASSISTANT_PENDING = "POST_ASSISTANT_PENDING";
export const POST_ASSISTANT_SUCCESS = "POST_ASSISTANT_SUCCESS";
export const POST_ASSISTANT_ERROR = "POST_ASSISTANT_ERROR";
export const PUT_ASSISTANT_PENDING = "PUT_ASSISTANT_PENDING";
export const PUT_ASSISTANT_SUCCESS = "PUT_ASSISTANT_SUCCESS";
export const PUT_ASSISTANT_ERROR = "PUT_ASSISTANT_ERROR";
export const DELETE_ASSISTANT_PENDING = "DELETE_ASSISTANT_PENDING";
export const DELETE_ASSISTANT_SUCCESS = "DELETE_ASSISTANT_SUCCESS";
export const DELETE_ASSISTANT_ERROR = "DELETE_ASSISTANT_ERROR";

// URL: URL_{URL}
const ASSISTANT_URL = `v1/master_data/assistant`;

const get = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(GET_ASSISTANT_PENDING));
    API.get(ASSISTANT_URL, { params: param })
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_ASSISTANT_SUCCESS, res));
            if (resolve) {
                const records_total = res.data.total;
                let data = res.data.data.map((item, i) => ({
                    ...item,
                    no: i + 1 + (param?.page - 1) * param?.length,
                    assistant_id: item?.assistant_id ? item?.assistant_id : ' - ',
                    airport: `${item?.airport_data?.code} - ${item?.airport_data?.city}`
                }));
                resolve({
                    data: data,
                    page: param?.page - 1,
                    totalCount: records_total,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_ASSISTANT_ERROR));
            let not_logout = handleResponse(err?.response);
            if (not_logout) {
                toastError(err?.response?.data?.message);
            }
        });
};

const post = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(POST_ASSISTANT_PENDING));
    API.post(ASSISTANT_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(POST_ASSISTANT_SUCCESS, res));
            toastSuccess("Berhasil Tambah Data User Assistant");
            if (resolve) {
                resolve({
                    data: res.data.data,
                    message: res.data.message,
                    status: res.data.status,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(POST_ASSISTANT_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const put = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(PUT_ASSISTANT_PENDING));
    API.put(ASSISTANT_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(PUT_ASSISTANT_SUCCESS, res));
            toastSuccess("Berhasil Ubah Data User Assistant");
            if (resolve) {
                resolve({
                    data: res.data.data,
                    message: res.data.message,
                    status: res.data.status,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(PUT_ASSISTANT_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const deleted = (param, callback) => (dispatch) => {
    dispatch(actionPending(DELETE_ASSISTANT_PENDING));
    API.delete(ASSISTANT_URL + `?id=${param}`)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(DELETE_ASSISTANT_SUCCESS, res));
            toastSuccess(`Berhasil Hapus Data`);
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(DELETE_ASSISTANT_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const assistant = {
    get,
    post,
    put,
    deleted,
};
export default assistant;
