import { React } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { withTrans } from '../../../i18n/withTrans';
import { Col, Row } from 'reactstrap';
import { history } from "../../../utils/History"
import Button from '../../commons/buttons/Button';
import footerIcon from '../../../assets/images/icon/joumpa-icon-footer.svg';
import gapuraIcon from '../../../assets/images/icon/gapura.svg';
import qrGooglePlay from "../../../assets/images/icon/google-play.png";
import qrIStore from "../../../assets/images/icon/ios-store.png";
import gPlay from "../../../assets/images/icon/google-play.svg";
import iStore from "../../../assets/images/icon/ios-store.svg";

let Index = ({ t }) => {

    const match = useRouteMatch('/reservation')

    return (
        <div className='footer'>
            <div className='support-footer'>
                <div className='text-support-container'>
                    <div className='title text-extra-bold '>{!match ? t('home.titleFooter') : t('footer.titleFooter')}</div>
                    <div className='normal-title'>{!match ? t('home.subtitleFooter') : t('footer.subtitleFooter')}</div>
                    <Button
                        title={!match ? t('home.homeButtonFoot') : t('footer.buttonFooter')}
                        variant="button primary"
                        rounded={true}
                        onClick={!match ? () => history.push('/reservation') : () => history.push('/support')}
                        style={{ textAlign: "center", borderRadius: "48px", padding: "16px 80px" }}
                    />
                </div>
            </div>
            <div className='main-footer'>
                <Row>
                    <Col lg={2} md={3} sm={12} className='footer-logo mb-5'>
                        <img src={footerIcon} className='img-fluid' />
                        <div className="right">
                            <div className='normal-title text-white mt-4 mb-3'>
                                {`${t("footer.productOf")} : `}
                            </div>
                            <img src={gapuraIcon} className=' img-fluid' />
                        </div>
                    </Col>
                    <Col md={4} sm={6}>
                        <Row className="ml-0 ml-md-2">
                            <Col className="pl-0">
                                <div className='footer-title-link normal-title text-bold mb-4'>
                                    {t("footer.information")}
                                </div>
                                <div 
                                    className='footer-link normal-title clickable mb-3' 
                                    onClick={() => history.push('/terms-condition')}
                                >
                                    {t("footer.terms")}
                                </div>
                                <div 
                                    className='footer-link normal-title clickable mb-3' 
                                    onClick={() => history.push('/privacy-policy')}
                                >
                                    {t("footer.privacyPolicy")}
                                </div>
                                <div 
                                    className='footer-link normal-title clickable mb-3' 
                                    onClick={() => history.push('/support')}
                                >
                                    {t("footer.contactUs")}
                                </div>
                            </Col>
                            <Col>
                                <div className='footer-title-link normal-title text-bold mb-4'>
                                    {t("footer.aboutUs")}
                                </div>
                                <div 
                                    className='footer-link normal-title clickable mb-3' 
                                    onClick={() => history.push('/our-location')}
                                >
                                    {t("footer.ourLocation")}
                                </div>
                                <div 
                                    className='footer-link normal-title clickable mb-3' 
                                    onClick={() => history.push('/')}
                                >
                                    {`${t("commons.about")} Joumpa`}
                                </div>
                                <a 
                                    className='footer-link normal-title clickable mb-3' 
                                    target="_blank"
                                    href="https://gapura.id/about-us-uk"
                                >
                                    {`${t("commons.about")} Gapura`}
                                </a>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={3} md={4} sm={6} className="mt-4 mt-sm-0">
                        <div className='footer-title-link normal-title text-bold mb-4'>
                            Contact Center
                        </div>
                        <Row className="ml-0 mb-3">
                            <Col sm={4} xs={3} className="pl-0">
                                <div 
                                    className='footer-title-link normal-title' 
                                >
                                    Whatsapp
                                </div>
                            </Col>
                            <Col sm={8} xs={9} className="">
                                <div className='footer-link normal-title'>
                                    +62-811-8566-009
                                </div>
                            </Col>
                        </Row>
                        <Row className="ml-0 mb-3">
                            <Col sm={4} xs={3} className="pl-0">
                                <div 
                                    className='footer-title-link normal-title' 
                                >
                                    Email
                                </div>
                            </Col>
                            <Col sm={8} xs={9} className="">
                                <div className='footer-link normal-title'>
                                    joumpa@gapura.id
                                </div>
                            </Col>
                        </Row>
                        <Row className="ml-0 mb-3">
                            <Col sm={4} xs={3} className="pl-0">
                                <div 
                                    className='footer-title-link normal-title' 
                                >
                                    Call Center
                                </div>
                            </Col>
                            <Col sm={8} xs={9} className="">
                                <div className='footer-link normal-title'>
                                    +62-811-8566-009
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={3} md={4} sm={8} className="pr-md-0 mt-4 mt-md-0">
                        <div className='footer-title-link normal-title mb-4'>
                            {t("footer.downloadApps")}
                        </div>
                        <Row className="ml-0">
                            <Col className="d-flex flex-column mb-3 px-2">
                                <img src={qrIStore} className='img-fluid footer-qr-app mb-2' />
                                <a 
                                    target='_blank'
                                    href="https://play.google.com/store/apps/details?id=id.bigio.gapura.joumpa"
                                >
                                    <img src={gPlay} className='img-fluid' />
                                </a>
                            </Col>
                            <Col className="d-flex flex-column mb-3 px-2">
                                <img src={qrGooglePlay} className='img-fluid footer-qr-app mb-2' />
                                <a
                                    target='_blank'
                                    href="https://apps.apple.com/id/app/joumpa-for-customer/id1600399708?l=id"
                                >
                                    <img src={iStore} className='img-fluid' />
                                </a>
                            </Col>
                        </Row>
                    </Col>
                    {/* {match ?
                        <Col md={10}>
                            <div className='list-main-footer row'>
                                <div className='normal-title'>{'© Joumpa 2021'}</div>
                                <div className='normal-title clickable' onClick={() => history.push('/')}>{t("footer.home")}</div>
                                <div className='normal-title clickable' onClick={() => history.push('/support')}>{t("footer.contactUs")}</div>
                                <div className='normal-title clickable' onClick={() => history.push('/support')}>{t("footer.ourLocation")}</div>
                            </div>
                        </Col>
                        :
                        <Col lg={10} md={7}>
                            <div className='list-main-footer '>
                                <div className='normal-title'>{'© Joumpa 2021'}</div>
                                <div className='normal-title '>{t("footer.downloadFooter")}</div>
                                <div className='normal-title clickable' onClick={() => history.push('/')}>
                                    <img src={gPlay} className='img-fluid' />
                                </div>
                                <div className='normal-title clickable' onClick={() => history.push('/')}>
                                    <img src={iStore} className='img-fluid' />
                                </div>
                            </div>
                        </Col>
                    } */}
                </Row>
            </div>
        </div>
    )
}

export default withTrans(Index)
