import "./card.scss";

const cardBody = (props) => {
  return (
    <div
      className={`${props.isActive ? "card-body-green" : "card-body"} rounded ${
        props.position
      }`}
      style={props.style}
    >
      {props.children}
    </div>
  );
};

export default cardBody;
