import {
    GET_CORPORATE_PENDING,
    GET_CORPORATE_SUCCESS,
    GET_CORPORATE_ERROR,
    GET_PRICE_CORPORATE_PENDING,
    GET_PRICE_CORPORATE_SUCCESS,
    GET_PRICE_CORPORATE_ERROR,
    POST_CORPORATE_PENDING,
    POST_CORPORATE_SUCCESS,
    POST_CORPORATE_ERROR,
    PUT_CORPORATE_PENDING,
    PUT_CORPORATE_SUCCESS,
    PUT_CORPORATE_ERROR,
    DELETE_CORPORATE_PENDING,
    DELETE_CORPORATE_SUCCESS,
    DELETE_CORPORATE_ERROR,
} from "../../../actions/master_data/corporate";

const initialState = {
    pending: false,
    error: null,
    data_list: null,
    pending_post: false,
    data_post: null,
    pending_price: false,
    data_price: null,
    pending_put: false,
    data_put: false,
    pending_delete: false,
    data_delete: null
};

const corporate = (state = initialState, action) => {
    switch (action.type) {
        case GET_CORPORATE_PENDING:
            return {
                ...state,
                pending: true,
            };
        case GET_CORPORATE_SUCCESS:
            return {
                ...state,
                pending: false,
                data_list: action.data,
            };
        case GET_CORPORATE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case GET_PRICE_CORPORATE_PENDING:
            return {
                ...state,
                pending_price: true,
            };
        case GET_PRICE_CORPORATE_SUCCESS:
            return {
                ...state,
                pending_price: false,
                data_price: action.data,
            };
        case GET_PRICE_CORPORATE_ERROR:
            return {
                ...state,
                pending_price: false,
                error: action.error,
            };
        case POST_CORPORATE_PENDING:
            return {
                ...state,
                pending_post: true,
            };
        case POST_CORPORATE_SUCCESS:
            return {
                ...state,
                pending_post: false,
                data_post: action.data,
            };
        case POST_CORPORATE_ERROR:
            return {
                ...state,
                pending_post: false,
                error: action.error,
            };
        case PUT_CORPORATE_PENDING:
            return {
                ...state,
                pending_put: true,
            };
        case PUT_CORPORATE_SUCCESS:
            return {
                ...state,
                pending_put: false,
                data_put: action.data,
            };
        case PUT_CORPORATE_ERROR:
            return {
                ...state,
                pending_put: false,
                error: action.error,
            };
        case DELETE_CORPORATE_PENDING:
            return {
                ...state,
                pending_delete: true,
            };
        case DELETE_CORPORATE_SUCCESS:
            return {
                ...state,
                pending_delete: false,
                data_delete: action.data,
            };
        case DELETE_CORPORATE_ERROR:
            return {
                ...state,
                pending_delete: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default corporate;
