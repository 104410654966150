import React from "react";
import { Col, Container, Row } from "reactstrap";
import { withTrans } from "../../i18n/withTrans";
const index = ({ t }) => {
  document.documentElement.scrollTop = 0;

  return (
    <div>
      <Container fluid>
        <div id="information">
          <div className="product-bg d-flex">
            <Row className="m-auto justify-content-center">
              <Col md={12}>
                <div className="super-title text-center text-extra-bold">
                  {t("support.headline")}
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <section id="product">
          <div className="support-content row">
            <div className="list-main-navbar col-md-6 col-sm-12 mb-5 line-2">
              <div className="content-subtitle">{t("support.address")}</div>
              <hr style={{ marginLeft: 0 }} />
              <div>
                Gedung Gapura
                <br></br>
                Jl. Merpati III, Blok B-12, Kav. 7.
                <br></br>
                Kota Baru, Kemayoran, Jakarta Pusat
              </div>
            </div>
            <div className="list-main-navbar col-md-6 line-2">
              <div className="content-subtitle">{t("field.contact")}</div>
              <hr style={{ marginLeft: 0 }} />
              <div className="row">
                <div className="col-md-6">
                  <strong>{t("support.phone")}</strong>
                </div>
                <div className="col-md-6">
                  <div>+62-811-8566-009</div>
                </div>
                <div className="col-md-6">
                  <strong>Fax</strong>
                </div>
                <div className="col-md-6">
                  <div>+62-21-654-5408</div>
                </div>
                <div className="col-md-6">
                  <strong>Email</strong>
                </div>
                <div className="col-md-6">
                  <div>joumpa@gapura.id</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default withTrans(index);
