import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

let joumpa_language = "joumpa_language"
if (typeof Storage !== "undefined") {
    if (localStorage.getItem(joumpa_language) === null) {
        localStorage.setItem(joumpa_language, window.navigator.language);
    }
}

i18next.use(initReactI18next).init({
    fallbackLng: localStorage.getItem(joumpa_language),
    resources: {
        id: {
            translations: require('../locales/id/translations.json')
        },
        en: {
            translations: require('../locales/en/translation.json')
        },
        enUS: {
            translations: require('../locales/enUS/translation.json')
        },
    },
    ns: ['translations'],
    defaultNS: 'translations',
    returnObjects: true,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
    react: {
        wait: true,
        useSuspense: true,
    },
});

i18next.languages = ['id', 'en', 'en-US'];

export default i18next;