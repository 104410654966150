import { React, useState } from 'react'
import { useTranslation } from "react-i18next";
import { NavLink, useRouteMatch } from 'react-router-dom'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import Button from "../../commons/buttons/Button";
import joumpaIcon from '../../../assets/images/icon/joumpa-icon.png'
import flagEN from '../../../assets/images/icon/flag_en.svg'
import flagID from '../../../assets/images/icon/flag_id.svg'
import { t } from 'i18next';

const Index = () => {
    let joumpa_language = "joumpa_language"
    const { i18n } = useTranslation()

    const [lang, setLang] = useState(localStorage.getItem(joumpa_language))
    const [isOpen, setIsOpen] = useState(false);
    const handleChangeLang = (value) => {
        if (value === 'id') {
            setLang('id')
            localStorage.setItem(joumpa_language, 'id')
            i18n.changeLanguage('id');
        }
        if (value === 'en') {
            setLang('en')
            localStorage.setItem(joumpa_language, 'en')
            i18n.changeLanguage('en');
        }
        window.location.reload()
    }
    const match = useRouteMatch('/reservation')

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className={match ? '' : 'container-fluid'}>
            <div className={`${match ? 'main-navbar shadow p-md-4 p-0' : 'main-navbar'} ${isOpen ? ' mb-4' : ''}`}>
                <Navbar expand="lg" light>
                    <NavbarBrand href="/">
                        <img src={joumpaIcon} className='img-fluid' />
                    </NavbarBrand>
                    {match && <div className='booking-title ml-auto text-extra-bold'>{t('navbar.textNavbar')}</div>}
                    {!match &&
                        <>
                            <NavbarToggler onClick={toggle} />
                            <Collapse isOpen={isOpen} navbar className='mx-auto'>
                                <Nav className="ml-auto" navbar>
                                    <NavItem>
                                        <NavLink
                                            className={`${isOpen ? ' text-bold nav-link navbar-list' : 'navbar-list pr-4 pl-3 nav-link'}`}
                                            to="/"
                                            exact
                                        >
                                            {t('navbar.listHome')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={`${isOpen ? ' text-bold nav-link navbar-list' : 'navbar-list pr-4 pl-3 nav-link'}`}
                                            to="/products"
                                        >
                                            {t('navbar.listProducts')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={`${isOpen ? ' text-bold nav-link navbar-list' : 'navbar-list pr-4 pl-3 nav-link'}`}
                                            to="/our-location"
                                        >
                                            {t('footer.ourLocation')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={`${isOpen ? ' text-bold nav-link navbar-list' : 'navbar-list pr-4 pl-3 nav-link'}`}
                                            to="/manage-booking"
                                        >
                                            {t('navbar.manageBooking')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={`${isOpen ? ' text-bold nav-link navbar-list' : 'navbar-list pr-4 pl-3 nav-link'}`}
                                            to="/support"
                                        >
                                            {t('footer.contactUs')}
                                        </NavLink>
                                    </NavItem>
                                    <UncontrolledDropdown className={`${isOpen ? ' text-bold' : 'pr-4 pl-3'}`} nav inNavbar>
                                        <DropdownToggle nav>
                                            <img src={lang === 'id' ? flagID : flagEN} className='language img-fluid' />
                                            <span className='px-3 text-extra-bold'>{`${lang === 'id' ? 'ID' : 'EN'}`}</span>
                                        </DropdownToggle>
                                        <DropdownMenu className='rounded' right>
                                            <DropdownItem onClick={() => handleChangeLang('id')}>
                                                Indonesian
                                            </DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem onClick={() => handleChangeLang('en')}>
                                                English
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <NavItem className='mt-2 mt-md-0'>
                                        <a
                                            target='_blank'
                                            href={process.env.REACT_APP_ADMIN_URL}
                                        >
                                            <Button
                                                title='Login'
                                                variant='primary'
                                                style={{ padding: '10px 24px'}}
                                                rounded
                                            />
                                        </a>
                                    </NavItem>
                                </Nav>
                            </Collapse>
                        </>
                    }
                </Navbar>
            </div>
        </div>
    )
}

export default Index
