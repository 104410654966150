import React, { useEffect, useState, memo } from "react";
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

import url from "./map.json";
import { locations } from "../../utils/Constants/CoordinateLocations";

const MapChart = ({ setTooltipContent, selectedAirport, setInfoLocation }) => {
  const [selectedLocations, setSelectedLocations] = useState(
    locations?.map((item) => ({...item, selected: true}))
  );
  const [position, setPosition] = useState({ coordinates: [118, -3], zoom: 1 });

  const handleFilterLocation = (airportId) => {
    if (airportId !== "all") {
      const filteredLocation = 
        locations?.map((item) =>
          item?.id !== airportId  
            ? { ...item, selected: false }
            : { ...item, selected: true }
        );
      const indexSelectedocation = filteredLocation?.findIndex(
        (item) => item?.selected == true
      );
      setSelectedLocations(filteredLocation);
      setPosition((pos) => ({
        coordinates: [
          filteredLocation[indexSelectedocation]?.longitude,
          filteredLocation[indexSelectedocation]?.latitude,
        ],
        zoom: pos.zoom >= 4 ? pos.zoom : pos.zoom * 4,
      }));
      setInfoLocation(locations?.find((item) => item?.id === airportId));
    } else {
      setSelectedLocations(
        locations?.map((item) => ({...item, selected: true}))
      );
      setPosition({ coordinates: [118, -3], zoom: 1 });
      setInfoLocation(null);
    }
  };

  useEffect(() => {
    handleFilterLocation(selectedAirport);
  }, [selectedAirport]);

  const handleZoomIn = () => {
    if (position.zoom >= 4) return;
    setPosition((pos) => ({ coordinates: [118, -3], zoom: pos.zoom * 2 }));
  };

  const handleZoomOut = () => {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ coordinates: [118, -3], zoom: pos.zoom / 2 }));
  };

  const handleMoveEnd = (position) => {
    setPosition(position);
  };

  const handleFilter = ({ constructor: { name } }) => {
    return name !== "WheelEvent" && name !== "MouseEvent";
  };

  return (
    <div className="map">
      <div className="map-controls d-flex ml-auto justify-content-end align-items-center px-4">
        <button onClick={handleZoomOut}>
          <span className="material-icons-outlined">remove</span>
        </button>
        <button onClick={handleZoomIn}>
          <span className="material-icons-outlined">add</span>
        </button>
      </div>
      <ComposableMap
        height={400}
        projection="geoEqualEarth"
        projectionConfig={{
          center: [118, -3],
          scale: 1000,
        }}
      >
        <ZoomableGroup
          zoom={position.zoom}
          center={position.coordinates}
          onMoveEnd={handleMoveEnd}
          filterZoomEvent={handleFilter}
        >
          <Geographies geography={url}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={"#83C47E"}
                  style={{
                    default: {
                      fill: "#83C47E",
                      outline: "none",
                    },
                    hover: {
                      fill: "#518E44",
                      outline: "none",
                    },
                    pressed: {
                      fill: "#518E44",
                      outline: "none",
                    },
                  }}
                />
              ))
            }
          </Geographies>
          {selectedLocations.map((item) => (
            <Marker
              data-tip=""
              key={item?.name}
              coordinates={[item?.longitude, item?.latitude]}
              style={{
                default: {
                  outline: "none",
                },
                hover: {
                  outline: "none",
                },
                pressed: {
                  outline: "none",
                },
              }}
              onMouseEnter={() => {
                setTooltipContent(
                  <>
                    <p className="text-bold normal-title mb-0">
                      {`${item?.code} - ${item?.name}`}
                    </p>
                    <p className="small-text mb-0">
                      {`${item?.city} - ${item?.uniform}`}
                    </p>
                  </>
                );
              }}
              onMouseLeave={() => {
                setTooltipContent("");
              }}
              onClick={() => {
                handleFilterLocation(item?.id);
                setInfoLocation(item);
                setPosition((pos) => ({
                  coordinates: [item?.longitude, item?.latitude],
                  zoom: pos.zoom >= 4 ? pos.zoom : pos.zoom * 4,
                }));
                setTooltipContent(
                  <>
                    <p className="text-bold normal-title mb-0">
                      {`${item?.code} - ${item?.name}`}
                    </p>
                    <p className="small-text mb-0">
                      {`${item?.city} - ${item?.uniform}`}
                    </p>
                  </>
                );
              }}
            >
              {item?.selected ? (
                <circle
                  className="clickable"
                  r={3}
                  fill="#F78217"
                  stroke="rgba(247, 130, 23, 0.15)"
                  strokeWidth={10}
                />
              ) : (
                <></>
              )}
            </Marker>
          ))}
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
};

export default memo(MapChart);
