import { ErrorMessage } from "formik";
import React from "react";
import NumberFormat from "react-number-format";

const InputPrepend = ({
  min,
  max,
  name,
  value,
  disabled,
  title,
  isCurrency,
  placeholder,
  onChange,
  onKeyDown,
  errors,
  touched,
  type,
  prefix,
  isAllowed,
  objname,
  multiple,
  width,
  mt
}) => {

  const error = errors ? (objname && objname?.parent ? (errors[objname?.parent] ? errors[objname?.parent][objname?.child] : errors[objname?.parent]) : errors[name]) : false
  const touch = touched ? (objname && objname?.parent ? (touched[objname?.parent] ? touched[objname?.parent][objname?.child] : touched[objname?.parent]) : touched[name]) : false
  const border = (error && touch
    ? "solid 1px red"
    : "")

  return (
    <>
      <div className={`input-group mb-3 ${multiple ? 'multiple' : ''}  mt-${mt ? mt : 4}`}>
        <div className="input-group-prepend" style={{
          width: multiple ? `calc(100%/${Number(multiple.length + 1)} )` : width ? width : "50%"
        }}>
          <span
            className="input-group-text"
            style={{
              width: "100%",
              border: border,
            }}
            id="basic-addon1"
          >
            {title}
          </span>
        </div>
        {
          multiple && multiple?.map((item, index) => {
            const errorItem = errors ? (item.objname && item.objname?.parent ? (errors[item.objname?.parent] ? errors[item.objname?.parent][item.objname?.child] : errors[item.objname?.parent]) : errors[item.name]) : false
            const touchItem = touched ? (item.objname && item.objname?.parent ? (touched[item.objname?.parent] ? touched[item.objname?.parent][item.objname?.child] : touched[item.objname?.parent]) : touched[item.name]) : false
            const isLastItem = index === (multiple.length - 1)
            return (
              <React.Fragment key={item.name + index}>
                {
                  item?.isCurrency ? (
                    <NumberFormat
                      className={
                        "form-control form-input " +
                        (item.disabled ? "input-disabled " : "") +
                        (errorItem && touchItem
                          ? " is-invalid"
                          : ""
                        ) + (isLastItem ? " multiple-last" : "")
                      }
                      disabled={item.disabled}
                      thousandSeparator={true}
                      prefix={item.prefix ?? ""}
                      onChange={item.onChange}
                      value={item.value != null ? item.value : ""}
                      placeholder={item.placeholder}
                      isAllowed={item.isAllowed}
                    />
                  ) : (
                    <input
                      name={item.name}
                      type={item.type ?? "text"}
                      className={
                        "form-control form-input " +
                        (item.disabled ? "input-disabled " : "") +
                        (errorItem && touchItem
                          ? " is-invalid"
                          : ""
                        ) + (isLastItem ? "multiple-last" : "")
                      }
                      value={item.value != null ? item.value : ""}
                      disabled={item.disabled}
                      min={item.min}
                      max={item.max}
                      onChange={item.onChange}
                      onKeyDown={item.onKeyDown}
                      onWheel={(e) => item?.type === "number" ? e.target.blur() : console.log("")}
                      placeholder={item.placeholder}
                      aria-label={item.placeholder}
                      aria-describedby="basic-addon1"
                    />
                  )
                }
              </React.Fragment>)
          })
        }
        {
          !multiple && (
            <>
              {isCurrency ? (
                <NumberFormat
                  className={
                    "form-control form-input " +
                    (disabled ? "input-disabled " : "") +
                    (error && touch
                      ? " is-invalid"
                      : ""
                    )
                  }
                  thousandSeparator={true}
                  prefix={prefix ?? ""}
                  onChange={onChange}
                  value={value != null ? value : ""}
                  placeholder={placeholder}
                  isAllowed={isAllowed}
                  disabled={disabled}
                />
              ) : (
                <input
                  name={name}
                  type={type ?? "text"}
                  className={
                    "form-control form-input " +
                    (disabled ? "input-disabled " : "") +
                    (error && touch
                      ? " is-invalid"
                      : ""
                    )
                  }
                  disabled={disabled}
                  value={value != null ? value : ""}
                  min={min}
                  max={max}
                  onChange={onChange}
                  onKeyDown={onKeyDown}
                  onWheel={(e) => type === "number" ? e.target.blur() : console.log("")}
                  placeholder={placeholder}
                  aria-label={placeholder}
                  aria-describedby="basic-addon1"
                />
              )}
            </>
          )
        }
        {
          !multiple && errors && <ErrorMessage
            name={name}
            component="div"
            className="invalid-feedback"
          />
        }
      </div>
    </>
  );
};
export default InputPrepend;
