import React from "react";
import { Modal, ModalHeader, ModalBody, Container } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import NumberFormat from "react-number-format";
import moment from "moment";

import { withTrans } from "../../../i18n/withTrans";
import Input from "../../../components/commons/forms/Input";
import Select2 from "../../../components/commons/forms/Select2";
import ButtonModal from "../../../components/commons/modal/ButtonModal";
import { toastError } from "../../../components/commons/toast";

const PassengerModal = ({ formik, dataCountries, show, toggle, t, idx }) => {
  const index = idx
    ? idx
    : formik?.values?.passenger_list?.length > 0 && idx === null
    ? formik?.values?.passenger_list?.length - 1
    : formik?.values?.passenger_list?.length > 0 && idx !== null
    ? idx
    : 0;

  const getDate = moment().format("YYYY-MM-DD");

  const handleAddPassengger = (formik) => {
    let passenger_list = formik.values.passenger_list;
    formik?.setFieldValue(`passenger_list`, passenger_list);
    formik?.setFieldValue(
      `passengers_adult`,
      passenger_list?.filter(
        (item) => item?.title === "Mr." || item?.title === "Mrs." || item?.title === "Ms."
      ).length
    );
    formik?.setFieldValue(
      `passengers_child`,
      passenger_list?.filter((item) => item?.title === "Child").length
    );
    formik?.setFieldValue(
      `passengers_infant`,
      passenger_list?.filter((item) => item?.title === "Infant").length
    );
    toggle(toggle);
  };
  const handleCancelPassenger = (formik) => {
    let i = formik?.values?.passenger_list?.length - 1;
    if ((idx === null || idx === undefined) && formik?.values?.passenger_list) {
      let passenger_list = formik?.values?.passenger_list;
      passenger_list.splice(i, 1);
      formik?.setFieldValue("passenger_list", passenger_list);
      toggle();
    } else {
      toggle();
    }
  };

  const handleDeletePassenger = (props, index) => {
    let passenger_list = props?.values?.passenger_list;
    passenger_list.splice(index, 1);
    props?.setFieldValue("passenger_list", passenger_list);
    formik?.setFieldValue(
      `passengers_adult`,
      passenger_list?.filter(
        (item) => item?.title === "Mr." || item?.title === "Mrs." || item?.title === "Ms."
      ).length
    );
    formik?.setFieldValue(
      `passengers_child`,
      passenger_list?.filter((item) => item?.title === "Child").length
    );
    formik?.setFieldValue(
      `passengers_infant`,
      passenger_list?.filter((item) => item?.title === "Infant").length
    );
    toggle();
  };

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {t("field.passengerData")}{" "}
          {index > 0 && (
            <span
              className="close clickable"
              onClick={() => handleCancelPassenger(formik)}
            >
              &times;
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          <Container>
            <Select2
              name={`passenger_list[${index}].title`}
              type="text"
              errors={formik.errors}
              touched={formik.touched}
              options={[
                { label: t("commons.mr"), value: "Mr." },
                { label: t("commons.mrs"), value: "Mrs." },
                { label: t("commons.ms"), value: "Ms." },
                { label: t("commons.child"), value: "Child" },
                { label: t("commons.infant"), value: "Infant" },
              ]}
              {...formik.getFieldProps(`passenger_list[${index}].title`)}
              value={formik?.values?.passenger_list[index]?.title ?? ""}
              onChange={(name, value) => {
                formik?.setFieldValue(`passenger_list[${index}].title`, value);
              }}
              onBlur={formik?.setFieldTouched}
              title={t("field.title")}
            />
            <Input
              name={`passenger_list[${index}].name`}
              errors={formik.errors}
              touched={formik.touched}
              {...formik.getFieldProps(`passenger_list[${index}].name`)}
              title={t("field.name")}
              type="text"
            />
            {formik?.values?.passenger_list[index]?.title === "Infant" && (
              <Input
                title={t("field.birth_date")}
                name={`passenger_list[${index}].passenger_date_of_birth`}
                type="date"
                max={getDate}
                errors={formik.errors}
                touched={formik.touched}
                value={
                  formik?.values?.passenger_list[index]
                    ?.passenger_date_of_birth ?? ""
                }
                onChange={(e) => {
                  let value = e.target.value;
                  formik?.setFieldValue(
                    `passenger_list[${index}].passenger_date_of_birth`,
                    value
                  );
                }}
              />
            )}
            {/* <Input
              name={`passenger_list[${index}].passenger_nationality`}
              errors={formik.errors}
              touched={formik.touched}
              value={
                formik?.values?.passenger_list[index]?.passenger_nationality ??
                ""
              }
              onChange={(e) => {
                let value = e.target.value;
                formik?.setFieldValue(
                  `passenger_list[${index}].passenger_nationality`,
                  value
                );
              }}
              title={t("field.nationality")}
              type="text"
            /> */}
            <Select2
              clearable={!!formik?.values?.passenger_list[index]?.passenger_nationality}
              name={`passenger_list[${index}].passenger_nationality`}
              title={t("field.nationality")}
              options={dataCountries}
              value={formik?.values?.passenger_list[index]?.passenger_nationality}
              onChange={(name, value) => {
                formik?.setFieldValue(
                  `passenger_list[${index}].passenger_nationality`, 
                  value
                );
              }}
              errors={formik.errors}
              touched={formik.touched}
              onBlur={formik.setFieldTouched}
            />
            <Input
              name={`passenger_list[${index}].email`}
              type="email"
              errors={formik.errors}
              touched={formik.touched}
              {...formik.getFieldProps(`passenger_list[${index}].email`)}
              title={t("field.email")}
            />
            <div className="form-group">
              <div className="form-label">{t("field.phone")}</div>
              <div className="input-wrapper w-100">
                <PhoneInput
                  enableTerritories={true}
                  countryCodeEditable={false}
                  placeholder={t("field.placeHolderPhoneNumber")}
                  country={formik?.values?.passenger_list[index]?.countryId ?? "id"}
                  inputStyle={{ width: '100%' }}
                  enableSearch={true}
                  value={formik?.values?.passenger_list[index]?.phoneNumber}
                  onChange={(phone, country) => {
                    formik?.setFieldValue(`passenger_list[${index}].countryCode`, country?.dialCode);
                    formik?.setFieldValue(`passenger_list[${index}].phoneNumber`, phone);
                    formik?.setFieldValue(`passenger_list[${index}].countryId`, country?.countryCode);
                  }}
                />
                {/* <NumberFormat
                  className="form-control form-input"
                  name={`passenger_list[${index}].phoneNumber`}
                  type="tel"
                  {...formik.getFieldProps(
                    `passenger_list[${index}].phoneNumber`
                  )}
                  style={{ padding: "25px" }}
                  placeholder={`${t("commons.input")} ${t("field.phone")}`}
                /> */}
              </div>
            </div>
          </Container>
          <div style={{ margin: "50px 20px 0 20px" }}>
            {formik?.values?.passenger_list?.length <= 1 ? (
              <ButtonModal
                toggle={() => handleCancelPassenger(formik)}
                confirm={() => {
                  if (formik?.values?.passenger_list[index]?.title === "") {
                    toastError(
                      `${t("commons.empty_data_message")} ${t("field.title")}!`
                    );
                    return;
                  }
                  if (
                    formik?.values?.passenger_list[index]?.title === "Infant"
                  ) {
                    if (
                      formik?.values?.passenger_list[index]
                        ?.passenger_date_of_birth === "" ||
                      formik?.values?.passenger_list[index]
                        ?.passenger_date_of_birth === undefined
                    ) {
                      toastError(
                        `${t("commons.empty_data_message")} ${t(
                          "field.birth_date"
                        )}!`
                      );
                      return;
                    } else {
                      const yearDiff = moment().diff(
                        formik?.values?.passenger_list[index]
                          ?.passenger_date_of_birth,
                        "year"
                      );
                      if (yearDiff > 2) {
                        toastError(
                          `${t("commons.birth_date_less_than_message")}!`
                        );
                        return;
                      }
                    }
                  }
                  if (
                    formik?.values?.passenger_list[index]
                      ?.passenger_nationality === ""
                  ) {
                    toastError(
                      `${t("commons.empty_data_message")} ${t(
                        "field.nationality"
                      )}!`
                    );
                    return;
                  }
                  if (formik?.values?.passenger_list[index]?.name === "") {
                    toastError(
                      `${t("commons.empty_data_message")} ${t("field.name")}!`
                    );
                    return;
                  }
                  if (
                    formik?.values?.passenger_list[index]?.email &&
                    !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                      formik?.values?.passenger_list[index]?.email
                    ))
                  ) {
                    toastError(`${t("commons.wrong_email_format")}!`);
                    return;
                  }
                  if (
                    formik?.values?.passenger_list[index]?.title !== "Infant" &&
                    formik?.values?.passenger_list[index]?.title !== "Child"
                  ) {
                    if (formik.values.passenger_list[index].email === "") {
                      toastError(`${t("commons.empty_data_message")} Email!`);
                      return;
                    } else if (
                      formik.values.passenger_list[index].phoneNumber === "" ||
                      formik.values.passenger_list[index].phoneNumber.includes("undefined") ||
                      formik.values.passenger_list[index].phoneNumber === formik.values.passenger_list[index].countryCode
                    ) {
                      toastError(
                        `${t("commons.empty_data_message")} ${t(
                          "field.phone"
                        )}!`
                      );
                      return;
                    } else {
                      handleAddPassengger(formik);
                    }
                  } else {
                    handleAddPassengger(formik);
                  }
                }}
                resetTitle={t("commons.delete")}
                confirmTitle={t("commons.save")}
                icon
              />
            ) : (
              <ButtonModal
                reset={() => handleDeletePassenger(formik, index)}
                toggle={() => handleCancelPassenger(formik)}
                confirm={() => {
                  if (formik?.values?.passenger_list[index]?.title === "") {
                    toastError(
                      `${t("commons.empty_data_message")} ${t("field.title")}!`
                    );
                    return;
                  }
                  if (
                    formik?.values?.passenger_list[index]?.title === "Infant"
                  ) {
                    if (
                      formik?.values?.passenger_list[index]
                        ?.passenger_date_of_birth === "" ||
                      formik?.values?.passenger_list[index]
                        ?.passenger_date_of_birth === undefined
                    ) {
                      toastError(
                        `${t("commons.empty_data_message")} ${t(
                          "field.birth_date"
                        )}!`
                      );
                      return;
                    } else {
                      const yearDiff = moment().diff(
                        formik?.values?.passenger_list[index]
                          ?.passenger_date_of_birth,
                        "year"
                      );
                      if (yearDiff > 2) {
                        toastError(
                          `${t("commons.birth_date_less_than_message")}!`
                        );
                        return;
                      }
                    }
                  }
                  if (
                    formik?.values?.passenger_list[index]
                      ?.passenger_nationality === ""
                  ) {
                    toastError(
                      `${t("commons.empty_data_message")} ${t(
                        "field.nationality"
                      )}!`
                    );
                    return;
                  }
                  if (formik?.values?.passenger_list[index]?.name === "") {
                    toastError(
                      `${t("commons.empty_data_message")} ${t("field.name")}!`
                    );
                    return;
                  }
                  if (
                    formik?.values?.passenger_list[index]?.email &&
                    !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                      formik?.values?.passenger_list[index]?.email
                    ))
                  ) {
                    toastError(`${t("commons.wrong_email_format")}!`);
                    return;
                  }
                  if (
                    formik?.values?.passenger_list[index]?.title !== "Infant" &&
                    formik?.values?.passenger_list[index]?.title !== "Child"
                  ) {
                    if (formik.values.passenger_list[index].email === "") {
                      toastError(`${t("commons.empty_data_message")} Email!`);
                      return;
                    } else if (
                      formik.values.passenger_list[index].phoneNumber === "" ||
                      formik.values.passenger_list[index].phoneNumber.includes("undefined") ||
                      formik.values.passenger_list[index].phoneNumber === formik.values.passenger_list[index].countryCode
                    ) {
                      toastError(
                        `${t("commons.empty_data_message")} ${t(
                          "field.phone"
                        )}!`
                      );
                      return;
                    } else {
                      handleAddPassengger(formik);
                    }
                  } else {
                    handleAddPassengger(formik);
                  }
                }}
                resetTitle={t("commons.delete")}
                confirmTitle={t("commons.save")}
                icon
              />
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withTrans(PassengerModal);
