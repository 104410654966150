import { React, useState, useEffect } from 'react'
import { Col, Container, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import {Divider} from "@material-ui/core";
import ReactTooltip from "react-tooltip";

import MapChart from "./MapChart";
import Card from "../../components/commons/cards/card";
import CardBody from "../../components/commons/cards/cardBody";
import Select2 from "../../components/commons/forms/Select2";
import { withTrans } from "../../i18n/withTrans";

//Service
import AirportService from "../../store/actions/master_data/airport";

const Index = ({ t, error }) => {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const [dataAirport, setDataAirport] = useState([]);
  const [selectedAirport, setSelectedAirport] = useState("all");
  const [infoLocation, setInfoLocation] = useState(null);
  const [tooltipContent, setTooltipContent] = useState("");

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 660;
  const isTabled = width <= 767 && width > 659;

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 999,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirport(
        res?.data.map((airport) => {
          return {
            value: airport?.id,
            label: `${airport?.code}-${airport?.city}-${airport?.name} ${
              airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
            }`,
            uniform: airport?.uniform
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    if(infoLocation) {
      setSelectedAirport(infoLocation?.id);
    }
  }, [infoLocation]);

  return (
    <>
      <Container fluid>
        <section id="our-location">
          <div className="product-bg d-flex">
            <Row className="m-auto justify-content-center">
              <Col md={12}>
                <div className="super-title text-center text-extra-bold">
                  {t("ourLocation.headline")}
                </div>
                <div className="super-title text-center text-extra-bold">
                  {t("ourLocation.subheadline")}
                </div>
              </Col>
            </Row>
          </div>
          <div className="content">
            <Container>
              <Row className="justify-content-center">
                <Col md={10}>
                  <Card
                    className="rounded" 
                    style={{ 
                      borderStyle: 'none',
                      boxShadow: '0px 23px 80px 0px rgba(0, 0, 0, 0.06)',
                    }}
                  >
                    <CardBody style={{ paddingTop: '2rem', paddingBottom: '1rem' }}>
                      <div className="row justify-content-between">
                        <Col md={3} sm={12}>
                          <div className="py-2 text-bold">{t("ourLocation.location")}</div>
                        </Col>
                        <Col md={8} sm={12}>
                          <Select2
                            name="selectedAirport"
                            options={[
                              {
                                value: "all",
                                label: `${t("commons.all")} ${t("field.airport")}`,
                              },
                              ...dataAirport
                            ]}
                            value={selectedAirport}
                            onChange={(name, value) => {
                              setSelectedAirport(value);
                            }}
                          />
                        </Col>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <div className="my-5">
              <MapChart
                setTooltipContent={setTooltipContent}
                setInfoLocation={setInfoLocation}
                selectedAirport={selectedAirport}
              />
              <ReactTooltip
                place="top"
                effect="solid"
              >
                {tooltipContent}
              </ReactTooltip>
            </div>
            {infoLocation && (
              <Container>
                <Row className="justify-content-center">
                  <Col lg={10} md={12}>
                    <Card
                      style={{ 
                        borderStyle: 'none',
                      }}
                    >
                      <CardBody>
                        <p className="small-title text-bold">
                          {`${infoLocation?.code} - ${infoLocation?.name}`}
                        </p>
                        <Divider orientation="horizontal" className="mb-3" />
                        <Row>
                          <Col md={3} xs={4}>
                            <p className="text-bold">
                              {t("field.code")}
                            </p>
                          </Col>
                          <Col md={8} xs={7}>
                            {infoLocation?.code}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3} xs={4}>
                            <p className="text-bold">
                              {t("field.airportName")}
                            </p>
                          </Col>
                          <Col md={8} xs={7}>
                            {infoLocation?.name}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3} xs={4}>
                            <p className="text-bold">
                              Email
                            </p>
                          </Col>
                          <Col md={8} xs={7}>
                            {infoLocation?.email}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3} xs={4}>
                            <p className="text-bold">
                              {t("field.phone")}
                            </p>
                          </Col>
                          <Col md={8} xs={7}>
                            {infoLocation?.phone}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3} xs={4}>
                            <p className="text-bold">
                              {t("support.address")}
                            </p>
                          </Col>
                          <Col md={8} xs={7}>
                            {`${infoLocation?.city}, ${infoLocation?.uniform}`}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            )}
          </div>
        </section>
      </Container>
    </>
  );
};

export default withTrans(Index);
