import { history } from '../../utils/History'
import { toastSuccess } from '../../components/commons/toast';

export function handleResponse(response) {
    if ([401, 403].indexOf(response?.status) !== -1) {
        toastSuccess('Logout berhasil')
        localStorage.removeItem('user_joumpa')
        localStorage.removeItem("persist:root");
        history.push('/');
        return false
    } else {
        return true
    }
}