import { history } from "../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastSuccess, toastError } from "../../../components/commons/toast";

import API from "../API";
import APIUpload from "../APIUpload";
import { handleResponse } from "../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_PROMO_PENDING = "GET_PROMO_PENDING";
export const GET_PROMO_SUCCESS = "GET_PROMO_SUCCESS";
export const GET_PROMO_ERROR = "GET_PROMO_ERROR";
export const POST_PROMO_PENDING = "POST_PROMO_PENDING";
export const POST_PROMO_SUCCESS = "POST_PROMO_SUCCESS";
export const POST_PROMO_ERROR = "POST_PROMO_ERROR";
export const PUT_PROMO_PENDING = "PUT_PROMO_PENDING";
export const PUT_PROMO_SUCCESS = "PUT_PROMO_SUCCESS";
export const PUT_PROMO_ERROR = "PUT_PROMO_ERROR";
export const DELETE_PROMO_PENDING = "DELETE_PROMO_PENDING";
export const DELETE_PROMO_SUCCESS = "DELETE_PROMO_SUCCESS";
export const DELETE_PROMO_ERROR = "DELETE_PROMO_ERROR";

// URL: URL_{URL}
const PROMO_URL = `v1/master_data/promo`;
const UPLOAD_URL = `v1/file/promo/upload`;

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_PROMO_PENDING));
  API.get(PROMO_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_PROMO_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_PROMO_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        toastError(err?.response?.data?.message);
      }
    });
};

const post = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(POST_PROMO_PENDING));
  API.post(PROMO_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_PROMO_SUCCESS, res));
      toastSuccess("Berhasil Tambah Data Harga Korporat");
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_PROMO_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const put = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_PROMO_PENDING));
  API.put(PROMO_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_PROMO_SUCCESS, res));
      toastSuccess("Berhasil Ubah Data Harga Korporat");
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_PROMO_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const del = (param, callback, name) => (dispatch) => {
  dispatch(actionPending(DELETE_PROMO_PENDING));
  API.delete(PROMO_URL + `?id=${param}`)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_PROMO_SUCCESS, res));
      toastSuccess(`Promo telah dihapus`);
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_PROMO_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const upload = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(POST_PROMO_PENDING));
  APIUpload.post(UPLOAD_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_PROMO_SUCCESS, res));
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_PROMO_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const promo = {
  get,
  post,
  put,
  del,
  upload,
};
export default promo;
