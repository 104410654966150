import { history } from "../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastSuccess, toastError } from "../../../components/commons/toast";

import API from "../API";
import { handleResponse } from "../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_BOOKING_PENDING = "GET_BOOKING_PENDING";
export const GET_BOOKING_SUCCESS = "GET_BOOKING_SUCCESS";
export const GET_BOOKING_ERROR = "GET_BOOKING_ERROR";
export const GET_REGULAR_PRICE_PENDING = "GET_REGULAR_PRICE_PENDING";
export const GET_REGULAR_PRICE_SUCCESS = "GET_REGULAR_PRICE_SUCCESS";
export const GET_REGULAR_PRICE_ERROR = "GET_REGULAR_PRICE_ERROR";
export const POST_BOOKING_PAYMENT_PENDING = "POST_BOOKING_PAYMENT_PENDING";
export const POST_BOOKING_PAYMENT_SUCCESS = "POST_BOOKING_PAYMENT_SUCCESS";
export const POST_BOOKING_PAYMENT_ERROR = "POST_BOOKING_PAYMENT_ERROR";
export const POST_BOOKING_REGULAR_PENDING = "POST_BOOKING_REGULAR_PENDING";
export const POST_BOOKING_REGULAR_SUCCESS = "POST_BOOKING_REGULAR_SUCCESS";
export const POST_BOOKING_REGULAR_ERROR = "POST_BOOKING_REGULAR_ERROR";
export const PUT_BOOKING_PENDING = "PUT_BOOKING_PENDING";
export const PUT_BOOKING_SUCCESS = "PUT_BOOKING_SUCCESS";
export const PUT_BOOKING_ERROR = "PUT_BOOKING_ERROR";
export const DELETE_BOOKING_PENDING = "DELETE_BOOKING_PENDING";
export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS";
export const DELETE_BOOKING_ERROR = "DELETE_BOOKING_ERROR";
export const FIND_AVAIL_PRICE_PENDING =
"FIND_AVAIL_PRICE_PENDING";
export const FIND_AVAIL_PRICE_SUCCESS =
"FIND_AVAIL_PRICE_SUCCESS";
export const FIND_AVAIL_PRICE_ERROR =
"FIND_AVAIL_PRICE_ERROR";

// URL: URL_{URL}
const BOOKING_URL = `v1/order`;
const BOOKING_REGULAR_URL = 'v2/order/regular/public'
const BOOKING_CANCEL_URL = `v1/order/corporate/cancel`;
const BOOKING_PAYMENT_URL = `v1/order/payment/request`;
const REGULAR_PRICE_URL = `v2/order/regular/calculate-price/public`;
const FIND_AVAILIBILTY_PRICE = `v2/order/regular/find-availability-price`;
const FIND_BOOKING_URL = `v1/order/find-booking/public`;
const EDIT_PASSENGER_URL = `v1/order/edit-passenger/public`;
const RESCHEDULE_URL = `v1/order/reschedule-booking/public`;

const lang = localStorage.getItem('joumpa_language')

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_BOOKING_PENDING));
  API.get(BOOKING_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_BOOKING_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_BOOKING_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        toastError(err?.response?.data?.message);
      }
    });
};

const post = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(POST_BOOKING_REGULAR_PENDING));
  API.post(BOOKING_REGULAR_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_BOOKING_REGULAR_SUCCESS, res));
      toastSuccess(lang === 'id' ? "Reservasi berhasil! Silahkan cek email anda dan lanjutkan pembayaran." : "Reservation Successful! Please check your email and proceed the payment.");
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_BOOKING_REGULAR_ERROR, err?.response));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const del = (param, callback, name) => (dispatch) => {
  dispatch(actionPending(DELETE_BOOKING_PENDING));
  API.post(BOOKING_CANCEL_URL + `?id=${param.id}`)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_BOOKING_SUCCESS, res));
      toastSuccess(`Reservasi telah dibatalkan`);
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_BOOKING_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const pay = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(POST_BOOKING_PAYMENT_PENDING));
  API.post(BOOKING_PAYMENT_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_BOOKING_PAYMENT_SUCCESS, res));
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_BOOKING_PAYMENT_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const get_price = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(GET_REGULAR_PRICE_PENDING));
  API.post(REGULAR_PRICE_URL, param)
  .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REGULAR_PRICE_SUCCESS, res));

      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REGULAR_PRICE_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      };
    });
};

const findAvailPrice = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(FIND_AVAIL_PRICE_PENDING));
  API.get(FIND_AVAILIBILTY_PRICE, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(FIND_AVAIL_PRICE_SUCCESS, res));
      if (resolve) {
        // let data = res.data.data.map((item, i) => ({
        //   ...item,
        //   no: i + 1 + (param?.page - 1) * param?.length,
        // }));
        let data = res.data.data;
        resolve({
          data: data,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(FIND_AVAIL_PRICE_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        };
      }
    });
};

const find_booking = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_BOOKING_PENDING));
  API.get(FIND_BOOKING_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_BOOKING_SUCCESS, res));
      if (resolve) {
        // let data = res.data.data.map((item, i) => ({
        //   ...item,
        //   no: i + 1 + (param?.page - 1) * param?.length,
        // }));
        let data = res.data.data;
        resolve({
          data: data,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_BOOKING_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        };
      }
    });
};

const editPassenger = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_BOOKING_PENDING));
  API.put(EDIT_PASSENGER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Berhasil Ubah Data Penumpang"
          : "Successfully Change Passenger Data"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_BOOKING_ERROR, err.response));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const rescheduleBooking = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_BOOKING_PENDING));
  API.put(RESCHEDULE_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Berhasil Ubah Schedule Booking"
          : "Change Booking Schedule Successful"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_BOOKING_ERROR, err.response));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const booking = {
  get,
  post,
  pay,
  get_price,
  findAvailPrice,
  find_booking,
  editPassenger,
  rescheduleBooking
};
export default booking;
