import { useEffect, useRef, useState } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import Card from "../../../components/commons/cards/card";
import CardHeader from "../../../components/commons/cards/cardHeader";
import CardBody from "../../../components/commons/cards/cardBody";
import CardFlush from "../../../components/commons/cards/cardFlush";
import Input from "../../../components/commons/forms/Input.js";
import Checkbox from "../../../components/commons/forms/Checkbox";
import Select2 from "../../../components/commons/forms/Select2";
import PagingLogoModal from "./modal/PagingLogoModal";
import { withTrans } from "../../../i18n/withTrans";

const PassengerReservation = ({ t, formik, dataCountries, button, add, toggle }) => {
  const [showPagingLogo, setShowPagingLogo] = useState(false);
  const imageRef = useRef();
  let isValid = formik?.values?.passenger_list?.length;

  useEffect (() => {
    if (formik?.values?.passenger_as_booker == 1) {
      if (formik?.values?.passenger_list?.length === 0) {
        formik?.setFieldValue("bookers_title", "");
        formik?.setFieldValue("bookers_name", "");
        formik?.setFieldValue("bookers_nationality", "");
        formik?.setFieldValue("bookers_email", "");
        formik?.setFieldValue("bookers_phone", "");
      } else {
        formik?.setFieldValue(
          "bookers_title",
          formik?.values?.passenger_list[0]?.title
        );
        formik?.setFieldValue(
          "bookers_name",
          formik?.values?.passenger_list[0]?.name
        );
        formik?.setFieldValue(
          "bookers_nationality",
          formik?.values?.passenger_list[0]
            ?.passenger_nationality
        );
        formik?.setFieldValue(
          "bookers_email",
          formik?.values?.passenger_list[0]?.email
        );
        formik?.setFieldValue(
          "bookers_phone",
          formik?.values?.passenger_list[0]?.phoneNumber
        );
        formik?.setFieldValue(
          "bookers_country_code",
          formik?.values?.passenger_list[0]?.countryCode
        );
        formik?.setFieldValue(
          "bookers_country_id",
          formik?.values?.passenger_list[0]?.countryId
        );
      }
    }
  }, [
    formik?.values?.passenger_as_booker,
    formik?.values?.passenger_list[0],
    formik?.values?.passenger_list?.length
  ]);

  const handleDetailPass = (index) => {
    if (toggle) {
      toggle(formik, index);
    }
  };

  const openFileDialogImage = () => {
    imageRef.current.click();
  };

  const handleChangeImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const image = e.target.files[0];
      const imageName = image.name;
      const file = new File([image], imageName);
      formik?.setFieldValue("paging_logo_url", URL.createObjectURL(image));
      formik?.setFieldValue("paging_logo", file);
      formik?.setFieldValue("imagePagingInfo", {
        title: imageName,
        subtitle:
          (Number(image.size) * 0.001).toFixed(2) + " KB",
      });
    }
  };

  return (
    <>
      <Row>
        <Col lg={9} md={12}>
          {/* <Card className="rounded">
            <CardHeader title={t("field.pagingInfo")} />
            <CardBody>
              <Input
                name="paging_name"
                errors={formik.errors}
                touched={formik.touched}
                value={formik?.values?.paging_name ?? ""}
                onChange={(e) => {
                  let value = e.target.value;
                  formik?.setFieldValue(`paging_name`, value);
                }}
                title={t("field.pagingName")}
                type="text"
              />
              
              <div className="d-flex align-items-center">
                <div className="form-label">
                  {t("field.pagingImage")}
                </div>
                {formik?.values?.paging_logo && (
                  <div className="d-flex align-items-center ml-auto">
                    <i className="material-icons-round text-primary"
                      style={{ fontSize: 20 }}
                      onClick={() => setShowPagingLogo(!showPagingLogo)}>
                        visibility
                    </i>
                    <div style={{ borderRight: "1px solid #A9A9A9", height: 20, margin: 5 }}></div>
                    <i className="material-icons-round text-danger"
                      style={{ fontSize: 19 }}
                      onClick={() => {
                        imageRef.current.value = "";
                        formik.setFieldValue("paging_logo", null);
                        formik.setFieldValue("paging_logo_url", null);
                        formik.setFieldValue("imagePagingInfo", {
                          title: t("field.uploadPagingInfo"),
                          subtitle: "File",
                        });
                      }}>
                        delete_outline
                    </i>
                  </div>
                )}
              </div>
              <DynamicButton
                titleLeft={formik?.values?.imagePagingInfo?.title}
                subtitleLeft={formik?.values?.imagePagingInfo?.subtitle}
                iconLeft="add_photo_alternate"
                toggle={openFileDialogImage}
                error={formik?.values?.imagePagingInfo?.message_error}
                marginButton="0 0 20px 0"
              />
              <input
                type="file"
                ref={imageRef}
                style={{ display: "none" }}
                onChange={handleChangeImage}
                accept="image/png, image/jpeg"
              />
              <Checkbox
                style={{ marginTop: "30px" }}
                name="show_joumpa_logo"
                checked={formik?.values?.show_joumpa_logo}
                onChange={(e) => {
                  if (formik?.values?.show_joumpa_logo) {
                    formik?.setFieldValue("show_joumpa_logo", null);
                  } else {
                    formik?.setFieldValue("show_joumpa_logo", ["1"]);
                  }
                }}
                value={1}
                label={t("field.includeLogoJoumpa")}
              />
            </CardBody>
          </Card> */}
          <Card className="rounded">
            <CardHeader title={t("field.passengerInfo")} />
            <CardBody>
              {formik.values.passenger_list?.map((item, index) => {
                return (
                  <CardFlush
                    list
                    label={item?.name}
                    key={index}
                    desc={
                      <div
                        className="d-flex justify-content-end clickable"
                        onClick={() => handleDetailPass(index)}
                      >
                        <span className="material-icons-outlined">
                          navigate_next
                        </span>
                      </div>
                    }
                    size={{ label: 10, desc: 2 }}
                  />
                );
              })}
              {formik?.values?.passenger_list?.length > 0 && (
                <div className="d-flex align-items-center mt-4">
                  <p
                    className="normal-title text-bold"
                    style={{ color: "#A5A5C0" }}
                  >
                    Total PAX
                  </p>
                  <div className="ml-auto">
                    <p
                      className="normal-title text-bold"
                    >
                      {`${
                        formik?.values?.passenger_list?.filter(
                          (item) => item?.title === "Mrs." || item?.title === "Mr." || item?.title === "Ms."
                        )?.length
                      } ${t("commons.adult")}, ${
                        formik?.values?.passenger_list?.filter(
                          (item) => item?.title === "Child"
                        )?.length
                      } ${t("commons.child")}, ${
                        formik?.values?.passenger_list?.filter(
                          (item) => item?.title === "Infant"
                        )?.length
                      } ${t("commons.infantLabel")}`}
                    </p>
                  </div>
                </div>
              )}
              <div
                className="d-flex justify-content-start mt-3"
                onClick={() => add(formik)}
              >
                <div className="left-icon float-left rounded-fill">
                  <span
                    className="material-icons clickable"
                    style={{ fontSize: 25, color: "#63ae5c" }}
                  >
                    person
                  </span>
                </div>
                <div
                  className="clickable normal-title text-extra-bold pt-3 pl-3"
                  style={{ color: "#63ae5c" }}
                >
                  {t("field.addPassenger")}
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="rounded">
            <CardHeader title={t("field.passengerNotes")} />
            <CardBody>
              <Input
                name="passenger_notes"
                type="text"
                errors={formik?.errors}
                touched={formik?.touched}
                {...formik?.getFieldProps("passenger_notes")}
                placeholder={`${t("commons.add")} ${t("field.note")}`}
              />
            </CardBody>
          </Card>
          <Card className="rounded">
            <CardHeader title={t("field.bookersInfo")} />
            <CardBody>
              {isValid ? (
                <div className="checkbox-wrapper">
                  <Checkbox
                    style={{ marginTop: "30px" }}
                    name="passenger_as_booker"
                    checked={formik?.values?.passenger_as_booker}
                    onChange={(e) => {
                      if (formik?.values?.passenger_as_booker) {
                        formik?.setFieldValue("passenger_as_booker", null);
                        formik?.setFieldValue("bookers_title", "");
                        formik?.setFieldValue("bookers_name", "");
                        formik?.setFieldValue("bookers_nationality", "");
                        formik?.setFieldValue("bookers_email", "");
                        formik?.setFieldValue("bookers_phone", "");
                      } else {
                        formik?.setFieldValue("passenger_as_booker", ["1"]);
                        formik?.setFieldValue(
                          "bookers_title",
                          formik?.values?.passenger_list[0]?.title
                        );
                        formik?.setFieldValue(
                          "bookers_name",
                          formik?.values?.passenger_list[0]?.name
                        );
                        formik?.setFieldValue(
                          "bookers_nationality",
                          formik?.values?.passenger_list[0]
                            ?.passenger_nationality
                        );
                        formik?.setFieldValue(
                          "bookers_email",
                          formik?.values?.passenger_list[0]?.email
                        );
                        formik?.setFieldValue(
                          "bookers_phone",
                          formik?.values?.passenger_list[0]?.phoneNumber
                        );
                        formik?.setFieldValue(
                          "bookers_country_code",
                          formik?.values?.passenger_list[0]?.countryCode
                        );
                        formik?.setFieldValue(
                          "bookers_country_id",
                          formik?.values?.passenger_list[0]?.countryId
                        );
                      }
                    }}
                    value={1}
                    label={t("field.bookerSameAsPassenger")}
                  />
                </div>
              ) : (
                <div></div>
              )}
              <div className="row">
                <div className="col-md-4">
                  <Select2
                    name="bookers_title"
                    type="text"
                    errors={formik.errors}
                    touched={formik.touched}
                    options={[
                      { label: t("commons.mr"), value: "Mr." },
                      { label: t("commons.mrs"), value: "Mrs." },
                      { label: t("commons.ms"), value: "Ms." },
                    ]}
                    {...formik.getFieldProps("bookers_title")}
                    value={isValid ? formik?.values?.bookers_title : ""}
                    onChange={(name, value) => {
                      formik?.setFieldValue(`bookers_title`, value);
                    }}
                    onBlur={formik?.setFieldTouched}
                    title={t("field.title")}
                  />
                </div>
                <div className="col-md-8">
                  <Input
                    name="bookers_name"
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik?.values?.bookers_name ?? ""}
                    onChange={(e) => {
                      let value = e.target.value;
                      formik?.setFieldValue("bookers_name", value);
                    }}
                    title={t("field.name")}
                    type="text"
                  />
                </div>
              </div>
              {/* <Input
                name="bookers_nationality"
                errors={formik.errors}
                touched={formik.touched}
                value={formik?.values?.bookers_nationality ?? ""}
                onChange={(e) => {
                  let value = e.target.value;
                  formik?.setFieldValue(`bookers_nationality`, value);
                }}
                title={t("field.nationality")}
                type="text"
              /> */}
              <Select2
                clearable={!!formik?.values?.bookers_nationality}
                name="bookers_nationality"
                title={t("field.nationality")}
                options={dataCountries}
                value={formik?.values?.bookers_nationality}
                onChange={(name, value) => {
                  formik?.setFieldValue(
                    "bookers_nationality", 
                    value
                  );
                }}
                errors={formik.errors}
                touched={formik.touched}
                onBlur={formik.setFieldTouched}
              />
              <Input
                name="bookers_email"
                type="email"
                errors={formik.errors}
                touched={formik.touched}
                value={isValid ? formik?.values?.bookers_email : ""}
                onChange={(e) => {
                  let value = e.target.value;
                  formik?.setFieldValue("bookers_email", value);
                }}
                title={t("field.email")}
              />
              <div className="form-group">
                <div className="form-label">{t("field.phone")}</div>
                <div className="input-wrapper">
                  <PhoneInput
                    enableTerritories={true}
                    countryCodeEditable={false}
                    placeholder={t("field.placeHolderPhoneNumber")}
                    country={formik?.values?.bookers_country_id ?? "id"}
                    inputStyle={{ width: '100%' }}
                    enableSearch={true}
                    value={formik?.values?.bookers_phone}
                    onChange={(phone, country) => {
                      formik?.setFieldValue("bookers_country_code", country?.dialCode);
                      formik?.setFieldValue("bookers_phone", phone);
                      formik?.setFieldValue("bookers_country_id", country?.countryCode);
                      // formik?.setFieldValue("passenger_nationality", country?.name);
                    }}
                  />
                  {/* <NumberFormat
                    allowNegative={false}
                    className="form-control form-input"
                    name="bookers_phone"
                    type="tel"
                    {...formik.getFieldProps("bookers_phone")}
                    style={{ padding: "25px" }}
                    placeholder={`${t("commons.input")} ${t("field.phone")}`}
                  /> */}
                </div>
              </div>
            </CardBody>
          </Card>
          {button()}
        </Col>
      </Row>
      <PagingLogoModal
        show={showPagingLogo}
        toggle={() => setShowPagingLogo(!showPagingLogo)}
        image={formik?.values?.paging_logo_url}
      />
    </>
  );
};
const mapStateToProps = ({ corporate }) => {
  return { corporate };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(PassengerReservation)
);
