import { Divider } from "@material-ui/core";

import "./card.scss";

const CardFlush = ({
  title,
  desc,
  leftIcon,
  label,
  sideMenu,
  list,
  descList,
  status,
  iconColor,
  color,
  multipleList,
  button,
  dropdownList,
  toggle,
  mapTooltip,
  last,
  withoutDivider,
  reverse,
  size,
  pills,
  statusInfo,
  addtService,
  descBold,
  isLounge = false,
  isTransport = false,
  nameLounge,
  nameTransport,
}) => {
  return (
    <>
      {sideMenu && (
        <div className="card-flush" style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              padding: "18px 30px 15px",
              alignItems: "center",
            }}
          >
            {leftIcon && (
              <span
                className="material-icons-round"
                style={{
                  fontSize: 35,
                  marginRight: 25,
                  marginTop: "-10px",
                  color: iconColor ?? "#63ae5c",
                }}
              >
                {leftIcon}
              </span>
            )}
            <div style={{ wordWrap: "anywhere" }}>
              <p
                className="card-flush-desc"
                style={{ fontSize: 14, marginBottom: 8 }}
              >
                {title}
              </p>
              <p
                className="card-flush-title"
                style={{ fontSize: 18, marginBottom: 10 }}
              >
                {desc}
              </p>
            </div>
          </div>
          {!last && <Divider orientation="horizontal" />}
          {statusInfo && (
            <div className="flush-status-info">
              <div className="normal-title">{statusInfo}</div>
            </div>
          )}
        </div>
      )}

      {mapTooltip && (
        <div>
          <div
            style={{
              width: "100%",
              padding: "10px 15px",
              alignItems: "center",
              borderBottom: last ? "" : "solid 1px #5A5A5A1A",
              color: "#242726",
              wordWrap: "break-word",
              display: "block",
            }}
          >
            <p
              className="card-flush-desc"
              style={{ fontSize: 14, marginBottom: 10 }}
            >
              {title}
            </p>
            <p
              className="text-bold"
              style={{ fontSize: 14, marginTop: 0, marginBottom: 10 }}
            >
              {desc}
            </p>
          </div>
        </div>
      )}

      {dropdownList && (
        <>
          <div
            className="mx-3 py-3"
            style={{ cursor: "pointer" }}
            onClick={toggle}
          >
            <div className="text-extra-bold">
              {label}
              <span className="material-icons mr-auto float-right right-icon">
                expand_more
              </span>
            </div>
          </div>
        </>
      )}

      {list && (
        <>
          <div
            className={`row ${label && desc ? "py-3" : ""} `}
            style={{ fontSize: 14, padding: "15px" }}
          >
            {button ? (
              <>
                <div
                  className={`${size?.label ? "col-md-" + size?.label : "col-md-4"
                    } ${reverse ? "" : "text-extra-bold"}`}
                >
                  {label}
                </div>
                <div
                  className={`${size?.desc ? "col-md-" + size?.desc : "col-md-7"
                    } ${reverse ? "text-extra-bold" : "text-medium"}`}
                >
                  {button}
                </div>
              </>
            ) : (
              <>
                <div
                  className={`${size?.label ? "col-md-" + size?.label : "col-md-4"
                    } ${reverse ? "" : "text-extra-bold"}`}
                  style={{ color: "#000" }}
                >
                  {label}
                </div>
                {desc && (
                  <div
                    className={`${size?.desc ? "col-md-" + size?.desc : "col-md-7"
                      } ${reverse ? "text-extra-bold" : ""} ${descBold ? "text-extra-bold" : ""
                      }`}
                  >
                    {desc}
                  </div>
                )}
              </>
            )}
          </div>
          {!last && !withoutDivider && <Divider orientation="horizontal" />}
        </>
      )}
      {descList && (
        <>
          <div
            className={`${label && desc ? "py-3" : ""} `}
            style={{ fontSize: 14, padding: "15px" }}
          >
            {button ? (
              <>
                <div
                  className={`${
                    size?.label ? "col-md-" + size?.label : "col-md-4"
                  } ${reverse ? "" : "text-extra-bold"}`}
                >
                  {label}
                </div>
                <div
                  className={`${
                    size?.desc ? "col-md-" + size?.desc : "col-md-12"
                  } ${reverse ? "text-extra-bold" : "text-medium"}`}
                >
                  {button}
                </div>
              </>
            ) : (
              <>
                <div
                  className={`${
                    size?.label ? "col-md-" + size?.label : "col-md-4"
                  } ${reverse ? "" : "text-extra-bold"}`}
                  style={{ color: "#000" }}
                >
                  {label}
                </div>
                <br />
                {desc && (
                  <div
                    className={`${
                      size?.desc ? "col-md-" + size?.desc : "col-md-12"
                    } ${reverse ? "text-extra-bold" : ""} ${
                      descBold ? "text-extra-bold" : ""
                    }`}
                  >
                    {desc}
                  </div>
                )}
              </>
            )}
          </div>
          {!last && <Divider orientation="horizontal" />}
        </>
      )}
      {multipleList && (
        <>
          <div
            className={`mb-1 ${size?.label ? "col-md-" + size?.label : "col-md-11"
              } ${reverse ? "" : "text-extra-bold"}`}
          >
            {label}
          </div>
          <div className="justify-content-left col-md-11">
            {multipleList.map((item, index) => (
              <label
                className={`rounded-label mt-1 mr-2 justify-content-left ${size?.label ? "col-md-" + size?.label : "col-md-5"
                  }`}
              >
                {item.name}
              </label>
            ))}
          </div>
        </>
      )}
      {addtService && (
        <div className="row justify-content-center py-3">
          <div className="col-md-5 border-right">
            <div
              className="left-icon float-left rounded-fill"
              style={{
                border: "1px solid #A5A5C0",
                backgroundColor: isLounge ? "#ecf5eb" : "#fff",
              }}
            >
              <span
                className="material-icons"
                style={{
                  fontSize: 25,
                  color: isLounge ? "#63ae5c" : "#A5A5C0",
                }}
              >
                chair
              </span>
            </div>
            <div className="text-bold">Lounge</div>
            <div className="normal-title">{nameLounge}</div>
          </div>
          <div className="px-3" />
          <div className="col-md-5">
            <div
              className="left-icon float-left rounded-fill"
              style={{
                border: "1px solid #A5A5C0",
                backgroundColor: isTransport ? "#ecf5eb" : "#fff",
              }}
            >
              <span
                className="material-icons"
                style={{
                  fontSize: 25,
                  color: isTransport ? "#63ae5c" : "#A5A5C0",
                }}
              >
                local_taxi
              </span>
            </div>
            <div className="text-bold">Transportasi</div>
            <div className="normal-title">{nameTransport}</div>
          </div>
        </div>
      )}
      {status && (
        <>
          <div className={`wrapper-detail-card ${(color = color)}`}>
            <div
              className="row justify-content-center py-3"
              style={{ fontSize: 16 }}
            >
              <div className="col-1 text-extra-bold">
                <span className="material-icons left-icon">info</span>
              </div>
              <div className="col-10 text-bold">{desc}</div>
            </div>
          </div>
        </>
      )}
      {pills && (
        <>
          <div className="ml-3 my-2">
            <div className="pill mb-2 py-2 px-3">{desc}</div>
          </div>
        </>
      )}
    </>
  );
};
export default CardFlush;
