import "./card.scss";

const card = ({ className, children, shadow, rounded, style }) => {

  return (
    <div className={`card ${className} ${rounded ? 'rounded' : ''} ${shadow ? 'card-shadow' : ''}`} style={style}>
      {children}
    </div>
  );

};

export default card;
