import { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import Card from "../../../components/commons/cards/card";
import CardHeader from "../../../components/commons/cards/cardHeader";
import CardBody from "../../../components/commons/cards/cardBody";
import Select2 from "../../../components/commons/forms/Select2";
import Input from "../../../components/commons/forms/Input";
import DynamicForm from "../../../components/commons/forms/DynamicForm";
import { connect, useDispatch } from "react-redux";
import { withTrans } from "../../../i18n/withTrans";

import AirportService from "../../../store/actions/master_data/airport";

const AdtServiceReservation = ({ formik, button, t }) => {
  const dispatch = useDispatch();
  const [dataLounge, setDataLounge] = useState([]);
  const [dataTransports, setDataTransports] = useState([]);
  const [dataCars, setDataCars] = useState([]);
  const [showTransports, setShowTransports] = useState(false);
  const [showLounge, setShowLounge] = useState(false);

  function toggleTransportation() {
    setShowTransports((showTransports) => !showTransports);
  }

  function toggleLounge() {
    setShowLounge(showLounge => !showLounge);
  }

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 999,
        id:
          formik?.values?.flight_type === 0
            ? formik?.values?.airport_id_to
            : formik?.values?.flight_type === 2
            ? formik?.values?.airport_id_transfer
            : formik?.values?.airport_id_from,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      if (
        formik?.values.car_type !== "" &&
        formik?.values.orderExtras.transport.id !== ""
      ) {
        const idTransport = formik?.values.orderExtras.transport.id;
        const index = res?.data[0]?.transports
          ?.map(function (e) {
            return e.id;
          })
          .indexOf(idTransport);
        const cars = res?.data[0]?.transports[index]?.prices.map((item) => ({
          label: (
            <>
              <div className="d-flex justify-content-between">
                <div className="normal-title">{item.car_name}</div>
                <div className="normal-title text-extra-bold">
                  {`${item?.capacity} ${t("field.person")}`}
                </div>
                <div className="normal-title text-extra-bold">
                  {item?.price == null
                    ? "Rp 0"
                    : "Rp " + numberWithDots(item.price)}
                </div>
              </div>
            </>
          ),
          value: item.id,
          name: item.car_name,
          capacity: item.capacity,
          price: item.price,
        }));
        setDataCars(cars);
      }
      if (formik?.values?.flight_type === 0) {
        if (formik?.values?.airport_uniform_from !== "Indonesia") {
          setDataLounge(
            res?.data[0]?.lounges?.filter((item) => item?.route_type === "international")
          );
        } else {
          setDataLounge(
            res?.data[0]?.lounges?.filter((item) => item?.route_type === "domestic")
          );
        }
      } else if (formik?.values?.flight_type === 1) {
        if (formik?.values?.airport_uniform_to !== "Indonesia") {
          setDataLounge(
            res?.data[0]?.lounges?.filter((item) => item?.route_type === "international")
          );
        } else {
          setDataLounge(
            res?.data[0]?.lounges?.filter((item) => item?.route_type === "domestic")
          );
        }
      } else if (formik?.values?.flight_type === 2) {
        if (
          formik?.values?.airport_uniform_from !== "Indonesia" ||
          formik?.values?.airport_uniform_to !== "Indonesia"
        ) {
          setDataLounge(
            res?.data[0]?.lounges?.filter((item) => item?.route_type === "international")
          );
        } else {
          setDataLounge(
            res?.data[0]?.lounges?.filter((item) => item?.route_type === "domestic")
          );
        }
      }
      setDataTransports(res?.data[0]?.transports);
    });
  }, []);

  useEffect(() => {
    if (formik?.values?.lounge) {
      const index = lounge_list
        ?.map(function (e) {
          return e.value;
        })
        .indexOf(formik?.values?.lounge);

      if (index > -1) {
        formik.setFieldValue(
          "total_lounge_adult",
          formik?.values?.lounge_adult_number * lounge_list[index]?.price_adult
        );
        formik.setFieldValue(
          "total_lounge_child",
          formik?.values?.lounge_child_number * lounge_list[index]?.price_child
        );
        formik.setFieldValue(
          "total_lounge_infant",
          formik?.values?.lounge_infant_number * lounge_list[index]?.price_infant
        );
        formik.setFieldValue(
          "total_lounges",
          formik?.values?.total_lounge_adult +
            formik?.values?.total_lounge_child +
            formik?.values?.total_lounge_infant
        );
      }
    }
  }, [
    formik?.values?.lounge_adult_number,
    formik?.values?.lounge_child_number,
    formik?.values?.lounge_infant_number,
    formik?.values?.total_lounge_adult,
    formik?.values?.total_lounge_child,
    formik?.values?.total_lounge_infant,
    formik?.values?.lounge
  ]);

  const numberWithDots = (x) => {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  // const lounge_list = dataLounge.map((item) => ({
  //   label: (
  //     <>
  //       <div className="d-flex justify-content-between">
  //         <div className="normal-title">{item.name}</div>
  //         <div className="normal-title text-extra-bold">
  //           {item?.price == null ? "Rp 0" : "Rp " + numberWithDots(item.price)}
  //         </div>
  //       </div>
  //     </>
  //   ),
  //   value: item.id,
  //   name: item.name,
  //   price: item.price,
  // }));

  const lounge_list = dataLounge.map((item) => ({
    label: (
      <>
        <div className="normal-title">{item.name}</div>
        <div className="d-flex justify-content-between">
          <div className="col-md-4">
            <div className="d-flex justify-content-between normal-title">
              {t("commons.adult")}
              <div className="text-primary">
                {item?.price_adult
                  ? "Rp " + numberWithDots(item?.price_adult)
                  : "Rp 0"}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex justify-content-between normal-title">
              {t("commons.child")}
              <div className="text-primary">
                {item?.price_child
                  ? "Rp " + numberWithDots(item?.price_child)
                  : "Rp 0"}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex justify-content-between normal-title">
              {t("commons.infantLabel")}
              <div className="text-primary">
                {item?.price_infant
                  ? "Rp " + numberWithDots(item?.price_infant)
                  : "Rp 0"}
              </div>
            </div>
          </div>
        </div>
      </>
    ),
    value: item.id,
    name: item.name,
    price_adult: item.price_adult,
    price_child: item.price_child,
    price_infant: item.price_infant,
  }));

  const regionList = [];
  const region = dataTransports.map((item) => {
    regionList.push(
      item.region_list.split(",").map((el) => ({
        label: el,
        value: el,
      }))
    );
  });
  const region_list = regionList.flat();

  return (
    <Row>
      <Col lg={9} md={12}>
        <Card className="rounded">
          <CardHeader title={t("field.orderExtras")} />
          <CardBody>
            <div className="wrapper-detail-card">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 15,
                  fontSize: 14,
                }}
              >
                <div className="text-extra-bold">{t("field.lounge")}</div>
                <div onClick={toggleLounge} style={{ cursor: "pointer" }}>
                  <span className="material-icons-round left-icon">
                    expand_more
                  </span>
                </div>
              </div>
              {showLounge && (
                <>
                  <div className="p-3 pt-0">
                    <Select2
                      clearable={!!formik.values?.lounge}
                      name="lounge"
                      title={t("field.lounge")}
                      errors={formik.errors}
                      options={lounge_list}
                      touched={formik.touched}
                      value={formik.values?.lounge}
                      onChange={(name, value) => {
                        formik.setFieldValue("lounge", value);
                        const index = lounge_list
                          ?.map(function (e) {
                            return e.value;
                          })
                          .indexOf(value);
                        const lounge = {
                          // price: value ? dataLounge[index].price : "",
                          name: value ? lounge_list[index]?.name : "",
                          id: value ? lounge_list[index]?.id : "",
                        };
                        formik.setFieldValue("orderExtras.lounge", lounge);
                        formik.setFieldValue("lounge_price_adult", lounge_list[index]?.price_adult);
                        formik.setFieldValue("lounge_price_child", lounge_list[index]?.price_child);
                        formik.setFieldValue("lounge_price_infant", lounge_list[index]?.price_infant);
                      }}
                      onBlur={formik.setFieldTouched}
                    />
                    <Row style={{ marginTop: 20 }}>
                      <Col md={4} sm={4} xs={4} style={{ paddingRight: "0px" }}>
                        <div className="form-group" style={{ marginBottom: 0 }}>
                          <label className="form-label">
                            {t("commons.adult")}
                          </label>
                        </div>
                      </Col>
                      <Col md={4} sm={4} xs={4} style={{ paddingLeft: "0px" }}>
                        <div className="form-group" style={{ marginBottom: 0 }}>
                          <label className="form-label">
                            {t("commons.child")}
                          </label>
                        </div>
                      </Col>
                      <Col md={4} sm={4} xs={4} style={{ paddingLeft: "0px" }}>
                        <div className="form-group" style={{ marginBottom: 0 }}>
                          <label className="form-label">
                            {t("commons.infantLabel")}
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <DynamicForm
                      input="lounge_adult_number"
                      nameCenter="lounge_child_number"
                      name="lounge_infant_number"
                      placeholderLeft={t("commons.adult")}
                      placeholderCenter={t("commons.child")}
                      placeholder={t("commons.infantLabel")}
                      typeLeft="number"
                      type="number"
                      typeCenter="number"
                      minLeft="0"
                      minCenter="0"
                      min="0"
                      formik={formik}
                      size={{ title: 4, center: 4, right: 4 }}
                    />
                  </div>
                  <div className="d-flex justify-content-between border-top p-3">
                    <div className="normal-title text-extra-bold">Total</div>
                    <div className="normal-title text-extra-bold">
                      Rp {numberWithDots(formik?.values?.total_lounges)}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between border-top p-3">
                    <div className="normal-title text-extra-bold">
                      {t("commons.adult")}
                    </div>
                    <div className="normal-title text-extra-bold">
                      Rp {numberWithDots(formik?.values?.total_lounge_adult)}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between border-top p-3">
                    <div className="normal-title text-extra-bold">
                      {t("commons.child")}
                    </div>
                    <div className="normal-title text-extra-bold">
                      Rp {numberWithDots(formik?.values?.total_lounge_child)}
                    </div>
                  </div>
                  <div className="d-flex card-body justify-content-between border-top p-3">
                    <div className="normal-title text-extra-bold">
                      {t("commons.infantLabel")}
                    </div>
                    <div className="normal-title text-extra-bold">
                      Rp {numberWithDots(formik?.values?.total_lounge_infant)}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="wrapper-detail-card">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 15,
                  fontSize: 14,
                }}
              >
                <div className="text-extra-bold">
                  {t("field.transportation")}
                </div>
                <div
                  onClick={toggleTransportation}
                  style={{ cursor: "pointer" }}
                >
                  <span className="material-icons-round left-icon">
                    expand_more
                  </span>
                </div>
              </div>
              {showTransports && (
                <div style={{ padding: 15 }}>
                  <Select2
                    clearable={!!formik.values?.region && !formik.values?.car_type}
                    name="region"
                    errors={formik.errors}
                    title={t("field.region")}
                    touched={formik.touched}
                    options={region_list}
                    value={formik.values?.region}
                    onChange={(name, value) => {
                      const index = dataTransports?.findIndex((item) => {
                        return (
                          item?.region_list?.split(',')?.some((region) => {
                            return (
                              region === value
                            );
                          })
                        );
                      });
                      if (index > -1) {
                        const transport = {
                          name: value ? region_list[index].label : "",
                          id: value ? dataTransports[index].id : "",
                        };
                        const cars = value
                          ? dataTransports[index].prices.map((item) => ({
                            label: (
                              <>
                                <div className="d-flex justify-content-between">
                                  <div className="normal-title">{item.car_name}</div>
                                  <div className="normal-title text-extra-bold">
                                      {`${item?.capacity} ${t("field.person")}`}
                                  </div>
                                  <div className="normal-title text-extra-bold">
                                    {item?.price == null
                                      ? "Rp 0"
                                      : "Rp " + numberWithDots(item.price)}
                                  </div>
                                </div>
                              </>
                            ),
                            value: item.id,
                            name: item.car_name,
                            capacity: item.capacity,
                            price: item.price,
                          }))
                          : "";
                        setDataCars(cars);
                        formik.setFieldValue("orderExtras.transport", transport);
                      }
                      formik.setFieldValue("car_type", "")
                      formik.setFieldValue("region", value);

                    }}
                    onBlur={formik.setFieldTouched}
                  />
                    <Select2
                      clearable={!!formik.values?.car_type}
                      name="car_type"
                      errors={formik.errors}
                      title={t("field.carType")}
                      touched={formik.touched}
                      options={dataCars}
                      value={formik.values?.car_type}
                      onChange={(name, value) => {
                        const index = dataCars
                          ?.map(function (e) {
                            return e.value;
                          })
                          .indexOf(value);
                        const car = {
                          name: value ? dataCars[index].name : "",
                          price: value ? dataCars[index].price : "",
                          id: value ? dataCars[index].value : "",
                          capacity: value ? dataCars[index].capacity : "",
                        };
                        formik.setFieldValue("orderExtras.car", car);
                        formik.setFieldValue("car_type", value);
                      }}
                      onBlur={formik.setFieldTouched}
                    />
                    <Input
                        title={t("field.totalUnit")}
                        name="{total_unit}"
                        type="number"
                        min={0}
                        errors={formik.errors}
                        touched={formik.touched}
                        {...formik.getFieldProps("total_unit")}
                    />
                </div>
              )}
            </div>

            {/* <Select2
              clearable={!!formik.values.lounge}
              name="lounge"
              title={t("field.lounge")}
              errors={formik.errors}
              options={lounge_list}
              touched={formik.touched}
              value={formik.values?.lounge}
              onChange={(name, value) => {
                formik.setFieldValue("lounge", value);
                const index = dataLounge
                  ?.map(function (e) {
                    return e.id;
                  })
                  .indexOf(value);
                const lounge = {
                  name: value ? dataLounge[index]?.name : "",
                  price: value ? dataLounge[index]?.price : "",
                  id: value ? dataLounge[index]?.id : "",
                };
                formik.setFieldValue("orderExtras.lounge", lounge);
              }}
              onBlur={formik.setFieldTouched}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: 15,
                paddingBottom: 15,
                fontSize: 14,
              }}
            >
              <div className="text-extra-bold">{t("field.transportation")}</div>
              <div onClick={toggleTransportation} style={{ cursor: "pointer" }}>
                <span className="material-icons-round left-icon">
                  expand_more
                </span>
              </div>
            </div>
            {showTransports && (
              <div>
                <Select2
                  clearable={!!formik.values.region && !formik.values.car_type}
                  name="region"
                  errors={formik.errors}
                  title={t("field.region")}
                  touched={formik.touched}
                  options={region_list}
                  value={formik.values?.region}
                  onChange={(name, value) => {
                    formik.setFieldValue("car_type", "");
                    const index = dataTransports
                      ?.map(function (e) {
                        return e.id;
                      })
                      .indexOf(value);
                    const transport = {
                      name: value ? region_list[index]?.label : "",
                      id: value ? dataTransports[index]?.id : "",
                    };
                    formik.setFieldValue("orderExtras.transport", transport);
                    formik.setFieldValue("region", value);

                    const cars = value
                      ? dataTransports[index]?.prices.map((item) => ({
                          label: (
                            <>
                              <div className="d-flex justify-content-between">
                                <div className="normal-title">
                                  {item.car_name}
                                </div>
                                <div className="normal-title text-extra-bold">
                                  {`${item?.capacity} ${t("field.person")}`}
                                </div>
                                <div className="normal-title text-extra-bold">
                                  {item?.price == null
                                    ? "Rp 0"
                                    : "Rp " + numberWithDots(item.price)}
                                </div>
                              </div>
                            </>
                          ),
                          value: item.id,
                          name: item.car_name,
                          capacity: item.capacity,
                          price: item.price,
                        }))
                      : "";
                    setDataCars(cars);
                  }}
                  onBlur={formik.setFieldTouched}
                />
                <Select2
                  clearable={!!formik.values.car_type}
                  name="car_type"
                  options={dataCars}
                  errors={formik.errors}
                  touched={formik.touched}
                  value={formik.values?.car_type}
                  onChange={(name, value) => {
                    const index = dataCars
                      ?.map(function (e) {
                        return e.value;
                      })
                      .indexOf(value);
                    const car = {
                      name: value ? dataCars[index]?.name : "",
                      price: value ? dataCars[index]?.price : "",
                      capacity: value ? dataCars[index].capacity : "",
                      id: value ? dataCars[index]?.value : "",
                    };
                    formik.setFieldValue("orderExtras.car", car);
                    formik.setFieldValue("car_type", value);
                  }}
                  title={t("field.carType")}
                  onBlur={formik.setFieldTouched}
                />
                <Input
                  title={t("field.totalUnit")}
                  name="{total_unit}"
                  errors={formik.errors}
                  touched={formik.touched}
                  {...formik.getFieldProps("total_unit")}
                />
              </div>
            )} */}
          </CardBody>
        </Card>
        {button()}
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ airport }) => {
  return { airport };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(AdtServiceReservation)
);
