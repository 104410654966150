export const locations = [
  {
    id: "76059219-b027-40c6-8499-0a0ced5ff82a",
    code: "NBX",
    name: "Douw Atuture Airport",
    city: "Kab. Nabire",
    uniform: "Indonesia",
    email: "reservation.nbx@gapura.id",
    phone: '08115999735',
    longitude: 135.49651448053575,
    latitude: -3.3676290213575344,
    timezone: 2,
  },
  {
    id: "fe2df888-1494-42b4-bfba-bbb8c58c7040",
    code: "MKW",
    name: "Rendani Airport",
    city: "Kab. Manokwari",
    uniform: "Indonesia",
    email: "reservation.mkw@gapura.id",
    phone: '08115998913',
    longitude: 134.02913776948196,
    latitude: -0.8115846011662372,
    timezone: 2,
  },
  {
    id: "d9514e20-994b-4fc4-830a-1b6cb8e01afc",
    code: "TKG",
    name: "Raden Inten II International Airport",
    city: "Kota Bandar Lampung",
    uniform: "Indonesia",
    email: "reservation.tkg@gapura.id",
    phone: '08115998930',
    longitude: 105.17800034051892,
    latitude: -5.091134629760852,
    timezone: 0,
  },
  {
    id: "09939448-c5b6-4b1a-ac39-4ab4c8ecfe52",
    code: "BTJ",
    name: "Sultan Iskandar Muda International Airport",
    city: "Kota Banda Aceh",
    uniform: "Indonesia",
    email: "reservation.btj@gapura.id",
    phone: '08115985311',
    longitude: 106.71186088847948,
    latitude: -6.075552122358112,
    timezone: 0,
  },
  {
    id: "302f902c-7477-49f9-83a2-37b2e1f7c5b8",
    code: "LBJ",
    name: "Komodo International Airport",
    city: "Kota Labuan Bajo",
    uniform: "Indonesia",
    email: "reservation.lbj@gapura.id",
    phone: '08115999764',
    longitude: 119.88656472370519,
    latitude: -8.487532143144978,
    timezone: 1,
  },
  {
    id: "7293bebe-c670-42a3-836e-14c52a247419",
    code: "DJB",
    name: "Sultan Thaha Saifuddin Airport",
    city: "Kota Jambi",
    uniform: "Indonesia",
    email: "reservation.djb@gapura.id",
    phone: '08115999761',
    longitude: 103.64309205168398,
    latitude: -1.6325801535345712,
    timezone: 0,
  },
  {
    id: "c57f4c31-87d4-406c-b998-d00a0ba3b28c",
    code: "MKQ",
    name: "Mopah Airport",
    city: "Kab. Merauke",
    uniform: "Indonesia",
    email: "reservation.mkq@gapura.id",
    phone: '08115998935',
    longitude: 140.41625540573992,
    latitude: -8.520073130786075,
    timezone: 2,
  },
  {
    id: "9b57e48a-7207-439b-86ea-9df0dca1f5f8",
    code: "PKU",
    name: "Sultan Syarif Kasim II Airport",
    city: "Kota Pekan Baru",
    uniform: "Indonesia",
    email: "reservation.pku@gapura.id",
    phone: '08115999275',
    longitude: 101.44825577865403,
    latitude: 0.4652832394611845,
    timezone: 0,
  },
  {
    id: "6e2912a0-9f95-4939-93dd-2b9f610b1c39",
    code: "DTB",
    name: "Silangit Airport",
    city: "Kab. Tapanuli Utara",
    uniform: "Indonesia",
    email: "reservation.dtb@gapura.id",
    phone: '08115999628',
    longitude: 98.98794407014192,
    latitude: 2.263233339123981,
    timezone: 0,
  },
  {
    id: "c813c93c-ab90-45ed-a5b9-dc2408df2125",
    code: "MJU",
    name: "Tampa Padang Airport",
    city: "Kab. Mamuju",
    uniform: "Indonesia",
    email: "reservation.mju@gapura.id",
    phone: '08115999638',
    longitude: 119.06733609854255,
    latitude: -2.1408483143930606,
    timezone: 1,
  },
  {
    id: "2ecbe51b-a5c4-4bd8-a0a6-bdb3eb972765",
    code: "DJJ",
    name: "Sentani Airport",
    city: "Kab. Jayapura",
    uniform: "Indonesia",
    email: "reservation.djj@gapura.id",
    phone: '08115999437',
    longitude: 140.51463081739587,
    latitude: -2.5734878887321178,
    timezone: 2,
  },
  {
    id: "2f789ee7-df3f-4d59-b423-45fb7d8938a5",
    code: "GNS",
    name: "Binaka Gunung Sitoli Nias Airport",
    city: "Kota Gunung Sitoli",
    uniform: "Indonesia",
    email: "reservation.gns@gapura.id",
    phone: '08115985310',
    longitude: 97.70475650934392,
    latitude: 1.165132551341614,
    timezone: 0,
  },
  {
    id: "8efae01c-1804-4056-a4cd-98e968c75ff0",
    code: "PNK",
    name: "Supadio International Airport",
    city: "Kota Pontianak",
    uniform: "Indonesia",
    email: "reservation.pnk@gapura.id",
    phone: '08115999462',
    longitude: 109.40441852283878,
    latitude: -0.14659182542987578,
    timezone: 0,
  },
  {
    id: "0d0c0f4e-4e36-412e-ba61-20000d5857f6",
    code: "PLM ",
    name: "Sultan Mahmud Badaruddin II International Airport",
    city: "Kota Palembang",
    uniform: "Indonesia",
    email: "reservation.plm@gapura.id",
    phone: '08115999256',
    longitude: 104.70466855169464,
    latitude: -2.8945956092904757,
    timezone: 0,
  },
  {
    id: "99ca6494-ca70-4485-8cd8-8ccc669d7ba3",
    code: "KNO ",
    name: "Kualanamu International Airport",
    city: "Kota Medan",
    uniform: "Indonesia",
    email: "reservation.kno@gapura.id",
    phone: '08115985310',
    longitude: 98.87509245167156,
    latitude: 3.6313518984940787,
    timezone: 0,
  },
  {
    id: "26cbd6be-3fe8-456b-8a80-1e0854b08c92",
    code: "PDG ",
    name: "Minangkabau International Airport",
    city: "Kota Padang",
    uniform: "Indonesia",
    email: "reservation.pdg@gapura.id",
    phone: '08115998905',
    longitude: 100.28325920935147,
    latitude: -0.788927837548462,
    timezone: 0,
  },
  {
    id: "e99bbaa6-6e8c-4dd1-913e-8f613a36dbb3",
    code: "BDO",
    name: "Husein Sastranegara International Airport",
    city: "Kota Bandung",
    uniform: "Indonesia",
    email: "reservation.bdo@gapura.id",
    phone: '08115998906',
    longitude: 107.57996329407496,
    latitude: -6.903764921172909,
    timezone: 0,
  },
  {
    id: "0a3a33d6-fa4f-4103-95bb-7a72385781c8",
    code: "BIK",
    name: "Frans Kaisiepo International Airport",
    city: "Kab. Biak Numfor",
    uniform: "Indonesia",
    email: "reservation.bik@gapura.id",
    phone: '08115998912',
    longitude: 136.10557655539878,
    latitude: -1.1910858344414512,
    timezone: 2,
  },
  {
    id: "7af9c18b-3976-4e84-93c0-98bbf80df8da",
    code: "SUB",
    name: "Juanda International Airport",
    city: "Kota Surabaya",
    uniform: "Indonesia",
    email: "reservation.sub@gapura.id",
    phone: '08115985308',
    longitude: 112.79477779222775,
    latitude: -7.374782251306162,
    timezone: 0,
  },
  {
    id: "2701cf21-888b-46df-882d-4a0ebea0fc07",
    code: "AAP",
    name: "APT Pranoto International Airport",
    city: "Kota Samarinda",
    uniform: "Indonesia",
    email: "reservation.bpn@joumpa.my.id",
    phone: '08115998902',
    longitude: 117.25702570749394,
    latitude: -0.37078225600216225,
    timezone: 1,
  },
  {
    id: "57b159d0-a066-4655-a15e-e04c19f70b92",
    code: "DPS",
    name: "I Gusti Ngurah Rai International Airport",
    city: "Kota Denpasar",
    uniform: "Indonesia",
    email: "reservation.dps@gapura.id",
    phone: '08115985306',
    longitude: 115.16809019225398,
    latitude: -8.746895654791864,
    timezone: 1,
  },
  {
    id: "e69447c0-0dcd-415e-ace2-310721d4310a",
    code: "FLZ",
    name: "Dr. Ferdinand Lumban Tobing Airport",
    city: "Kab. Tapanuli Tengah",
    uniform: "Indonesia",
    email: "reservation.flz@gapura.id",
    phone: '08115998934',
    longitude: 98.89657250934327,
    latitude: 1.5549994961506413,
    timezone: 0,
  },
  {
    id: "7f5572b4-2c4f-4d9e-8ee0-8b4068a3da53",
    code: "AMQ",
    name: "Pattimura International Airport",
    city: "Kota Ambon",
    uniform: "Indonesia",
    email: "reservation.amq@gapura.id",
    phone: '08115999742',
    longitude: 128.08847400566515,
    latitude: -3.7049077808337816,
    timezone: 2,
  },
  {
    id: "1a451802-ad87-4039-9aea-efaefff622f7",
    code: "BDJ",
    name: "Sjamsudin Noor International Airport",
    city: "Kota Banjarmasin",
    uniform: "Indonesia",
    email: "reservation.bdj@gapura.id",
    phone: '08115999431',
    longitude: 114.76085228053651,
    latitude: -3.4357999959687593,
    timezone: 1,
  },
  {
    id: "1fdb7abe-01d8-4e02-bf06-1dd9df8b5c05",
    code: "TNJ",
    name: "Raja Haji Fisabilillah International Airport",
    city: "Kota Tanjung Pinang",
    uniform: "Indonesia",
    email: "reservation.tnj@gapura.id",
    phone: '08115998925',
    longitude: 104.52672399214322,
    latitude: 0.9220144303652233,
    timezone: 0,
  },
  {
    id: "c13184be-3258-4c08-ae3e-b012f976db5a",
    code: "BPN",
    name: "Sultan Aji Muhammad Sulaiman Sepinggan International Airport",
    city: "Kota Balikpapan",
    uniform: "Indonesia",
    email: "reservation.bpn@gapura.id",
    phone: '08115998902',
    longitude: 116.89768287866264,
    latitude: -1.2655791219099328,
    timezone: 1,
  },
  {
    id: "c02b3a7f-605f-4d36-af7a-e799ca2e7154",
    code: "SOC ",
    name: "Adi Sumarmo International Airport",
    city: "Solo",
    uniform: "Indonesia",
    email: "reservation.soc@gapura.id",
    phone: '08115998929',
    longitude: 110.7561311652491,
    latitude: -7.515330369062708,
    timezone: 0,
  },
  {
    id: "b27f9889-0e65-4fc3-afd6-103f8fca5d8b",
    code: "BKS",
    name: "Fatmawati Soekarno Airport",
    city: "Kota Bengkulu",
    uniform: "Indonesia",
    email: "reservation.bks@gapura.id",
    phone: '08115999527',
    longitude: 102.3394803247235,
    latitude: -3.860347065850878,
    timezone: 0,
  },
  {
    id: "63f1cd84-7dcc-418d-95ea-d48d60b5a5f1",
    code: "PGK ",
    name: "Depati Amir Airport",
    city: "Kota Pangkal Pinang",
    uniform: "Indonesia",
    email: "reservation.pgk@gapura.id",
    phone: '08115999756',
    longitude: 106.14160857866916,
    latitude: -2.1569636731432866,
    timezone: 0,
  },
  {
    id: "7bf24b48-f6e2-40dd-8905-44fda309e9af",
    code: "YIA",
    name: "Yogyakarta International Airport",
    city: "Kab. Kulon Progo",
    uniform: "Indonesia",
    email: "reservation.yia@gapura.id",
    phone: 0,
    longitude: 110.06043366711154,
    latitude: -7.896856531549263,
    timezone: 0,
  },
  {
    id: "fbb74001-9193-4fe3-b633-bd5fabd90dd3",
    code: "SRG",
    name: "Achmad Yani International Airport",
    city: "Kota Semarang",
    uniform: "Indonesia",
    email: "reservation.srg@gapura.id",
    phone: '08115999265',
    longitude: 110.3749974652395,
    latitude: -6.965934299536552,
    timezone: 0,
  },
  {
    id: "926ae1a4-af2f-465f-b681-16ce2dbb29a7",
    code: "KOE",
    name: "El Tari International Airport",
    city: "Kota Kupang",
    uniform: "Indonesia",
    email: "reservation.koe@gapura.id",
    phone: '08115998931',
    longitude: 123.66799885366845,
    latitude: -10.16846756459237,
    timezone: 2,
  },
  {
    id: "c916eab2-6eee-4715-90a4-9be8e61207f0",
    code: "BWX",
    name: "Blimbingsari Airport",
    city: "Kab. Banyuwangi",
    uniform: "Indonesia",
    email: "reservation.bwx@gapura.id",
    phone: '08115998918',
    longitude: 114.33983586340887,
    latitude: -8.312425611095925,
    timezone: 0,
  },
  {
    id: "c8769a56-20c6-4813-bf3c-d0d762b08f92",
    code: "BTH",
    name: "Hang Nadim International Airport",
    city: "Kota Batam",
    uniform: "Indonesia",
    email: "reservation.bth@gapura.id",
    phone: '08115985309',
    longitude: 104.1183508381805,
    latitude: 1.1221183808967503,
    timezone: 0,
  },
  {
    id: "c32a26ea-c1b4-43af-8ed9-a09339707221",
    code: "CGK",
    name: "Soekarno Hatta International Airport",
    city: "Kota Tangerang",
    uniform: "Indonesia",
    email: "reservation.cgk@gapura.id",
    phone: '08115985305',
    longitude: 106.64597544304661,
    latitude: -6.0643553244174155,
    timezone: 0,
  },
  {
    id: "6b8fbe57-866e-444f-95d2-fdf6ab05b852",
    code: "TJQ",
    name: "H.A.S Hanandjoeddin International Airport",
    city: "Kab. Belitung",
    uniform: "Indonesia",
    email: "reservation.tjq@gapura.id",
    phone: '08115999652',
    longitude: 107.75272888680739,
    latitude: -2.752991243407737,
    timezone: 0,
  },
  {
    id: "bb1790b2-5cd7-4167-a35c-fdfb033b285b",
    code: "HLP",
    name: "Halim Perdanakusuma International Airport",
    city: "Kota Jakarta Timur",
    uniform: "Indonesia",
    email: "reservation.hlp@gapura.id",
    phone: '08115999741',
    longitude: 106.88631485173738,
    latitude: -6.2649211337413675,
    timezone: 0,
  },
  {
    id: "0efba112-a06f-45db-b000-0001baa6f3d1",
    code: "UPG",
    name: "Sultan Hasanuddin International Airport",
    city: "Ujung Pandang",
    uniform: "Indonesia",
    email: "reservation.upg@gapura.id",
    phone: '08115999237',
    longitude: 119.549316636374,
    latitude: -5.077294013220538,
    timezone: 1,
  },
  {
    id: "a1fcd8d4-be65-4cd5-bd4a-b8e1a1b775c5",
    code: "LOP",
    name: "Lombok International Airport",
    city: "Kota Mataram",
    uniform: "Indonesia",
    email: "reservation.lop@gapura.id",
    phone: '08115999435',
    longitude: 116.27351303643655,
    latitude: -8.761831551352236,
    timezone: 1,
  },
  {
    id: "12102fec-0883-4050-8dbb-05d835f5496c",
    code: "DMY",
    name: "Airport Dummy International",
    city: "Kota Bogor",
    uniform: "Indonesia",
    email: "dummy.rsv@joumpa.my.id",
    phone: '082898212',
    longitude: -122406417,
    latitude: 37785834,
    timezone: 0,
  },
  {
    id: "79c87840-8a56-4ffe-91c9-1f11f4fcdea7",
    code: "MDC",
    name: "Sam Ratulangi International Airport",
    city: "Kota Manado",
    uniform: "Indonesia",
    email: "reservation.mdc@gapura.id",
    phone: '08115998904',
    longitude: 124.92549339399731,
    latitude: 1.5501550475448855,
    timezone: 1,
  },
  {
    id: "27dfbf24-b673-42c4-a8ce-d57422f3f62f",
    code: "JOG",
    name: "Adi Sutjipto International Airport",
    city: "Kota Yogyakarta",
    uniform: "Indonesia",
    email: "reservation.jog@gapura.id",
    phone: '08115998903',
    longitude: 110.43168619223528,
    latitude: -7.787471194491668,
    timezone: 0,
  },
];
