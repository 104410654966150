import React from "react";
import { Route, Redirect } from "react-router-dom";
// import { getUser } from "../../../utils/User";
import Layout from "../Layout";

const Index = ({ exact, component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default Index;
