import { combineReducers } from "redux";
import airport from "./master_data/airport";
import airline from "./master_data/airline";
import corporate from "./master_data/corporate";
import assistant from "./master_data/assistant";
import user from "./master_data/user";
import role from "./master_data/role";
import city from "./master_data/city";
import reg_price from "./product/regular_price";
import corp_price from "./product/corporate_price";
import booking from "./booking";
import promo from "./promo";

const rootReducer = combineReducers({
  airport,
  airline,
  corporate,
  assistant,
  user,
  role,
  city,
  reg_price,
  corp_price,
  booking,
  promo,
});

export default rootReducer;
