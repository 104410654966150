import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ButtonModal from "./ButtonModal";
import parse from "html-react-parser";
import { withTrans } from "../../../i18n/withTrans";

const ConfirmationModal = ({
  t,
  show,
  toggle,
  title,
  message,
  isDelete,
  children,
  icon,
  confirmTitle,
  cancelTitle,
  variantIcon,
  confirm,
  disabledConfirm,
  messageData,
  param,
}) => {
  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={toggle}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title ?? "Konfirmasi"}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              marginLeft: 20,
              marginRight: 20,
              display: "flex",
              lineHeight: "2rem",
            }}
          >
            {icon && (
              <i
                className={`material-icons-outlined icon-modal ${variantIcon}`}
                style={{ marginRight: 10 }}
              >
                {icon}
              </i>
            )}
            {message && (
              <p style={{ fontSize: "16px", margin: "0 20px" }}>
                {parse(message) ?? "Apakah Anda yakin akan menghapus data ini?"}
                <strong>{messageData} ?</strong>
              </p>
            )}
            {children}
            {param && (
              <div>
                {t ? t("commons.deleteConfirmation") : ""}
                <strong>{param}</strong> ?
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <ButtonModal
            toggle={toggle}
            deleted={isDelete}
            confirm={confirm}
            confirmTitle={confirmTitle}
            cancelTitle={cancelTitle}
            disabledConfirm={disabledConfirm}
          />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withTrans(ConfirmationModal);
