import React from "react";

const Index = ({ 
  className,
  style,
  onClick
}) => {

  return (
    <div
      className={`slick-button-prev ${className}`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};

export default Index;