import React from "react";
import { Container, Row, Col } from "reactstrap";
import { withTrans } from "../../../i18n/withTrans";

//superio-icon
import support from "../../../assets/images/icon/support.svg";
import location from "../../../assets/images/icon/location.svg";
import star from "../../../assets/images/icon/star.svg";
import grade from "../../../assets/images/icon/grade.svg";
import strategize from "../../../assets/images/icon/strategize.svg";
import mobile from "../../../assets/images/icon/mobile.svg";
import contactCenter from "../../../assets/images/icon/contact-center.svg";

const Index = ({ t, isLargeDesktop }) => {
  
  return (
    <section id="superiority">
      <Container fluid={!isLargeDesktop}>
        <div className="superiority-title">
          <div className="super-title text-center text-extra-bold">
            {t("home.superioTitle")}
          </div>
          <div className="super-subtitle small-title text-center">
            {t("home.superioSubtitle")}
          </div>
        </div>
        <Row className="superiority-content justify-content-center pb-50 mx-0">
          <Col md={3} sm={6} 
            className="d-flex justify-content-center text-center px-1 px-lg-3 my-3"
          >
            <div className="super-wrapper">
              <div className="icon">
                <img
                  src={support}
                  alt="ground-handling"
                  className="super-icon-wrapper img-fluid"
                />
              </div>
              <div className="description">
                <div className="medium-title my-4 text-extra-bold text-white">
                  {t("home.superio0")}
                </div>
              </div>
            </div>
          </Col>
          <Col md={3} sm={6} 
            className="d-flex justify-content-center text-center px-1 px-lg-3 my-3"
          >
            <div className="super-wrapper">
              <div className="icon">
                <img
                  src={location}
                  alt="airports"
                  className="super-icon-wrapper img-fluid"
                />
              </div>
              <div className="description">
                <div className="medium-title my-4 text-extra-bold text-white">
                  {t("home.superio1")}
                </div>
              </div>
            </div>
          </Col>
          <Col md={3} sm={6} 
            className="d-flex justify-content-center text-center px-1 px-lg-3 my-3"
          >
            <div className="super-wrapper">
              <div className="icon">
                <img
                  src={star}
                  alt="certified"
                  className="super-icon-wrapper img-fluid"
                />
              </div>
              <div className="description">
                <div className="medium-title my-4 text-extra-bold text-white">
                  {t("home.superio2")}
                </div>
              </div>
            </div>
          </Col>
          <Col md={3} sm={6} 
            className="d-flex justify-content-center text-center px-1 px-lg-3 my-3"
          >
            <div className="super-wrapper">
              <div className="icon">
                <img
                  src={grade}
                  alt="authorized"
                  className="super-icon-wrapper img-fluid"
                />
              </div>
              <div className="description">
                <div className="medium-title my-4 text-extra-bold text-white">
                  {t("home.superio3")}
                </div>
              </div>
            </div>
          </Col>
          <Col md={3} sm={6} 
            className="d-flex justify-content-center text-center px-1 px-lg-3 my-3"
          >
            <div className="super-wrapper">
              <div className="icon">
                <img
                  src={strategize}
                  alt="strategize"
                  className="super-icon-wrapper img-fluid"
                />
              </div>
              <div className="description">
                <div className="medium-title my-4 text-extra-bold text-white">
                  {t("home.superio4")}
                </div>
              </div>
            </div>
          </Col>
          <Col md={3} sm={6} 
            className="d-flex justify-content-center text-center px-1 px-lg-3 my-3"
          >
            <div className="super-wrapper">
              <div className="icon">
                <img
                  src={mobile}
                  alt="mobile-apps"
                  className="super-icon-wrapper img-fluid"
                />
              </div>
              <div className="description">
                <div className="medium-title my-4 text-extra-bold text-white">
                  {t("home.superio5")}
                </div>
              </div>
            </div>
          </Col>
          <Col md={3} sm={6} 
            className="d-flex justify-content-center text-center px-1 px-lg-3 my-3"
          >
            <div className="super-wrapper">
              <div className="icon">
                <img
                  src={contactCenter}
                  alt="contact-center"
                  className="super-icon-wrapper img-fluid"
                />
              </div>
              <div className="description">
                <div className="medium-title my-4 text-extra-bold text-white">
                  {t("home.superio6")}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default withTrans(Index);