import {
    GET_AIRPORT_PENDING,
    GET_AIRPORT_SUCCESS,
    GET_AIRPORT_ERROR,
    GET_DETAIL_AIRPORT_PENDING,
    GET_DETAIL_AIRPORT_SUCCESS,
    GET_DETAIL_AIRPORT_ERROR,
    POST_AIRPORT_PENDING,
    POST_AIRPORT_SUCCESS,
    POST_AIRPORT_ERROR,
    PUT_AIRPORT_PENDING,
    PUT_AIRPORT_SUCCESS,
    PUT_AIRPORT_ERROR,
    DELETE_AIRPORT_PENDING,
    DELETE_AIRPORT_SUCCESS,
    DELETE_AIRPORT_ERROR,
} from "../../../actions/master_data/airport";

const initialState = {
    pending: false,
    error: null,
    data_list: null,
    pending_post: false,
    data_post: null,
    pending_add: false,
    data_add: null,
    pending_put: false,
    data_put: false,
    pending_delete: false,
    data_delete: null,
    data_detail: null,
    pending_detail: false,
};

const airport = (state = initialState, action) => {
    switch (action.type) {
        case GET_AIRPORT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case GET_AIRPORT_SUCCESS:
            return {
                ...state,
                pending: false,
                data_list: action.data,
            };
        case GET_AIRPORT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case GET_DETAIL_AIRPORT_PENDING:
            return {
                ...state,
                pending_detail: true,
            };
        case GET_DETAIL_AIRPORT_SUCCESS:
            return {
                ...state,
                pending_detail: false,
                data_detail: action.data,
            };
        case GET_DETAIL_AIRPORT_ERROR:
            return {
                ...state,
                pending_detail: false,
                error: action.error,
            };
        case POST_AIRPORT_PENDING:
            return {
                ...state,
                pending_post: true,
            };
        case POST_AIRPORT_SUCCESS:
            return {
                ...state,
                pending_post: false,
                data_post: action.data,
            };
        case POST_AIRPORT_ERROR:
            return {
                ...state,
                pending_post: false,
                error: action.error,
            };
        case PUT_AIRPORT_PENDING:
            return {
                ...state,
                pending_put: true,
            };
        case PUT_AIRPORT_SUCCESS:
            return {
                ...state,
                pending_put: false,
                data_put: action.data,
            };
        case PUT_AIRPORT_ERROR:
            return {
                ...state,
                pending_put: false,
                error: action.error,
            };
        case DELETE_AIRPORT_PENDING:
            return {
                ...state,
                pending_delete: true,
            };
        case DELETE_AIRPORT_SUCCESS:
            return {
                ...state,
                pending_delete: false,
                data_delete: action.data,
            };
        case DELETE_AIRPORT_ERROR:
            return {
                ...state,
                pending_delete: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default airport;
