import { React, useState, useEffect } from 'react'
import { Col, Container, Row } from "reactstrap";
import { withTrans } from "../../i18n/withTrans";
import { history } from "../../utils/History";
import Button from "../../components/commons/buttons/Button";
import fastTrack from "../../assets/images/icon/fast-track-img.svg"
import meetGreet from "../../assets/images/icon/meet-and-greet-img.svg"
import transfer from "../../assets/images/icon/transfer-img.svg"

const Index = ({ t }) => {

  document.documentElement.scrollTop = 0;
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 660;
  const isTabled = width <= 767 && width > 659;
  return (
    <>
      <Container fluid>
        <div className="product-bg d-flex">
          <Row className="m-auto justify-content-center">
            <Col md={10}>
              <div className="super-title text-center text-extra-bold">
                {t("product.headline")}
              </div>
            </Col>
          </Row>
        </div>
        {/* <div className="product-title">
          <Row className="justify-content-center">
            <Col md={12}>
              <div className="title text-center text-extra-bold">
                {t("products.products")}
              </div>
            </Col>
          </Row>
        </div> */}
        <section id="product">
          {/* <Row className="product-content justify-content-between align-items-center">
            <Col lg={5} md={6} sm={12}>
              <img src={fastTrack} alt='fast-track' className="img-fluid w-100" />
            </Col>
            <Col lg={7} md={6} sm={12}>
              <div className="card-description">
                <div className="title-products text-extra-bold">{t("product.FastTrack")}</div>
                <div className="content-products">
                  {t("product.detailFTDesc")}
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <Button 
                    title="Read More"
                    rounded={true}
                    variant="btn-card-more"
                    onClick={() => history.push('/product/detail/fast-track')}
                  />
                </div>
              </div>
            </Col>
          </Row> */}
          <Row className="product-content justify-content-between align-items-center">
            <Col lg={5} md={6} sm={12}>
              <img src={meetGreet} alt='fast-track' className="img-fluid w-100" />
            </Col>
            <Col lg={7} md={6} sm={12}>
              <div className="card-description">
                <div className="title-products text-extra-bold">{t("product.meetAndGreet")}</div>
                <div className="content-products">
                  {t("product.detailMnGDesc")}
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <Button 
                    title="Read More"
                    rounded={true}
                    variant="btn-card-more"
                    onClick={() => history.push('/product/detail/meet-and-greet')}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="product-content justify-content-between align-items-center">
            <Col lg={5} md={6} sm={12}>
              <img src={transfer} alt='fast-track' className="img-fluid w-100" />
            </Col>
            <Col lg={7} md={6} sm={12}>
              <div className="card-description">
                <div className="title-products text-extra-bold">{t("product.transfer")}</div>
                <div className="content-products">
                  {t("product.detailTransferDesc")}
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <Button 
                    title="Read More"
                    rounded={true}
                    variant="btn-card-more"
                    onClick={() => history.push('/product/detail/transfer')}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </Container>
    </>
  );
};

export default withTrans(Index);
