import { Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./components/PrivateRoute";

import NotFound from "./views/empty_state/NotFound";
import FormReservation from "./views/form_reservation/new_reservation";
import Support from "./views/support";
import Products from "./views/products";
import DetailProduct from "./views/products/DetailProduct";
import ManageBooking from "./views/manage_booking";
import PrivacyPolicy from "./views/privacy_policy";
import PrivacyPolicyAssistant from "./views/privacy_policy_assistant";
import TermsCondition from "./views/terms_condition";
import OurLocation from "./views/our_location";
import Home from "./views/home"

function App() {
  return (
    <>
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <Switch>
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute path="/reservation" component={FormReservation} />
        <PrivateRoute path="/support" component={Support} />
        <PrivateRoute path="/products" component={Products} />
        <PrivateRoute path="/product/detail/:service" component={DetailProduct} />
        <PrivateRoute path="/manage-booking" component={ManageBooking} />
        <PrivateRoute path="/privacy-policy" component={PrivacyPolicy} />
        <PrivateRoute path="/privacy-policy-assistant" component={PrivacyPolicyAssistant} />
        <PrivateRoute path="/terms-condition" component={TermsCondition} />
        <PrivateRoute path="/our-location" component={OurLocation} />
        {/* <Route path="*" component={NotFound} /> */}
        <Redirect from="*" to="/" />
      </Switch>
    </>
  );
}

export default App;
