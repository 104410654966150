import React from "react";

const Index = ({ 
  className,
  style,
  onClick
}) => {

  return (
    <div>
      <div
        className={`slick-button-next ${className}`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    </div>
  );
};

export default Index;