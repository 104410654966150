import Select from "react-select";

const SelectFilter = ({ onChange, placeholder, clearable, searchable, options, value, name }) => {
    const customSelect = {
        control: (base, state) => ({
            ...base,
            cursor: "pointer",
            textAlign: 'left',
            fontWeight: '700',
            background: "#ffff",
            borderRadius: "32px",
            padding: "1px 1px 1px 10px",
            height: "48px",
            minWidth: "200px",
            border: "2px solid #E5E5F0",
            borderColor: state.isFocused ? "#E5E5F0" : "#E5E5F0",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                borderColor: state.isFocused ? "#E5E5F0" : "#E5E5F0"
            }
        }),
        indicatorsContainer: (base) => ({
            ...base,
            marginRight: "7px",
        }),
        indicatorSeparator: (base) => ({
            ...base,
            backgroundColor: "#fff",
        }),
        option: (options, state) => ({
            ...options,
            cursor: "pointer",
            textAlign: "left",
            paddingLeft: "15px",
        })
    }
    
    const handleChange = (item) => {
        // this is going to call setFieldValue and manually update values.topcis
        if (onChange)
          onChange(name, item?.value ?? "");
    };

    return (
        <>
            <Select
                isClearable={clearable}
                isSearchable={searchable}
                options={options ?? []}
                name={name}
                value={
                    options?.filter((obj) => {
                      return obj?.value === value;
                    })[0] ?? { label: "Select...", key: "" }
                }
                styles={customSelect}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#deebff',
                    },
                })}
                placeholder={placeholder ?? "Select..."}
                className="mr-2"
                onChange={handleChange}
            />
        </>
    )
}

export default SelectFilter;