import { history } from "../../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";

import { toastSuccess, toastError } from "../../../../components/commons/toast";

import API from "../../API";
import { handleResponse } from "../../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_CORPORATE_PRICE_PENDING = "GET_CORPORATE_PRICE_PENDING";
export const GET_CORPORATE_PRICE_SUCCESS = "GET_CORPORATE_PRICE_SUCCESS";
export const GET_CORPORATE_PRICE_ERROR = "GET_CORPORATE_PRICE_ERROR";
export const POST_CORPORATE_PRICE_PENDING = "POST_CORPORATE_PRICE_PENDING";
export const POST_CORPORATE_PRICE_SUCCESS = "POST_CORPORATE_PRICE_SUCCESS";
export const POST_CORPORATE_PRICE_ERROR = "POST_CORPORATE_PRICE_ERROR";
export const PUT_CORPORATE_PRICE_PENDING = "PUT_CORPORATE_PRICE_PENDING";
export const PUT_CORPORATE_PRICE_SUCCESS = "PUT_CORPORATE_PRICE_SUCCESS";
export const PUT_CORPORATE_PRICE_ERROR = "PUT_CORPORATE_PRICE_ERROR";
export const DELETE_CORPORATE_PRICE_PENDING = "DELETE_CORPORATE_PRICE_PENDING";
export const DELETE_CORPORATE_PRICE_SUCCESS = "DELETE_CORPORATE_PRICE_SUCCESS";
export const DELETE_CORPORATE_PRICE_ERROR = "DELETE_CORPORATE_PRICE_ERROR";
export const FIND_CORPORATE_PENDING = "FIND_CORPORATE_PENDING";
export const FIND_CORPORATE_SUCCESS = "FIND_CORPORATE_SUCCESS";
export const FIND_CORPORATE_ERROR = "FIND_CORPORATE_ERROR";

// URL: URL_{URL}
const CORPORATE_PRICE_URL = `v1/master_data/product_corporate_price`;
const FIND_CORPORATE_URL = `v1/master_data/product_corporate_price/find_corporate`;

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_CORPORATE_PRICE_PENDING));
  API.get(CORPORATE_PRICE_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_CORPORATE_PRICE_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_CORPORATE_PRICE_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        toastError(err?.response?.data?.message);
      }
    });
};

const find_corp = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(FIND_CORPORATE_PENDING));
  API.get(FIND_CORPORATE_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(FIND_CORPORATE_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(FIND_CORPORATE_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const post = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(POST_CORPORATE_PRICE_PENDING));
  API.post(CORPORATE_PRICE_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_CORPORATE_PRICE_SUCCESS, res));
      toastSuccess("Berhasil Tambah Data Harga Korporasi");
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_CORPORATE_PRICE_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const put = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_CORPORATE_PRICE_PENDING));
  API.put(CORPORATE_PRICE_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_CORPORATE_PRICE_SUCCESS, res));
      toastSuccess("Berhasil Ubah Data Harga Korporasi");
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_CORPORATE_PRICE_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const del = (param, callback, name) => (dispatch) => {
  dispatch(actionPending(DELETE_CORPORATE_PRICE_PENDING));
  API.delete(CORPORATE_PRICE_URL + `?id=${param}`)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_CORPORATE_PRICE_SUCCESS, res));
      toastSuccess(`Berhasil Hapus Data Harga Korporasi`);
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_CORPORATE_PRICE_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const corp_price = {
  get,
  find_corp,
  post,
  put,
  del,
};
export default corp_price;
