import { history } from "../../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";

import { toastSuccess, toastError } from "../../../../components/commons/toast";

import API from "../../API";
import { handleResponse } from '../../HandleRespone';

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_REGULAR_PENDING = "GET_REGULAR_PENDING";
export const GET_REGULAR_SUCCESS = "GET_REGULAR_SUCCESS";
export const GET_REGULAR_ERROR = "GET_REGULAR_ERROR";
export const GET_REGULAR_PRICE_PENDING = "GET_REGULAR_PRICE_PENDING";
export const GET_REGULAR_PRICE_SUCCESS = "GET_REGULAR_PRICE_SUCCESS";
export const GET_REGULAR_PRICE_ERROR = "GET_REGULAR_PRICE_ERROR";
export const POST_REGULAR_PENDING = "POST_REGULAR_PENDING";
export const POST_REGULAR_SUCCESS = "POST_REGULAR_SUCCESS";
export const POST_REGULAR_ERROR = "POST_REGULAR_ERROR";
export const PUT_REGULAR_PENDING = "PUT_REGULAR_PENDING";
export const PUT_REGULAR_SUCCESS = "PUT_REGULAR_SUCCESS";
export const PUT_REGULAR_ERROR = "PUT_REGULAR_ERROR";
export const DELETE_REGULAR_PENDING = "DELETE_REGULAR_PENDING";
export const DELETE_REGULAR_SUCCESS = "DELETE_REGULAR_SUCCESS";
export const DELETE_REGULAR_ERROR = "DELETE_REGULAR_ERROR";

// URL: URL_{URL}
const REGULAR_URL = `v1/master_data/product-regular-price`;
const REGULAR_PRICE_URL = `v1/order/regular/calculate-price/public`;
// const REGULAR_PRICE_URL = `v1/master_data/product-regular-price/find-active-price`;

const lang = localStorage.getItem("joumpa_language");

const get = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(GET_REGULAR_PENDING));
    API.get(REGULAR_URL, { params: param, })
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_REGULAR_SUCCESS, res));
            if (resolve) {
                const records_total = res.data.total;
                let data = res.data.data.map((item, i) => ({
                    ...item,
                    no: i + 1 + (param?.page - 1) * param?.length,
                }));
                resolve({
                    data: data,
                    page: param?.page - 1,
                    totalCount: records_total,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_REGULAR_ERROR));
            let not_logout = handleResponse(err?.response)
            if (not_logout) {
                toastError(err?.response?.data?.message);
            }
        });
};

const get_price = (param, resolve, callback) => (dispatch) => {
    dispatch(actionPending(GET_REGULAR_PRICE_PENDING));
    API.post(REGULAR_PRICE_URL, param)
    .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(GET_REGULAR_PRICE_SUCCESS, res));
  
        if (resolve) {
          resolve({
            data: res.data.data,
            message: res.data.message,
            status: res.data.status,
          });
        }
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        dispatch(actionError(GET_REGULAR_PRICE_ERROR));
        toastError(
          lang === "id"
            ? err?.response?.data?.message
            : err?.response?.data?.message_eng
        );
      });
};

const post = (param, resolve, callback) => (dispatch) => {
    dispatch(actionPending(POST_REGULAR_PENDING));
    API.post(REGULAR_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(POST_REGULAR_SUCCESS, res));
            toastSuccess("Berhasil Tambah Data Harga Regular");
            if (resolve) {
                resolve({
                    data: res.data.data,
                    message: res.data.message,
                    status: res.data.status,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(POST_REGULAR_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const put = (param, resolve, callback) => (dispatch) => {
    dispatch(actionPending(PUT_REGULAR_PENDING));
    API.put(REGULAR_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(PUT_REGULAR_SUCCESS, res));
            toastSuccess("Berhasil Ubah Data Harga Regular");
            if (resolve) {
                resolve({
                    data: res.data.data,
                    message: res.data.message,
                    status: res.data.status,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(PUT_REGULAR_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const del = (param, callback, name) => (dispatch) => {
    dispatch(actionPending(DELETE_REGULAR_PENDING));
    API.delete(REGULAR_URL + `?id=${param}`)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(DELETE_REGULAR_SUCCESS, res));
            toastSuccess(`Berhasil Hapus Data Harga Regular`);
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(DELETE_REGULAR_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const reg_price = {
    get,
    get_price,
    post,
    put,
    del,
};
export default reg_price;
