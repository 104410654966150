import React from "react";
import { Container } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CustomArrowNext from "../../commons/slick/customArrowNext";
import CustomArrowPrev from "../../commons/slick/customArrowPrev";
import * as partners from "../../../utils/Constants/PartnersImport";
import { withTrans } from "../../../i18n/withTrans";

const Index = ({ t }) => {
  const settingWrapperSlider = {
    dots: true,
    className: "center",
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 2000,
    nextArrow: <CustomArrowNext />,
    prevArrow: <CustomArrowPrev />,
    appendDots: dots => (
      <div>
        <ul style={{ margin: 0 }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div className="slick-custom-pagination">
        {i + 1}
      </div>
    ),
    responsive: [
      {
        breakpoint: 576,
        settings: {
          dots: false
        }
      },
    ]
  }

  const settingSlideLeft = {
    className: "center",
    infinite: true,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 0,
    cssEase: "linear",
    rtl: false
  }

  const settingSlideRight = {
    className: "center",
    infinite: true,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 0,
    cssEase: "linear",
    rtl: true
  }

  return (
    <Container fluid>
      <section id="partners">
        <div className="partner-title">
          <div className="super-title text-center text-extra-bold">
            {t("home.partnersHeadline")}
            <span className="text-title-partner">
              {" "}100+{" "}
            </span>
            <span>
              {t("home.partners")}
            </span>
          </div>
        </div>
        <Slider
          {...settingWrapperSlider}
        >
          {/* slide 1 */}
          <div>
            <Slider {...settingSlideRight}>
              <div className="partner-logo">
                <img src={partners.DPRRI} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.NihiSumba} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.AsiaConcierge} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.ExoTravel} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.MandarinHotel} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.SoettaAirport} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Aeroconnect} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.BRI} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.DestinationAsia} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.AstrindoSatrya} alt="partner" className="img-fluid " />
              </div>
            </Slider>
            <Slider {...settingSlideLeft}>
              <div className="partner-logo">
                <img src={partners.SoettaAirport} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Aeroconnect} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.BRI} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.DestinationAsia} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.AstrindoSatrya} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.DPRRI} alt="partner" className="img-fluid " />
              </div>
              <div className="img-fluid partner-logo">
                <img src={partners.NihiSumba} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.AsiaConcierge} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.ExoTravel} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.MandarinHotel} alt="partner" className="img-fluid " />
              </div>
            </Slider>
          </div>
          {/* slide 2 */}
          <div>
            <Slider {...settingSlideRight}>
              <div className="partner-logo">
                <img src={partners.VanisRizki} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.ShangrilaHotel} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.RitzCarlton} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Pelni} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.TheOberoi} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Pelindo} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.JimbaranGreenhill} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Sulselbar} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Apruva} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.GlobalServiceWisata} alt="partner" className="img-fluid " />
              </div>
            </Slider>
            <Slider {...settingSlideLeft}>
              <div className="partner-logo">
                <img src={partners.Pelindo} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.JimbaranGreenhill} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Sulselbar} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Apruva} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.GlobalServiceWisata} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.VanisRizki} alt="partner" className="img-fluid " />
              </div>
              <div className="img-fluid partner-logo">
                <img src={partners.ShangrilaHotel} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.RitzCarlton} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Pelni} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.TheOberoi} alt="partner" className="img-fluid " />
              </div>
            </Slider>
          </div>
          {/* slide 3 */}
          <div>
            <Slider {...settingSlideRight}>
              <div className="partner-logo">
                <img src={partners.BNIEmerald} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.BankSumut} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.HiltonBaliResort} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.JWMarriotJakarta} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Intercontinental} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.WakatobiDiveResort} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Citilink} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.JapanAirlines} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.TopIndonesia} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.JPBali} alt="partner" className="img-fluid " />
              </div>
            </Slider>
            <Slider {...settingSlideLeft}>
              <div className="partner-logo">
                <img src={partners.WakatobiDiveResort} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Citilink} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.JapanAirlines} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.TopIndonesia} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.JPBali} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.BNIEmerald} alt="partner" className="img-fluid " />
              </div>
              <div className="img-fluid partner-logo">
                <img src={partners.BankSumut} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.HiltonBaliResort} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.JWMarriotJakarta} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Intercontinental} alt="partner" className="img-fluid " />
              </div>
            </Slider>
          </div>
          {/* slide 4 */}
          <div>
            <Slider {...settingSlideRight}>
              <div className="partner-logo">
                <img src={partners.Pacto} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.InternationalSOS} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.JalanTour} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.OCBC} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Adlink} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.SurveyorIndonesia} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.AngkasaPura} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.PLNLombok} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Klook} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.PertaminaHKT} alt="partner" className="img-fluid " />
              </div>
            </Slider>
            <Slider {...settingSlideLeft}>
              <div className="partner-logo">
                <img src={partners.SurveyorIndonesia} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.AngkasaPura} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.PLNLombok} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Klook} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.PertaminaHKT} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Pacto} alt="partner" className="img-fluid " />
              </div>
              <div className="img-fluid partner-logo">
                <img src={partners.InternationalSOS} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.JalanTour} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.OCBC} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Adlink} alt="partner" className="img-fluid " />
              </div>
            </Slider>
          </div>
          {/* slide 5 */}
          <div>
            <Slider {...settingSlideRight}>
              <div className="partner-logo">
                <img src={partners.NusaduaBeach} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.SmailingBali} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Unnes} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Nirjhara} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Wika} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.TheLaguna} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.BankKalsel} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.StRegis} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.PertaminaTrans} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.LuxuryVillas} alt="partner" className="img-fluid " />
              </div>
            </Slider>
            <Slider {...settingSlideLeft}>
              <div className="partner-logo">
                <img src={partners.TheLaguna} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.BankKalsel} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.StRegis} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.PertaminaTrans} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.LuxuryVillas} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.NusaduaBeach} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.SmailingBali} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Unnes} alt="partner" className="img-fluid " />
              </div>
              <div className="img-fluid partner-logo">
                <img src={partners.Nirjhara} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Wika} alt="partner" className="img-fluid " />
              </div>
            </Slider>
          </div>
          {/* slide 6 */}
          <div>
            <Slider {...settingSlideRight}>
              <div className="partner-logo">
                <img src={partners.BoundlessLife} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.TanahGajah} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Pegadaian} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Applus} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.HyattRegency} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.MeliaBali} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.TravelokaAxes} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.BiasMandiri} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Astrindo} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.KLM} alt="partner" className="img-fluid " />
              </div>
            </Slider>
            <Slider {...settingSlideLeft}>
              <div className="partner-logo">
                <img src={partners.MeliaBali} alt="partner" className="img-fluid " />
              </div>
              <div className="img-fluid partner-logo">
                <img src={partners.TravelokaAxes} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.BiasMandiri} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Astrindo} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.KLM} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.BoundlessLife} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.TanahGajah} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Pegadaian} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Applus} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.HyattRegency} alt="partner" className="img-fluid " />
              </div>
            </Slider>
          </div>
          {/* slide 7 */}
          <div>
            <Slider {...settingSlideRight}>
              <div className="partner-logo">
                <img src={partners.Konimex} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Come2Indonesia} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Konimex} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Come2Indonesia} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Konimex} alt="partner" className="img-fluid " />
              </div>
            </Slider>
            <Slider {...settingSlideLeft}>
              <div className="partner-logo">
                <img src={partners.Come2Indonesia} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Konimex} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Come2Indonesia} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Konimex} alt="partner" className="img-fluid " />
              </div>
              <div className="partner-logo">
                <img src={partners.Come2Indonesia} alt="partner" className="img-fluid " />
              </div>
            </Slider>
          </div>
        </Slider>
      </section>
    </Container>
  );
}

export default withTrans(Index);