import React from "react";
import { Container, Row, Col } from "reactstrap";
import { withTrans } from "../../../i18n/withTrans";
import { history } from "../../../utils/History";

//service-icon
import fastt from "../../../assets/images/icon/fast-track.svg";
import meetandgreet from "../../../assets/images/icon/meet-and-greet.svg";
import transfer from "../../../assets/images/icon/transfer.svg";

const Index = ({ t }) => {
  
  return (
    <Container>
      <section id="services">
        <div className="services-title">
          <div className="super-title text-center text-extra-bold">
            {t("home.serviceTitle")}
          </div>
          <div className="small-title text-center">
            {t("home.serviceSubtitle")}
          </div>
        </div>
        <Row className="justify-content-center content-wrapper">
          {/* <Col md={4} className="text-center service-wrapper">
            <div className="icon">
              <img
                src={fastt}
                alt="fast-track"
                className="icon-service-wrapper img-fluid"
              />
            </div>
            <div className="description">
              <div className="small-title my-4 text-extra-bold">
                {t("field.fastTrack")}
              </div>
              <div className="small-title my-4 ">
                {t("home.serviceFastTrack")}
              </div>
            </div>
            <div
              className="hover-icon"
              onClick={() => history.push("/products")}
            >
              <span className="material-icons-outlined my-4 service-navigate">
                arrow_forward_ios
              </span>
            </div>
          </Col> */}
          <Col md={6} className="text-center  service-wrapper">
            <div className="icon">
              <img
                src={meetandgreet}
                alt="meet-and-greet"
                className="icon-service-wrapper img-fluid"
              />
            </div>
            <div className="description">
              <div className="small-title my-4 text-extra-bold">
                {t("field.meetNGreet")}
              </div>
              <div className="small-title my-4 ">
                {t("home.serviceMeetnGreet")}
              </div>
            </div>
            <div
              className="hover-icon"
              onClick={() => history.push("/products")}
            >
              <span className="material-icons-outlined my-4 service-navigate">
                arrow_forward_ios
              </span>
            </div>
          </Col>
          <Col md={6} className="text-center service-wrapper">
            <div className="icon">
              <img
                src={transfer}
                alt="transfer"
                className="icon-service-wrapper img-fluid"
              />
            </div>
            <div className="description">
              <div className="small-title my-4 text-extra-bold">
                {t("field.transfer")}
              </div>
              <div className="small-title my-4 ">
                {t("home.serviceTransfer")}
              </div>
            </div>
            <div
              className="hover-icon"
              onClick={() => history.push("/products")}
            >
              <span className="material-icons-outlined my-4 service-navigate">
                arrow_forward_ios
              </span>
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  );
}

export default withTrans(Index);