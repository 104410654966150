import {
  GET_PROMO_PENDING,
  GET_PROMO_SUCCESS,
  GET_PROMO_ERROR,
  POST_PROMO_PENDING,
  POST_PROMO_SUCCESS,
  POST_PROMO_ERROR,
  PUT_PROMO_PENDING,
  PUT_PROMO_SUCCESS,
  PUT_PROMO_ERROR,
  DELETE_PROMO_PENDING,
  DELETE_PROMO_SUCCESS,
  DELETE_PROMO_ERROR,
} from "../../actions/promo";

const initialState = {
  pending: false,
  error: null,
  data_list: null,
  pending_post: false,
  data_post: null,
  pending_put: false,
  data_put: false,
  pending_delete: false,
  data_delete: null,
};

const promo = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROMO_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_PROMO_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case GET_PROMO_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_PROMO_PENDING:
      return {
        ...state,
        pending_post: true,
      };
    case POST_PROMO_SUCCESS:
      return {
        ...state,
        pending_post: false,
        data_post: action.data,
      };
    case POST_PROMO_ERROR:
      return {
        ...state,
        pending_post: false,
        error: action.error,
      };
    case PUT_PROMO_PENDING:
      return {
        ...state,
        pending_put: true,
      };
    case PUT_PROMO_SUCCESS:
      return {
        ...state,
        pending_put: false,
        data_put: action.data,
      };
    case PUT_PROMO_ERROR:
      return {
        ...state,
        pending_put: false,
        error: action.error,
      };
    case DELETE_PROMO_PENDING:
      return {
        ...state,
        pending_delete: true,
      };
    case DELETE_PROMO_SUCCESS:
      return {
        ...state,
        pending_delete: false,
        data_delete: action.data,
      };
    case DELETE_PROMO_ERROR:
      return {
        ...state,
        pending_delete: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default promo;
